import { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getCountryAll } from "../store/country/actions";
import { getStateByCountryId } from "../store/state/actions";
import { getDistrictByStateId } from "../store/district/actions";
import { getWardByDistrictId } from "../store/ward/actions";
import { getBranchFull, setDataNull } from "../store/address/actions";
import { ObjectType } from "./Interface";

function AddressDetail({ object_id, object_type, callBackData }) {
  const [addressDialog, setAddressDialog] = useState(false);
  // mang luu cac dia chi
  const [addressess, setAddressess] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { countryData, stateData, districtData, wardData, branchDataFull } = useSelector(
    (state) => ({
      countryData: state.Country.datas,
      stateData: state.State.dataByCountryId,
      districtData: state.District.dataByStateId,
      wardData: state.Ward.dataByDistrictId,
      branchDataFull: state.Address.dataBranchFull,
    }),
    shallowEqual
  );

  const WithoutDispatching = Yup.object().shape({
    branch: Yup.string().required("Vui lòng nhập tên chi nhánh"),
    country_id: Yup.number().required("Vui lòng nhập tên quốc gia"),
    state_id: Yup.number().required("Vui lòng nhập tên tỉnh/thành phố"),
  });

  const WithDispatching = Yup.object().shape({
    branch: Yup.string().required("Vui lòng nhập tên chi nhánh"),
    country_id: Yup.number().required("Vui lòng nhập tên quốc gia"),
  });

  const getValidationSchema = () => {
    if (object_type === "dispatching_company" || object_type === "intern") {
      return WithDispatching;
    } else {
      return WithoutDispatching;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      index: "",
      branch: "",
      supervisor: "",
      object_id: "",
      object_type: "",
      country_id: null,
      country_name: "",
      state_id: null,
      state_name: "",
      district_id: null,
      district_name: "",
      ward_id: null,
      ward_name: "",
      detail: "",
      phone_number: "",
      email: "",
      fax: "",
      is_default: false,
      avatar: "",
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      let arr = [...addressess];
      if (values.index !== "") {
        arr[values.index] = values;
        setAddressess(arr);
      } else {
        if (arr.length === 0) {
          values.is_default = true;
        }
        setAddressess([...arr, values]);
      }
      setAddressDialog(false);
      // formik.resetForm();
    },
  });

  useEffect(() => {
    dispatch(getCountryAll());
    if (object_type && object_id) {
      dispatch(getBranchFull({ object_id: object_id, object_type: object_type }));
    }
  }, [object_id, object_type]);

  useEffect(() => {
    if (branchDataFull) {
      const arr = branchDataFull.map((item) => {
        const obj = {};
        obj.id = item.id;
        obj.avatar = countryData?.find((ct) => ct.CountryID === item.country_id)?.Avatar;
        obj.branch = item.branch;
        obj.country_id = item.country_id;
        obj.country_name = item?.address_display ? item?.address_display?.split(",")[0] : "";
        obj.state_id = item.state_id;
        obj.state_name = item?.address_display ? item?.address_display?.split(",")[1] : "";
        obj.district_id = item.district_id;
        obj.district_name = item?.address_display ? item?.address_display?.split(",")[2] : "";
        obj.ward_id = item.ward_id;
        obj.ward_name = item?.address_display ? item?.address_display?.split(",")[3] : "";
        obj.detail = item.detail;
        obj.phone_number = item.phone_number;
        obj.email = item.email;
        obj.fax = item.fax;
        obj.supervisor = item.supervisor;
        obj.is_default = item.is_default;

        return obj;
      });
      setAddressess(arr);
    }
  }, [branchDataFull]);

  useEffect(() => {
    if (object_type === ObjectType.RECEIVING_FACTORY || object_type === ObjectType.SYNDICATION) {
      formik.setFieldValue("country_id", 2);
    }
  }, [object_type]);

  useEffect(() => {
    if (formik.values.country_id && formik.values.country_id < 3) {
      dispatch(getStateByCountryId(formik.values.country_id));
      const flag = countryData.find((country) => country.CountryID === formik.values.country_id)?.Avatar;
      formik.setFieldValue("avatar", flag);
      formik.setFieldValue(
        "country_name",
        countryData.find((country) => country.CountryID === formik.values.country_id)?.CountryName_en
      );
    }
  }, [formik.values.country_id]);

  useEffect(() => {
    if (formik.values.state_id) {
      dispatch(getDistrictByStateId(formik.values.state_id));
      formik.setFieldValue(
        "state_name",
        stateData.find((state) => state.StateID === formik.values.state_id)?.StateName_en
      );
    }
  }, [formik.values.state_id]);

  useEffect(() => {
    if (formik.values.district_id) {
      dispatch(getWardByDistrictId(formik.values.district_id));
      formik.setFieldValue(
        "district_name",
        districtData.find((district) => district.DistrictID === formik.values.district_id)?.DistrictName_en
      );
    }
  }, [formik.values.district_id]);

  useEffect(() => {
    callBackData(addressess);
  }, [addressess]);

  const openDialog = () => {
    setAddressDialog(true);
  };

  const closeDialog = () => {
    formik.resetForm();
    // dispatch(setDataNull());
    setAddressDialog(false);
  };

  const handleRemove = (index) => {
    let arr = [...addressess];
    arr.splice(index, 1);
    setAddressess(arr);
  };

  const handleSetAddressDefault = (e, index) => {
    let arr = [...addressess];
    const newArr = arr.map((item, i) => {
      let data;
      if (i === index) {
        data = { ...item, is_default: e.checked };
      } else {
        data = { ...item, is_default: false };
      }
      return data;
    });
    // arr[index].is_default = e.checked;
    setAddressess(newArr);
  };

  const handleEdit = (address, index) => {
    formik.setFieldValue("index", index);
    formik.setFieldValue("branch", address.branch);
    formik.setFieldValue("supervisor", address.supervisor);
    formik.setFieldValue("object_id", address.object_id);
    formik.setFieldValue("object_type", address.object_type);
    formik.setFieldValue("country_id", address.country_id);
    formik.setFieldValue("state_id", address.state_id);
    formik.setFieldValue("district_id", address.district_id);
    formik.setFieldValue("ward_id", address.ward_id);
    formik.setFieldValue("detail", address.detail);
    formik.setFieldValue("phone_number", address.phone_number);
    formik.setFieldValue("email", address.email);
    formik.setFieldValue("fax", address.fax);
    formik.setFieldValue("is_default", address.is_default);
    formik.setFieldValue("avatar", address.avatar);
    setAddressDialog(true);
  };

  // Xử lý và lấy ảnh
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.CountryName_en} src={option.Avatar} className={`mr-2 }`} style={{ width: "18px" }} />
          <div>{t(option.CountryName_en)}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.CountryName_en} src={option.Avatar} className={`mr-2 }`} style={{ width: "18px" }} />
        <div>{t(option.CountryName_en)}</div>
      </div>
    );
  };

  return (
    <div className="bg-white border-round-md ">
      <div className="flex px-3 pt-3 mb-3 ">
        <Button label={t("Add address")} icon="pi pi-plus" onClick={openDialog} />
      </div>
      <div className="flex flex-wrap">
        {addressess.map((address, index) => (
          <div key={index} className="w-4 p-3">
            <div className="surface-50 border-1 border-200 border-round-md p-3 relative" style={{ minHeight: "170px" }}>
              <div className="flex justify-content-between">
                <div className="flex align-items-center">
                  <i className="pi pi-map-marker" style={{ fontSize: "1rem" }}></i>
                  <div className="font-bold ml-2">{address.branch}</div>
                </div>
                <div className="flex align-items-center">
                  <label htmlFor={`ingredient${index}`} className="mr-2">
                    {t("Default")}
                  </label>
                  <Checkbox
                    name="is_default"
                    id="is_default"
                    checked={address.is_default}
                    onChange={(e) => handleSetAddressDefault(e, index)}
                  />
                </div>
              </div>
              <div className="flex align-items-center mt-3 ml-4">
                <Avatar image={address.avatar} />
                <div className="ml-2">{`${address.country_name} ${address.state_name ?? ""} ${
                  address.district_name ?? ""
                } ${address.ward_name ?? ""}  ${address.detail ?? ""}`}</div>
              </div>
              <div className="mt-2 ml-4">{address.phone_number}</div>
              <div className="mt-2 ml-4">{address.email}</div>
              <div className="mt-2 ml-4">{address.supervisor}</div>
              <div className="flex flex-row absolute bottom-0 right-0 gap-2">
                <Button icon="pi pi-pen-to-square" text className="mt-3" onClick={() => handleEdit(address, index)} />
                <Button icon="pi pi-times" text className="mt-3" onClick={() => handleRemove(index)} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        header={object_id === null ? t("Add a new branch") : t("Edit branch")}
        visible={addressDialog}
        style={{ width: "38vw" }}
        modal
        onHide={closeDialog}
      >
        <div className="p-fluid px-3">
          <div className="flex flex-row gap-3">
            <div className="w-6">
              <label>{t("Branch name")}</label>
              <InputText
                id="branch"
                name="branch"
                value={formik.values.branch}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.errors.branch && formik.touched.branch}
              />
            </div>
            <div className="w-6">
              <label>{t("Country")}</label>
              <Dropdown
                options={countryData}
                value={formik.values.country_id}
                optionValue="CountryID"
                onChange={(e) => {
                  formik.setFieldValue("country_id", e.value);
                  formik.setFieldValue("state_id", "");
                  formik.setFieldValue("district_id", "");
                  formik.setFieldValue("ward_id", "");
                }}
                filter
                showClear
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                emptyFilterMessage={t("No results found")}
                style={{ height: "35px" }}
                pt={{ list: "p-0", root: "w-full", header: "p-3" }}
                disabled={object_type === ObjectType.SYNDICATION || object_type === ObjectType.RECEIVING_FACTORY}
                invalid={formik.errors.country_id && formik.touched.country_id}
              />
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <div className="mt-3 w-4">
              <label className="mb-3">{t("Province")}</label>
              <Dropdown
                options={stateData}
                value={formik.values.state_id}
                onChange={(e) => {
                  formik.setFieldValue("state_id", e.value);
                  formik.setFieldValue("district_id", "");
                  formik.setFieldValue("ward_id", "");
                }}
                filter
                showClear
                emptyFilterMessage={t("No results found")}
                style={{ height: "35px" }}
                pt={{ list: "p-0", root: "w-full", header: "p-3" }}
                disabled={formik.values.country_id > 2}
                invalid={formik.errors.state_id && formik.touched.state_id}
              />
            </div>
            <div className="mt-3 w-4">
              <label className="mb-3">{t("District")}</label>
              <Dropdown
                options={districtData}
                value={formik.values.district_id}
                onChange={(e) => {
                  formik.setFieldValue("district_id", e.value);
                  formik.setFieldValue("ward_id", "");
                }}
                filter
                showClear
                emptyFilterMessage={t("No results found")}
                style={{ height: "35px" }}
                pt={{ list: "p-0", root: "w-full", header: "p-3" }}
                disabled={formik.values.country_id > 2}
              />
            </div>
            <div className="mt-3 w-4">
              <label className="mb-3">{t("Ward")}</label>
              <Dropdown
                options={wardData}
                value={formik.values.ward_id}
                onChange={(e) => formik.setFieldValue("ward_id", e.value)}
                filter
                showClear
                emptyFilterMessage={t("No results found")}
                style={{ height: "35px" }}
                pt={{ list: "p-0", root: "w-full", header: "p-3" }}
                disabled={formik.values.country_id > 2}
              />
            </div>
          </div>
          <div className="mt-3">
            <label className="mb-2">{t("House Number, Street, etc.")}</label>
            <InputText
              id="detail"
              name="detail"
              className="mt-2"
              value={formik.values.detail}
              onChange={formik.handleChange}
              invalid={formik.errors.detail && formik.touched.detail}
            />
          </div>
          <div className="flex flex-row gap-3 mt-3">
            <div className="w-6">
              <label className="mb-2">{t("Phone Number")}</label>
              <InputText
                id="phone_number"
                name="phone_number"
                className="mt-2"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
              />
            </div>
            <div className="w-6">
              <label className="mb-2">{t("Email")}</label>
              <InputText
                id="email"
                name="email"
                className="mt-2"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          {object_type !== ObjectType.INTERN && (
            <div className="flex flex-row gap-3 mt-3">
              <div className="w-6">
                <label className="mb-2">{t("Fax")}</label>
                <InputText
                  id="fax"
                  name="fax"
                  className="mt-2"
                  value={formik.values.fax}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="w-6">
                <label className="mb-2">{t("Person in Charge")}</label>
                <InputText
                  id="supervisor"
                  name="supervisor"
                  className="mt-2"
                  value={formik.values.supervisor}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          )}
          <Button type="submit" className="mt-5" label={t("Save")} onClick={formik.handleSubmit} />
        </div>
      </Dialog>
    </div>
  );
}

export default AddressDetail;
