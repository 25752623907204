import { takeEvery, put, call } from "redux-saga/effects";
import {
  GET_STATUS_BY_ID,
  GET_STATUS_BY_USER_ID,
  SET_STATUS,
  UPDATE_STATUS,
  DELETE_STATUS,
} from "./actionTypes";
import {
  getStatusByIdSuccess,
  getStatusByIdFail,
  getStatusByUserIdSuccess,
  getStatusByUserIdFail,
  setStatusSuccess,
  setStatusFail,
  updateStatusSuccess,
  updateStatusFail,
  deleteStatusSuccess,
  deleteStatusFail,
} from "./actions";

import {
  getStatusDataById,
  getStatusDataByUserId,
  addNewDataStatus,
  updateDataStatus,
  deleteDataStatus,
} from "../../api/backend_helper";

function* fetStatusDataId({ payload: id }) {
  try {
    const response = yield call(getStatusDataById, id);
    yield put(getStatusByIdSuccess(response));
  } catch (error) {
    yield put(getStatusByIdFail(error.response.data));
  }
}

function* fetStatusDataByUserId() {
  try {
    const response = yield call(getStatusDataByUserId);
    yield put(getStatusByUserIdSuccess(response));
  } catch (error) {
    yield put(getStatusByUserIdFail(error.response.data));
  }
}

function* onAddNewStatus({ payload: data }) {
  try {
    const response = yield call(addNewDataStatus, data);
    yield put(setStatusSuccess(response));
  } catch (error) {
    yield put(setStatusFail(error.response.data));
  }
}

function* onUpdateStatus({ payload: data }) {
  try {
    console.log("kiem tras", data);
    const response = yield call(updateDataStatus, data);
    console.log("kime tra tra ra ", response);
    yield put(updateStatusSuccess(response));
  } catch (error) {
    yield put(updateStatusFail(error.response.data));
  }
}

function* onDeleteStatus({ payload: id }) {
  try {
    const response = yield call(deleteDataStatus, id);
    yield put(deleteStatusSuccess(response));
  } catch (error) {
    yield put(deleteStatusFail(error.response.data));
  }
}

function* StatusSaga() {
  yield takeEvery(GET_STATUS_BY_ID, fetStatusDataId);
  yield takeEvery(GET_STATUS_BY_USER_ID, fetStatusDataByUserId);
  yield takeEvery(SET_STATUS, onAddNewStatus);
  yield takeEvery(UPDATE_STATUS, onUpdateStatus);
  yield takeEvery(DELETE_STATUS, onDeleteStatus);
}

export default StatusSaga;
