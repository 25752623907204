export const GET_CAREER_BY_ID = 'GET_CAREER_BY_ID';
export const GET_CAREER_BY_ID_SUCCESS = 'GET_CAREER_BY_ID_SUCCESS';
export const GET_CAREER_BY_ID_FAIL = 'GET_CAREER_BY_ID_FAIL';

export const GET_CAREER_BY_USER_ID = 'GET_CAREER_BY_USER_ID';
export const GET_CAREER_BY_USER_ID_SUCCESS = 'GET_CAREER_BY_USER_ID_SUCCESS';
export const GET_CAREER_BY_USER_ID_FAIL = 'GET_CAREER_BY_USER_ID_FAIL';

export const SET_CAREER = 'SET_CAREER';
export const SET_CAREER_SUCCESS = 'SET_CAREER_SUCCESS';
export const SET_CAREER_FAIL = 'SET_CAREER_FAIL';

export const UPDATE_CAREER = 'UPDATE_CAREER';
export const UPDATE_CAREER_SUCCESS = 'UPDATE_CAREER_SUCCESS';
export const UPDATE_CAREER_FAIL = 'UPDATE_CAREER_FAIL';

export const DELETE_CAREER = 'DELETE_CAREER';
export const DELETE_CAREER_SUCCESS = 'DELETE_CAREER_SUCCESS';
export const DELETE_CAREER_FAIL = 'DELETE_CAREER_FAIL';
