import { SplitButton } from "primereact/splitbutton";
import { useTranslation } from "react-i18next";

const ButtonImport = ({ onClick, exportClick }) => {
  const { t } = useTranslation();

  const printReport = () => { };

  // nhập dữ liệu
  const importItems = [
    {
      label: t("Import from Excel"),
      icon: "pi pi-file-excel",
      command: onClick,
    },
    {
      label: t("Export to Excel"),
      icon: "pi pi-file-excel",
      command: exportClick,
    },
    // {
    //   label: t("Export to PDF"),
    //   icon: "pi pi-file-pdf",
    // },

  ];

  return (
    <SplitButton
      label={t("Print report")}
      icon="pi pi-print"
      onClick={printReport}
      model={importItems}
      severity="danger"
      outlined
      size="small"
      pt={{ menuItem: "mx-2" }}
      className="bg-white"
    />
  );
};

export default ButtonImport;
