import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AlienCard = ({
  cardDialog,
  setCardDialog,
  editingCard,
  residenceItems,
  setCardList,
  setEditingCard,
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      card_number: editingCard ? editingCard.card_number : '',
      status_of_residence: editingCard
        ? editingCard.status_of_residence
        : undefined,
      card_license_date: editingCard
        ? new Date(editingCard.card_license_date)
        : undefined,
      card_expiration_date: editingCard
        ? new Date(editingCard.card_expiration_date)
        : undefined,
      // description: editingCard ? editingCard.description : undefined,
    },
    validationSchema: Yup.object().shape({
      card_number: Yup.string().required(t('This field is required')),
      status_of_residence: Yup.string().required(t('This field is required')),
      card_license_date: Yup.date().required(t('This field is required')),
      card_expiration_date: Yup.date().required(t('This field is required')),
    }),
    onSubmit: (values) => {
      const newCard = {
        id: '', // card them moi se khong co id // phan biet voi card da co id
        card_number: values.card_number,
        status_of_residence: values.status_of_residence,
        card_license_date: values.card_license_date,
        card_expiration_date: values.card_expiration_date,
        description: values.description,
      };
      if (editingCard) {
        setCardList((prevCardList) =>
          prevCardList.map((card) =>
            card.id === editingCard.id ? newCard : card
          )
        );
      } else {
        setCardList((prevCardList) => [...prevCardList, newCard]);
      }
      setCardDialog(false);
      setEditingCard(null);
      // Reset form values
      formik.setFieldValue('card_number', '');
      formik.setFieldValue('status_of_residence', '');
      formik.setFieldValue('card_license_date', undefined);
      formik.setFieldValue('card_expiration_date', undefined);
      formik.setFieldValue('description', '');
    },
  });

  return (
    <Dialog
      visible={cardDialog}
      onHide={() => {
        setCardDialog(false);
        formik.resetForm();
      }}
      header={
        editingCard
          ? t('Edit alien registration card')
          : t('Add a new alien registration card')
      }
      style={{ width: '20%' }}>
      <div className='flex flex-column'>
        <label className='mb-2'>{t('Foreigner Registration No.')}</label>
        <InputText
          id='card_number'
          value={formik.values.card_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={
            formik.touched.card_number && formik.errors.card_number
              ? true
              : false
          }
        />
        {formik.touched.card_number && formik.errors.card_number && (
          <p className='p-error'>{formik.errors.card_number}</p>
        )}
      </div>
      <div className='flex flex-column mt-3'>
        <label className='mb-2'>{t('Residency status')}</label>
        <Dropdown
          id='status_of_residence'
          name='status_of_residence'
          options={residenceItems}
          value={formik.values.status_of_residence}
          onBlur={formik.handleBlur}
          onChange={(e) => formik.setFieldValue('status_of_residence', e.value)}
          showClear
          optionLabel='name'
          optionValue='value'
          pt={{ list: 'p-0', header: 'p-3' }}
          invalid={
            formik.touched.status_of_residence &&
            formik.errors.status_of_residence
              ? true
              : false
          }
        />

        {formik.touched.status_of_residence &&
          formik.errors.status_of_residence && (
            <p className='p-error'>{formik.errors.status_of_residence}</p>
          )}
      </div>
      <div className='flex flex-column mt-3'>
        <label className='mb-2'>{t('Date of Issue')}</label>
        <Calendar
          id='card_license_date'
          value={formik.values.card_license_date}
          onChange={(e) => formik.setFieldValue('card_license_date', e.value)}
          onBlur={formik.handleBlur}
          showIcon
          dateFormat='yy/mm/dd'
          invalid={
            formik.touched.card_license_date && formik.errors.card_license_date
              ? true
              : false
          }
        />
        {formik.touched.card_license_date &&
          formik.errors.card_license_date && (
            <p className='p-error'>{formik.errors.card_license_date}</p>
          )}
      </div>
      <div className='flex flex-column mt-3'>
        <label className='mb-2'>{t('Date of Expiration')}</label>
        <Calendar
          id='card_expiration_date'
          value={formik.values.card_expiration_date}
          onChange={(e) =>
            formik.setFieldValue('card_expiration_date', e.value)
          }
          onBlur={formik.handleBlur}
          showIcon
          dateFormat='yy/mm/dd'
          invalid={
            formik.touched.card_expiration_date &&
            formik.errors.card_expiration_date
              ? true
              : false
          }
        />
        {formik.touched.card_expiration_date &&
          formik.errors.card_expiration_date && (
            <p className='p-error'>{formik.errors.card_expiration_date}</p>
          )}
      </div>
      <Button
        label={t('Save')}
        className='mt-5 w-full'
        type='submit'
        onClick={formik.handleSubmit}
      />
    </Dialog>
  );
};

export default AlienCard;
