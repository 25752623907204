import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import {
  statusLevelItems,
  statusConditionDate,
  statusConditionMilestone,
} from '../../../components/Interface';
import { setDataNull, setStatus } from '../../../store/status/actions';

const AddStatusDialog = ({ visible, onHide, toast }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errorInsert, dataInsert } = useSelector(
    (state) => ({
      errorInsert: state.Status.errorInsert,
      dataInsert: state.Status.dataInsert,
    }),
    shallowEqual
  );

  const statusLevelTemplate = (option, props) => {
    if (option) {
      return <div>{t(option)}</div>;
    }
    return <span>{props.placeholder}</span>;
  };

  const levelOptionTemplate = (option) => {
    return <div>{t(option)}</div>;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      priority_level: '',
      is_auto: false,
      condition_date: '',
      condition_milestone: '',
      condition_value: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('This field is required')),
    }),
    onSubmit: (values) => {
      dispatch(setStatus(values));
    },
  });

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorInsert.mes,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (dataInsert) {
      toast.current?.show({
        severity: 'success',
        summary: 'success',
        detail: 'Add career Success',
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      onHide();
    }
    // eslint-disable-next-line
  }, [errorInsert, dataInsert]);

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t('Add Status')}
      modal
      style={{ width: '50rem' }}
      footer={
        <div>
          <Button
            label={t('Cancel')}
            onClick={onHide}
            severity='secondary'
            className='p-button-text'
          />
          <Button
            type='submit'
            label={t('Save')}
            onClick={formik.handleSubmit}
          />
        </div>
      }>
      <div className='flex flex-column mb-4'>
        <label className='mb-2'>{t('Status Name')}</label>
        <InputText
          id='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          invalid={formik.touched.name && formik.errors.name ? true : false}
        />
        {formik.touched.name && formik.errors.name ? (
          <div style={{ color: 'red', marginTop: '5px' }}>
            {formik.errors.name.toString()}
          </div>
        ) : null}
      </div>
      <div className='flex flex-column mb-4'>
        <label className='mb-2'>{t('Severity')}</label>
        <Dropdown
          value={formik.values.priority_level}
          onChange={(e) => formik.setFieldValue('priority_level', e.value)}
          options={Object.values(statusLevelItems)}
          optionLabel='name'
          placeholder={t('Severity')}
          valueTemplate={statusLevelTemplate}
          itemTemplate={levelOptionTemplate}
          className='w-full'
        />
      </div>
      <div className='flex flex-column mb-6'>
        <label className='mb-2'>{t('Description')}</label>
        <InputTextarea
          id='description'
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </div>
      {/* <div>
        <div className='flex align-items-center mb-3'>
          <InputSwitch
            name='is_auto'
            checked={formik.values.is_auto}
            onChange={(e) => formik.setFieldValue('is_auto', e.value)}
          />
          <label className='ml-2'>
            {t('Automatically add this status and alert')}
          </label>
        </div>
        {formik.values.is_auto && (
          <div className='flex flex-row gap-3'>
            <div className='flex flex-column w-4'>
              <label className='mb-2'>{t('Condition')}</label>
              <Dropdown
                name='condition_date'
                value={formik.values.condition_date}
                onChange={(e) =>
                  formik.setFieldValue('condition_date', e.value)
                }
                options={Object.values(statusConditionDate)}
                optionLabel='name'
                placeholder={t('Select a condition')}
                valueTemplate={statusLevelTemplate}
                itemTemplate={levelOptionTemplate}
                className='w-full'
              />
            </div>
            <div className='flex flex-column w-4'>
              <label className='mb-2'>{t('Number of Days')}</label>
              <InputText
                id='condition_value'
                name='condition_value'
                value={formik.values.condition_value}
                onChange={formik.handleChange}
                className='w-full'
              />
            </div>
            <div className='flex flex-column w-4'>
              <label className='mb-2'>{t('Count from date')}</label>
              <Dropdown
                value={formik.values.condition_milestone}
                onChange={(e) =>
                  formik.setFieldValue('condition_milestone', e.value)
                }
                options={Object.values(statusConditionMilestone)}
                optionLabel='name'
                placeholder={t('Select a condition')}
                valueTemplate={statusLevelTemplate}
                itemTemplate={levelOptionTemplate}
                className='w-full'
              />
            </div>
          </div>
        )}
      </div> */}
    </Dialog>
  );
};

export default AddStatusDialog;
