import {
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  REGISTER_ADMIN,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAIL,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  VERIFY_KEY,
  VERIFY_KEY_SUCCESS,
  VERIFY_KEY_FAIL,
  VERIFY_KEY_LICENSE,
  VERIFY_KEY_LICENSE_SUCCESS,
  VERIFY_KEY_LICENSE_FAIL,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_INFOR,
  GET_INFOR_SUCCESS,
  GET_INFOR_FAIL,
  SET_DATA_NULL,
} from './actionTypes';

export const getUserById = (id) => ({
  type: GET_USER_BY_ID,
  payload: id,
});

export const getUserByIdSuccess = (data) => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: data,
});

export const getUserByIdFail = (error) => ({
  type: GET_USER_BY_ID_FAIL,
  payload: error,
});

export const login = (credentials) => ({
  type: LOGIN,
  payload: credentials,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFail = (error) => ({
  type: LOGIN_FAIL,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFail = (error) => ({
  type: LOGOUT_FAIL,
  payload: error,
});

export const registerAdmin = (data) => ({
  type: REGISTER_ADMIN,
  payload: data,
});

export const registerAdminSuccess = (data) => ({
  type: REGISTER_ADMIN_SUCCESS,
  payload: data,
});

export const registerAdminFail = (error) => ({
  type: REGISTER_ADMIN_FAIL,
  payload: error,
});

export const registerUser = (data) => ({
  type: REGISTER_USER,
  payload: data,
});

export const registerUserSuccess = (data) => ({
  type: REGISTER_USER_SUCCESS,
  payload: data,
});

export const registerUserFail = (error) => ({
  type: REGISTER_USER_FAIL,
  payload: error,
});

export const verifyKey = (data) => ({
  type: VERIFY_KEY,
  payload: data,
});

export const verifyKeySuccess = (data) => ({
  type: VERIFY_KEY_SUCCESS,
  payload: data,
});

export const verifyKeyFail = (error) => ({
  type: VERIFY_KEY_FAIL,
  payload: error,
});

export const verifyKeyLicense = (data) => ({
  type: VERIFY_KEY_LICENSE,
  payload: data,
});

export const verifyKeyLicenseSuccess = (data) => ({
  type: VERIFY_KEY_LICENSE_SUCCESS,
  payload: data,
});

export const verifyKeyLicenseFail = (error) => ({
  type: VERIFY_KEY_LICENSE_FAIL,
  payload: error,
});

export const verifyEmail = (data) => ({
  type: VERIFY_EMAIL,
  payload: data,
});

export const verifyEmailSuccess = (data) => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const verifyEmailFail = (error) => ({
  type: VERIFY_EMAIL_FAIL,
  payload: error,
});

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  payload: data,
});

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: data,
});

export const changePasswordFail = (error) => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error,
});

export const deleteUser = (data) => ({
  type: DELETE_USER,
  payload: data,
});

export const deleteUserSuccess = (data) => ({
  type: DELETE_USER_SUCCESS,
  payload: data,
});

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  payload: error,
});

export const getInfor = () => ({
  type: GET_INFOR,
});

export const getInforSuccess = (data) => ({
  type: GET_INFOR_SUCCESS,
  payload: data,
});

export const getInforFail = (error) => ({
  type: GET_INFOR_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
