import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_NEW_INTERN,
  GET_NEW_INTERN_SUCCESS,
  GET_NEW_INTERN_FAIL,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
} from "./actionTypes";

export const getDashboardData = () => ({
  type: GET_DASHBOARD_DATA,
});

export const getDashboardDataSuccess = (data) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const getDashboardDataFail = (error) => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error,
});

export const getNewIntern = (params) => ({
  type: GET_NEW_INTERN,
  payload: params,
});

export const getNewInternSuccess = (data) => ({
  type: GET_NEW_INTERN_SUCCESS,
  payload: data,
});

export const getNewInternFail = (error) => ({
  type: GET_NEW_INTERN_FAIL,
  payload: error,
});

export const getNotification = (params) => ({
  type: GET_NOTIFICATION,
  payload: params,
});

export const getNotificationSuccess = (data) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getNotificationFail = (error) => ({
  type: GET_NOTIFICATION_FAIL,
  payload: error,
});
