
export const GET_SYNDICATION_BY_ID = "GET_SYNDICATION_BY_ID";
export const GET_SYNDICATION_BY_ID_SUCCESS = "GET_SYNDICATION_BY_ID_SUCCESS";
export const GET_SYNDICATION_BY_ID_FAIL = "GET_SYNDICATION_BY_ID_FAIL";

export const GET_SYNDICATION_BY_USER_ID = "GET_SYNDICATION_BY_USER_ID";
export const GET_SYNDICATION_BY_USER_ID_SUCCESS = "GET_SYNDICATION_BY_USER_ID_SUCCESS";
export const GET_SYNDICATION_BY_USER_ID_FAIL = "GET_SYNDICATION_BY_USER_ID_FAIL";

export const GET_SYNDICATION_LIST = "GET_SYNDICATION_LIST";
export const GET_SYNDICATION_LIST_SUCCESS = "GET_SYNDICATION_LIST_SUCCESS";
export const GET_SYNDICATION_LIST_FAIL = "GET_SYNDICATION_LIST_FAIL";

export const GET_SYNDICATION_BY_KEY_ID = "GET_SYNDICATION_BY_KEY_ID";
export const GET_SYNDICATION_BY_KEY_ID_SUCCESS = "GET_SYNDICATION_BY_KEY_ID_SUCCESS";
export const GET_SYNDICATION_BY_KEY_ID_FAIL = "GET_SYNDICATION_BY_KEY_ID_FAIL";

export const SET_SYNDICATION = "SET_SYNDICATION";
export const SET_SYNDICATION_SUCCESS = "SET_SYNDICATION_SUCCESS";
export const SET_SYNDICATION_FAIL = "SET_SYNDICATION_FAIL";

export const UPDATE_SYNDICATION = "UPDATE_SYNDICATION";
export const UPDATE_SYNDICATION_SUCCESS = "UPDATE_SYNDICATION_SUCCESS";
export const UPDATE_SYNDICATION_FAIL = "UPDATE_SYNDICATION_FAIL";

export const DELETE_SYNDICATION = "DELETE_SYNDICATION";
export const DELETE_SYNDICATION_SUCCESS = "DELETE_SYNDICATION_SUCCESS";
export const DELETE_SYNDICATION_FAIL = "DELETE_SYNDICATION_FAIL";

export const SET_DATA_NULL = 'SET_DATA_NULL';