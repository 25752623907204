import React from 'react';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useContext } from 'react';
import { DataContext } from '../../contexts/data/DataProvider';
import { site } from './../../api/url_helper';
import { useTranslation } from 'react-i18next';

import defaultAvatar from '../../assets/images/avatas/image.png';

function Notification({
  selectedNotify,
  setSelectedNotify,
  tabMenuActive,
  setTabMenuActive,
}) {
  const { selectedLang } = useContext(DataContext);
  const { t } = useTranslation();
  const formatTime = (time) => {
    const currentDate = new Date();
    const targetDate = new Date(time);

    const timeDiff = Math.abs(currentDate - targetDate);
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    if (minutesDiff < 1) {
      return t('1 minute ago');
    } else if (minutesDiff < 60) {
      return `${minutesDiff} ${t('minutes ago')}`;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
      const hours = targetDate.getHours();
      const minutes = targetDate.getMinutes();
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    } else if (targetDate.getFullYear() === currentDate.getFullYear()) {
      const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
      const day = targetDate.getDate().toString().padStart(2, '0');
      return `${month}/${day}`;
    } else {
      const year = targetDate.getFullYear();
      const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
      const day = targetDate.getDate().toString().padStart(2, '0');

      return `${year}/${month}/${day}`;
    }
  };

  return (
    <>
      <div className='bg-white border-round-md shadow-1 p-5 '>
        <div className='flex gap-5'>
          <div className='flex justify-content-center align-items-center'>
            <Button
              icon='pi pi-angle-left'
              rounded
              style={{
                background: '#F5F5F5',
                color: '#000',
                border: 'none',
              }}
              size='small'
              onClick={() => {
                setSelectedNotify(null);
                setTabMenuActive(tabMenuActive);
              }}
            />
          </div>
          <div className='flex flex-column justify-content-between gap-2'>
            {selectedLang === 'vi' ? (
              <div
                className='font-bold'
                dangerouslySetInnerHTML={{
                  __html: selectedNotify.title_vi,
                }}></div>
            ) : null}
            {selectedLang === 'en' ? (
              <div
                className='font-bold'
                dangerouslySetInnerHTML={{
                  __html: selectedNotify.title_en,
                }}></div>
            ) : null}
            {selectedLang === 'ja' ? (
              <div
                className='font-bold'
                dangerouslySetInnerHTML={{
                  __html: selectedNotify.title_ja,
                }}></div>
            ) : null}
            <div className='flex gap-5'>
              <div className='flex gap-3 '>
                <div>
                  <img
                    src={selectedNotify && selectedNotify.sender_info.avata}
                    alt=''
                    style={{
                      width: '2rem',
                      height: '2rem',
                      borderRadius: '50%',
                    }}
                  />
                </div>
                <div className='flex align-items-center '>
                  {selectedNotify.sender_info.name}
                </div>
              </div>
              <div className='flex align-items-center opacity-70'>
                {formatTime(selectedNotify.createdAt)}
              </div>
            </div>
          </div>
        </div>
        <Divider className='mb-8' />
        <div>
          {selectedLang === 'vi' ? (
            <div
              className='text mb-5'
              dangerouslySetInnerHTML={{
                __html: selectedNotify.content_vi,
              }}></div>
          ) : null}
          {selectedLang === 'en' ? (
            <div
              className='text mb-5'
              dangerouslySetInnerHTML={{
                __html: selectedNotify.content_en,
              }}></div>
          ) : null}
          {selectedLang === 'ja' ? (
            <div
              className='text mb-5'
              dangerouslySetInnerHTML={{
                __html: selectedNotify.content_ja,
              }}></div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Notification;
