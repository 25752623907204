import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_BRANCH_RECEIVING,
  GET_BRANCH_SYNDICATION,
  GET_BRANCH_DISPATCHING,
  GET_BRANCH_FULL,
} from "./actionTypes";
import {
  getBranchReceivingSuccess,
  getBranchReceivingFail,
  getBranchDispatchingSuccess,
  getBranchDispatchingFail,
  getBranchSyndicationSuccess,
  getBranchSyndicationFail,
  getBranchFullSuccess,
  getBranchFullFail,
} from "./actions";
import { getBranch, getBranchFull } from "../../api/backend_helper";

function* fetBranchReceivingData({ payload: data }) {
  try {
    const response = yield call(getBranch, data);
    yield put(getBranchReceivingSuccess(response));
  } catch (error) {
    yield put(getBranchReceivingFail(error));
  }
}

function* fetBranchSyndicationData({ payload: data }) {
  try {
    const response = yield call(getBranch, data);
    yield put(getBranchSyndicationSuccess(response));
  } catch (error) {
    yield put(getBranchSyndicationFail(error));
  }
}

function* fetBranchDispatchingData({ payload: data }) {
  try {
    const response = yield call(getBranch, data);
    yield put(getBranchDispatchingSuccess(response));
  } catch (error) {
    yield put(getBranchDispatchingFail(error));
  }
}

function* fetBranchFullData({ payload: data }) {
  try {
    const response = yield call(getBranchFull, data);
    yield put(getBranchFullSuccess(response));
  } catch (error) {
    yield put(getBranchFullFail(error));
  }
}

function* AddressSaga() {
  yield takeEvery(GET_BRANCH_RECEIVING, fetBranchReceivingData);
  yield takeEvery(GET_BRANCH_SYNDICATION, fetBranchSyndicationData);
  yield takeEvery(GET_BRANCH_DISPATCHING, fetBranchDispatchingData);
  yield takeEvery(GET_BRANCH_FULL, fetBranchFullData);
}

export default AddressSaga;
