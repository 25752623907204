import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  setDataNull,
  updateViolateType,
} from "../../../store/violate_type/actions";

const ModelEditViolateType = ({ visible, onHide, toast, rowData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errorUpdate, dataUpdate } = useSelector(
    (state) => ({
      errorUpdate: state.ViolateType.errorUpdate,
      dataUpdate: state.ViolateType.dataUpdate,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: rowData?.id,
      name: rowData?.name,
      description: rowData?.description,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("This field is required")),
    }),
    onSubmit: (values) => {
      dispatch(updateViolateType(values));
    },
  });

  useEffect(() => {
    if (errorUpdate) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorUpdate.mes,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (dataUpdate) {
      toast.current?.show({
        severity: "success",
        summary: "success",
        detail: "Update status success",
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      onHide();
    }
    // eslint-disable-next-line
  }, [errorUpdate, dataUpdate]);

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t("Edit Violate Type")}
      modal
      style={{ width: "50rem", top: "-15%" }}
      footer={
        <div>
          <Button
            label={t("Cancel")}
            onClick={onHide}
            severity="secondary"
            className="p-button-text"
          />
          <Button
            type="submit"
            label={t("Save")}
            onClick={formik.handleSubmit}
          />
        </div>
      }
    >
      <div className="flex flex-column mb-4">
        <label className="mb-2">{t("Violate type")}</label>
        <InputText
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          invalid={formik.touched.name && formik.errors.name ? true : false}
        />
        {formik.touched.name && formik.errors.name ? (
          <div style={{ color: "red", marginTop: "5px" }}>
            {formik.errors.name.toString()}
          </div>
        ) : null}
      </div>
      <div className="flex flex-column mb-3">
        <label className="mb-2">{t("Description")}</label>
        <InputTextarea
          id="description"
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </div>
    </Dialog>
  );
};

export default ModelEditViolateType;
