import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { site } from "../api/url_helper";

const Authmiddleware = (props) => {
  const navigate = useNavigate();

  // const auth = Cookies.get('auth');
  const auth = JSON.parse(localStorage.getItem('auth'));
  
  

  useEffect(() => {
    const getAuth = async () => {
      try {
        if(auth === 'true' || auth === true) {
          console.log('login success');
          const authData = await axios.get(`${site}/api/users/get-auth`, { withCredentials: true });
          if (authData.status === 200) {
            navigate(props.path);
          } else {
            navigate("/login");
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.log(error);
        navigate("/login");
      }
    };

    getAuth();
  }, [props.path, auth, navigate]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
