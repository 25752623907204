import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { AutoComplete } from "primereact/autocomplete";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ObjectType, ViolateStatus } from "../../components/Interface";
import UploadFile from "./../../components/UploadFile";
import { site } from "../../api/url_helper";
import moment from "moment";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { setDataNull, setViolate } from "../../store/violate/actions";
import { getInternByUserId, getSearchIntern } from "../../store/intern/actions";
import { getViolateTypeByUserId } from "../../store/violate_type/actions";

const avata = "images/avatas/logo-intern-null.svg";

const AddViolateDialog = ({ visible, onHide, toast, internSelected }) => {
  const [globalSearch, setGlobalSearch] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState(globalSearch);

  // Quan ly danh sach intern trong hop tim kiem
  const [internDataSearch, setInternDataSearch] = useState([]);

  // Quan ly danh sach intern dang lua chon
  const [internDataSelect, setInternDataSelect] = useState([]);

  //   const [dataTable, setDataTable] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [clearFiles, setClearFiles] = useState(false);

  const op = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const admin = "syndication";

  const { violateTypeData, internApiData, dataInsert, errorInsert } = useSelector(
    (state) => ({
      violateTypeData: state.ViolateType.dataByUserId,
      internApiData: state.Intern.dataByUserId,
      dataInsert: state.Violate.dataInsert,
      errorInsert: state.Violate.errorInsert,
    }),
    shallowEqual
  );

  console.log(globalSearch);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setGlobalFilterValue(globalSearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [globalSearch]);

  useEffect(() => {
    dispatch(getViolateTypeByUserId());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInternByUserId({ page: 1, limit: 1000, key_search: globalFilterValue }));
  }, [dispatch, globalFilterValue]);

  // nap data danh sach intern cho o tim kiem
  useEffect(() => {
    if (internApiData) {
      setInternDataSearch(internApiData.data);
    }
  }, [internApiData]);

  useEffect(() => {
    if (internSelected) {
      setInternDataSelect(internSelected);
    }
  }, [internSelected]);

  const onUpload = () => {};

  const deleteSyndication = (rowData) => {
    setInternDataSelect((prev) => prev.filter((item) => item.id !== rowData.id));
  };

  const removeInternButton = (rowData) => (
    <Button icon="pi pi-trash" severity="warning" onClick={() => deleteSyndication(rowData)} />
  );

  const getFiles = (files) => {
    setSelectedFiles(files);
  };

  const nameTemplate = (rowData) => (
    <div className="flex flex-row align-items-center py-2">
      <Avatar image={`${rowData.avata}`} shape="circle" className="mr-3" />
      <div className="flex flex-column">
        <span>{`${rowData.first_name_jp} ${rowData.middle_name_jp} ${rowData.last_name_jp}`}</span>
        <span>{`${rowData.first_name_en} ${rowData.middle_name_en} ${rowData.last_name_en}`}</span>
      </div>
    </div>
  );

  const validationSchema = Yup.object({
    violate_date: Yup.date().max(new Date(), t("This value is required")).required(t("This value is required")),
    violate_type_id: Yup.number().required(t("This value is required")),
    violate_address: Yup.string().required(t("This value is required")),
    violate_status: Yup.string().required(t("This value is required")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      violate_date: "",
      violate_type_id: "",
      violate_address: "",
      description: "",
      violate_status: "New",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (internDataSelect.length > 0) {
        const li = internDataSelect.map((intern) => intern.id);
        const datas = {
          ...values,
          list_intern: li,
          violate_date: moment(values.violate_date).format("YYYY-MM-DD HH:mm:ss"),
        };

        dispatch(setViolate(datas, Array.from(selectedFiles)));
      } else {
        toast.current?.show({
          severity: "warn",
          summary: "Please enter complete information",
          detail: "Please select intern",
          life: 5000,
        });
      }
    },
  });

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorInsert.message,
        life: 5000,
      });
      dispatch(setDataNull());
    }
    if (dataInsert) {
      toast.current?.show({
        severity: "success",
        summary: "success",
        detail: "Add Violate Success",
        life: 5000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      setInternDataSelect([]);
      setInternDataSearch([]);
      setClearFiles(!clearFiles);
      onHide();
    }
    // eslint-disable-next-line
  }, [errorInsert, dataInsert]);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{t(option)}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const internOptionTemplate = (option) => {
    return (
      <div className="flex justify-content-between">
        <div className="flex align-items-center gap-2" style={{ minWidth: "15rem" }}>
          <Avatar
            image={option.avata ? `${option.avata}` : avata}
            shape="circle"
            style={{ minWidth: "2rem" }}
            pt={{
              root: {
                style: { width: "2rem", height: "2rem" },
              },
            }}
          />
          <div className="flex flex-column">
            <span>{`${option.first_name_jp} ${option.middle_name_jp} ${option.last_name_jp}`}</span>
            <span>{`${option.first_name_en} ${option.middle_name_en} ${option.last_name_en}`}</span>
          </div>
        </div>
        {admin !== ObjectType.DISPATCHING_COMPANY && (
          <div className="flex align-items-center gap-2" style={{ minWidth: "20rem" }}>
            <Avatar
              image={option.dispatching_company_info.avata ? `${option.dispatching_company_info.avata}` : avata}
              shape="circle"
              style={{ minWidth: "2rem" }}
              pt={{
                root: {
                  style: { width: "2rem", height: "2rem" },
                },
              }}
            />
            <div className="flex flex-column">
              <span>{`${option.dispatching_company_info.name_jp}`}</span>
              <span>{`${option.dispatching_company_info.name_en}`}</span>
            </div>
          </div>
        )}
        {admin !== ObjectType.RECEIVING_FACTORY && (
          <div className="flex align-items-center gap-2" style={{ minWidth: "20rem" }}>
            <Avatar
              image={option.receiving_factory_info.avata ? `${option.receiving_factory_info.avata}` : avata}
              shape="circle"
              style={{ minWidth: "2rem" }}
              pt={{
                root: {
                  style: { width: "2rem", height: "2rem" },
                },
              }}
            />
            <div className="flex flex-column">
              <span>{`${option.receiving_factory_info?.name_jp}`}</span>
              <span>{`${option.receiving_factory_info?.name_en}`}</span>
            </div>
          </div>
        )}
        {admin !== ObjectType.SYNDICATION && (
          <div className="flex align-items-center gap-2" style={{ minWidth: "20rem" }}>
            <Avatar
              image={option.syndication_info?.avata ? `${option.syndication_info?.avata}` : avata}
              shape="circle"
              style={{ minWidth: "2rem" }}
              pt={{
                root: {
                  style: { width: "2rem", height: "2rem" },
                },
              }}
            />
            <div className="flex flex-column">
              <span>{`${option.syndication_info?.name_jp}`}</span>
              <span>{`${option.syndication_info?.name_en}`}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const selectedInternTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        // setInternDataSearch([]);
        // setInternDataSelect([]);
        formik.resetForm();
        onHide();
      }}
      header={t("Add Violate Type")}
      modal
      style={{ width: "88%", height: "90%" }}
      footer={
        <div>
          <Button
            label={t("Cancel")}
            onClick={() => {
              // setInternDataSearch([]);
              // setInternDataSelect([]);
              formik.resetForm();
              onHide();
            }}
            severity="secondary"
            className="p-button-text"
          />
          <Button type="submit" label={t("Save")} onClick={formik.handleSubmit} />
        </div>
      }
    >
      <>
        <div className="flex flex-row gap-4">
          <div className="w-6 p-3 bg-white border-round-md">
            <div className="flex flex-row gap-3 mb-2">
              <div className="flex flex-column w-full">
                <label className="mb-2">{t("Date")}</label>
                <FloatLabel>
                  <Calendar
                    id="violate_date"
                    value={formik.values.violate_date}
                    className="w-full"
                    onChange={(e) => formik.setFieldValue("violate_date", e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    invalid={formik.touched.violate_date && formik.errors.violate_date}
                  />
                  {formik.touched.violate_date && formik.errors.violate_date ? (
                    <div style={{ color: "red" }}>{formik.errors.violate_date.toString()}</div>
                  ) : null}
                </FloatLabel>
              </div>
              <div className="flex flex-column w-full">
                <label className="mb-2">{t("Status")}</label>
                <FloatLabel>
                  <Dropdown
                    id="violate_status"
                    options={Object.values(ViolateStatus)}
                    className="w-full"
                    showClear
                    value={formik.values.violate_status}
                    onChange={(e) => formik.setFieldValue("violate_status", e.value)}
                    placeholder="."
                    invalid={formik.touched.violate_status && formik.errors.violate_status}
                    disabled
                    valueTemplate={selectedCountryTemplate}
                    itemTemplate={countryOptionTemplate}
                  />
                  {formik.touched.violate_status && formik.errors.violate_status ? (
                    <div style={{ color: "red" }}>{formik.errors.violate_status.toString()}</div>
                  ) : null}
                </FloatLabel>
              </div>
            </div>
            <div className="flex flex-row gap-3 mb-2">
              <div className="flex flex-column w-full">
                <label className="mb-2">{t("Violate type")}</label>
                <FloatLabel>
                  <Dropdown
                    id="violate_type_id"
                    name="violate_type_id"
                    options={violateTypeData?.data}
                    value={violateTypeData?.data.find((v) => v.id === formik.values.violate_type_id)}
                    onChange={(e) => formik.setFieldValue("violate_type_id", e.value?.id)}
                    className="w-full"
                    showClear
                    optionLabel="name"
                    placeholder="."
                    invalid={formik.touched.violate_type_id && formik.errors.violate_type_id}
                  />
                  {formik.touched.violate_type_id && formik.errors.violate_type_id ? (
                    <div style={{ color: "red" }}>{formik.errors.violate_type_id.toString()}</div>
                  ) : null}
                </FloatLabel>
              </div>
              <div className="flex flex-column w-full">
                <label className="mb-2">{t("Violation address")}</label>
                <FloatLabel>
                  <InputText
                    id="violate_address"
                    className="w-full"
                    onChange={formik.handleChange}
                    value={formik.values.violate_address}
                    invalid={formik.touched.violate_address && formik.errors.violate_address}
                  />
                  {formik.touched.violate_address && formik.errors.violate_address ? (
                    <div style={{ color: "red" }}>{formik.errors.violate_address.toString()}</div>
                  ) : null}
                </FloatLabel>
              </div>
            </div>
          </div>
          <div className="w-6 p-3 bg-white border-round-md">
            <div className="flex flex-column">
              <label className="mb-2">{t("Description")}</label>
              <InputTextarea
                id="description"
                rows={5}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <div className="w-9 flex flex-column bg-white mt-5 p-3 border-round-md">
            <span className="font-bold mb-3">{t("Add violating intern")}</span>
            <div className="mb-5">
              {/* <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  placeholder="Search & Add"
                  className="w-full"
                  value={globalSearch}
                  onChange={(e) => setGlobalSearch(e.target.value)}
                  onFocus={(event) => op.current && op.current.show(event)}
                  onBlur={() => op.current && op.current.hide()}
                />
                <OverlayPanel ref={op} style={{ width: "60%" }}>
                  <DataTable
                    value={internDataSearch}
                    size="small"
                    // filters={filters}
                    // globalFilterFields={[
                    //   "last_name_jp",
                    //   "last_name_en",
                    //   "syndication",
                    //   "factory",
                    //   "dispatching",
                    // ]}
                    showHeaders={false}
                    scrollable
                    scrollHeight={"400px"}
                  >
                    <Column
                      field="last_name_jp"
                      header={t("Name")}
                      body={nameTemplate}
                      style={{ minWidth: "20%" }}
                    ></Column>
                    {admin !== "syndication" && (
                      <Column field="syndication_info.name_jp" header="Syndication" style={{ width: "20%" }}></Column>
                    )}
                    {admin !== "factory" && (
                      <Column field="receiving_factory_info.name_jp" header="Factory" style={{ width: "20%" }}></Column>
                    )}
                    {admin !== "dispatching" && (
                      <Column
                        field="dispatching_company_info.name_jp"
                        header="Dispatching"
                        style={{ width: "20%" }}
                      ></Column>
                    )}
                    <Column field="residence" header="Residence" style={{ width: "20%" }}></Column>
                    <Column body={addInternButton} headerStyle={{ width: "10%" }}></Column>
                  </DataTable>
                </OverlayPanel>
              </IconField> */}
              <Dropdown
                value={internDataSearch}
                onChange={(e) => {
                  console.log(e.value);
                  setInternDataSelect([...internDataSelect, e.value]);
                }}
                options={internDataSearch}
                optionLabel="first_name_en"
                placeholder="Select a intern"
                filter
                filterBy="full_name_en,full_name_jp"
                // onFilter={(e) => {
                //   console.log(e);
                //   setGlobalSearch(e.filter);
                // }}
                valueTemplate={selectedInternTemplate}
                itemTemplate={internOptionTemplate}
                className="w-full"
              />
            </div>
            <span className="font-bold mb-3">{t("List of added interns")}</span>
            <DataTable value={internDataSelect} paginator rows={10} rowsPerPageOptions={[10, 20, 50, 100]} size="small">
              <Column field="name_jp" header={t("Name")} body={nameTemplate} style={{ width: "20%" }}></Column>
              {admin !== "syndication" && (
                <Column field="syndication_info.name_jp" header={t("Syndication")} style={{ width: "20%" }}></Column>
              )}
              {admin !== "factory" && (
                <Column field="receiving_factory_info.name_jp" header={t("Factory")} style={{ width: "20%" }}></Column>
              )}
              {admin !== "dispatching" && (
                <Column
                  field="dispatching_company_info.name_jp"
                  header={t("Dispatching company")}
                  style={{ width: "20%" }}
                ></Column>
              )}
              <Column body={removeInternButton} headerStyle={{ width: "10%" }}></Column>
            </DataTable>
          </div>
          <div className="w-3 flex flex-column bg-white mt-5 p-3 border-round-md">
            <label className="mb-3 font-bold">{t("Violating images")}</label>
            <UploadFile getFiles={getFiles} onUpload={onUpload} clearFiles={clearFiles} />
          </div>
        </div>
      </>
    </Dialog>
  );
};

export default AddViolateDialog;
