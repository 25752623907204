import {
  GET_WARD_ALL,
  GET_WARD_ALL_SUCCESS,
  GET_WARD_ALL_FAIL,
  GET_WARD_ID,
  GET_WARD_ID_SUCCESS,
  GET_WARD_ID_FAIL,
  GET_WARD_BY_DISTRICT_ID,
  GET_WARD_BY_DISTRICT_ID_SUCCESS,
  GET_WARD_BY_DISTRICT_ID_FAIL,
  SET_WARD,
  SET_WARD_SUCCESS,
  SET_WARD_FAIL,
  UPDATE_WARD,
  UPDATE_WARD_SUCCESS,
  UPDATE_WARD_FAIL,
  DELETE_WARD,
  DELETE_WARD_SUCCESS,
  DELETE_WARD_FAIL
} from "./actionTypes";

export const getWardAll = () => ({
  type: GET_WARD_ALL,
});

export const getWardAllSuccess = data => ({
  type: GET_WARD_ALL_SUCCESS,
  payload: data,
});

export const getWardAllFail = error => ({
  type: GET_WARD_ALL_FAIL,
  payload: error,
});

export const getWardId = id => ({
  type: GET_WARD_ID,
  payload: id,
});

export const getWardIdSuccess = data => ({
  type: GET_WARD_ID_SUCCESS,
  payload: data,
});

export const getWardIdFail = error => ({
  type: GET_WARD_ID_FAIL,
  payload: error,
});

export const getWardByDistrictId = id => ({
  type: GET_WARD_BY_DISTRICT_ID,
  payload: id,
});

export const getWardByDistrictIdSuccess = data => ({
  type: GET_WARD_BY_DISTRICT_ID_SUCCESS,
  payload: data,
});

export const getWardByDistrictIdFail = error => ({
  type: GET_WARD_BY_DISTRICT_ID_FAIL,
  payload: error,
});

export const setWard = data => ({
  type: SET_WARD,
  payload: data
});

export const setWardSuccess = data => ({
  type: SET_WARD_SUCCESS,
  payload: data,
});

export const setWardFail = error => ({
  type: SET_WARD_FAIL,
  payload: error,
});

export const updateWard = (data) => ({
  type: UPDATE_WARD,
  payload: data
});

export const updateWardSuccess = data => ({
  type: UPDATE_WARD_SUCCESS,
  payload: data,
});

export const updateWardFail = error => ({
  type: UPDATE_WARD_FAIL,
  payload: error,
});

export const deleteWard = (data) => ({
  type: DELETE_WARD,
  payload: data
});

export const deleteWardSuccess = data => ({
  type: DELETE_WARD_SUCCESS,
  payload: data,
});

export const deleteWardFail = error => ({
  type: DELETE_WARD_FAIL,
  payload: error,
});