import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_INTERN_BY_ID,
  GET_INTERN_BY_USER_ID,
  GET_INTERN_BY_KEY_ID,
  SET_INTERN,
  UPDATE_INTERN,
  DELETE_INTERN,
  GET_SEARCH_INTERN,
  GET_TIME_LINE,
  GET_LIST_CARD,
} from "./actionTypes";
import {
  getInternByIdSuccess,
  getInternByIdFail,
  getInternByUserIdSuccess,
  getInternByUserIdFail,
  getInternByKeyIdSuccess,
  getInternByKeyIdFail,
  setInternSuccess,
  setInternFail,
  updateInternSuccess,
  updateInternFail,
  deleteInternSuccess,
  deleteInternFail,
  getSearchInternSuccess,
  getSearchInternFail,
  getTimeLineSuccess,
  getTimeLineFail,
  getListCardSuccess,
  getListCardFail,
} from "./actions";

import {
  getInternById,
  getInternDataByUserId,
  getInternDataByKeyId,
  deleteDataIntern,
  getTimeLine,
  getListCard,
} from "../../api/backend_helper";
import axios from "axios";
import * as URL_API from "../../api/url_helper";
import { site } from "../../api/url_helper";

function* fetInternDataId({ payload: id }) {
  try {
    const response = yield call(getInternById, id);
    yield put(getInternByIdSuccess(response));
  } catch (error) {
    yield put(getInternByIdFail(error.response.data));
  }
}

function* fetInternDataByUserId({ payload: data }) {
  try {
    const response = yield call(getInternDataByUserId, data);
    yield put(getInternByUserIdSuccess(response));
  } catch (error) {
    yield put(getInternByUserIdFail(error.response.data));
  }
}
function* fetInternDataByKeyId() {
  try {
    const response = yield call(getInternDataByKeyId);
    yield put(getInternByKeyIdSuccess(response));
  } catch (error) {
    yield put(getInternByKeyIdFail(error.response.data));
  }
}

function* onAddNewIntern(action) {
  try {
    const { values, file } = action.payload;

    const intern = new FormData();
    intern.append("username", values.username);
    intern.append("password", values.password);
    intern.append("is_login", values.is_login);
    intern.append("first_name_jp", values.first_name_jp);
    intern.append("middle_name_jp", values.middle_name_jp);
    intern.append("last_name_jp", values.last_name_jp);
    intern.append("first_name_en", values.first_name_en);
    intern.append("middle_name_en", values.middle_name_en);
    intern.append("last_name_en", values.last_name_en);
    intern.append("country", values.country);
    intern.append("gender", values.gender);
    intern.append("dob", values.dob);
    intern.append("passport_code", values.passport_code);
    intern.append("passport_license_date", values.passport_license_date);
    intern.append("passport_expiration_date", values.passport_expiration_date);
    intern.append("phone_domestically", values.phone_domestically);
    intern.append("phone_abroad", values.phone_abroad);
    intern.append("email", values.email);
    intern.append("description", values.description);
    intern.append("status_ids", JSON.stringify(values.status_ids));
    intern.append("receiving_factory_id", values.receiving_factory_id);
    intern.append(
      "receiving_factory_branch_id",
      values.receiving_factory_branch_id
    );
    intern.append("dispatching_company_id", values.dispatching_company_id);
    intern.append(
      "dispatching_company_branch_id",
      values.dispatching_company_branch_id
    );
    intern.append("syndication_id", values.syndication_id);
    intern.append("syndication_branch_id", values.syndication_branch_id);
    intern.append("entry_date", values.entry_date);
    intern.append("status_of_residence", values.status_of_residence);
    intern.append("card_number", values.card_number);
    intern.append("card_license_date", values.card_license_date);
    intern.append("card_expiration_date", values.card_expiration_date);
    intern.append("career_id", values.career_id);

    intern.append("addresses", JSON.stringify(values.addresses));
    intern.append(
      "alien_registration_cards",
      JSON.stringify(values.alien_registration_cards)
    );

    if (file) {
      intern.append("file", file);
    }

    const response = yield call(
      axios.post,
      URL_API.SET_INTERN,
      intern,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(setInternSuccess(dataUpdate));
  } catch (error) {
    yield put(setInternFail(error.response));
  }
}

function* onUpdateIntern(action) {
  try {
    const { values, file } = action.payload;

    const intern = new FormData();
    intern.append("id", values.id);
    intern.append("username", values.username);
    intern.append("password", values.password);
    intern.append("is_login", values.is_login);
    intern.append("first_name_jp", values.first_name_jp);
    intern.append("middle_name_jp", values.middle_name_jp);
    intern.append("last_name_jp", values.last_name_jp);
    intern.append("first_name_en", values.first_name_en);
    intern.append("middle_name_en", values.middle_name_en);
    intern.append("last_name_en", values.last_name_en);
    intern.append("country", values.country);
    intern.append("gender", values.gender);
    intern.append("dob", values.dob);
    intern.append("passport_code", values.passport_code);
    intern.append("passport_license_date", values.passport_license_date);
    intern.append("passport_expiration_date", values.passport_expiration_date);
    intern.append("phone_domestically", values.phone_domestically);
    intern.append("phone_abroad", values.phone_abroad);
    intern.append("email", values.email);
    intern.append("description", values.description);
    intern.append("status_ids", JSON.stringify(values.status_ids));
    intern.append("receiving_factory_id", values.receiving_factory_id);
    intern.append(
      "receiving_factory_branch_id",
      values.receiving_factory_branch_id
    );
    intern.append("dispatching_company_id", values.dispatching_company_id);
    intern.append(
      "dispatching_company_branch_id",
      values.dispatching_company_branch_id
    );
    intern.append("syndication_id", values.syndication_id);
    intern.append("syndication_branch_id", values.syndication_branch_id);
    intern.append("entry_date", values.entry_date);
    intern.append("career_id", values.career_id);

    intern.append("addresses", JSON.stringify(values.addresses));
    intern.append(
      "alien_registration_cards",
      JSON.stringify(values.alien_registration_cards)
    );

    if (file) {
      intern.append("file", file);
    }

    const response = yield call(
      axios.put,
      URL_API.UPDATE_INTERN,
      intern,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    yield put(updateInternSuccess(dataUpdate));
  } catch (error) {
    console.log("onUpdateIntern - error - saga: ", error);
    yield put(updateInternFail(error.response.data));
  }
}

function* onDeleteIntern({ payload: id }) {
  try {
    const response = yield call(deleteDataIntern, id);
    yield put(deleteInternSuccess(response));
  } catch (error) {
    yield put(deleteInternFail(error.response.data));
  }
}

function* fetchSearchIntern(action) {
  try {
    const response = yield call(axios.get, `${site}/api/intern/search`, {
      params: action.query,
      withCredentials: true,
    });
    yield put(getSearchInternSuccess(response.data));
  } catch (error) {
    yield put(getSearchInternFail(error.message));
  }
}

function* fetchTimeLine({ payload: data }) {
  try {
    const response = yield call(getTimeLine, data);
    yield put(getTimeLineSuccess(response));
  } catch (error) {
    yield put(getTimeLineFail(error.message));
  }
}

function* fetchListCard({ payload: data }) {
  try {
    const response = yield call(getListCard, data);
    yield put(getListCardSuccess(response));
  } catch (error) {
    yield put(getListCardFail(error.message));
  }
}

function* InternSaga() {
  yield takeEvery(GET_INTERN_BY_ID, fetInternDataId);
  yield takeEvery(GET_INTERN_BY_USER_ID, fetInternDataByUserId);
  yield takeEvery(GET_INTERN_BY_KEY_ID, fetInternDataByKeyId);
  yield takeLatest(SET_INTERN, onAddNewIntern);
  yield takeEvery(UPDATE_INTERN, onUpdateIntern);
  yield takeEvery(DELETE_INTERN, onDeleteIntern);
  yield takeEvery(GET_SEARCH_INTERN, fetchSearchIntern);
  yield takeEvery(GET_TIME_LINE, fetchTimeLine);
  yield takeEvery(GET_LIST_CARD, fetchListCard);
}

export default InternSaga;
