import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// import { site } from "../../api/url_helper";
import { Calendar } from "primereact/calendar";
import { ObjectType } from "../../components/Interface";
import { setDataNull, updateReceivingFactory } from "../../store/receiving_factory/actions";
import AddressDetail from "../../components/AddressDetail";
import { setDataNull as setDataNullAddress } from "../../store/address/actions";
import { ConvertLinkGoogleDrive } from "../../ultis/ConvertLinkGoogleDrive";

const ModelEditReceivingFactory = ({ visible, onHide, rowData, toast }) => {
  const [listAddress, setListAddress] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const avata = "./images/avatas/avatar-null.png";
  const checked_icon = "/images/image/check.png";

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAvata, setShowAvata] = useState(avata);

  const [isLogin, setIsLogin] = useState(false);
  const [isHasAccount, setIsHasAccount] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const callBackData = (data) => {
    setListAddress(data);
  };

  const getListAddress = (data) => {
    setListAddress(data);
  };

  const { errorUpdate, dataUpdate } = useSelector(
    (state) => ({
      errorUpdate: state.ReceivingFactory.errorUpdate,
      dataUpdate: state.ReceivingFactory.dataUpdate,
    }),
    shallowEqual
  );

  // Xủ lý file ảnh
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowAvata(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (rowData) {
      setShowAvata(ConvertLinkGoogleDrive(rowData?.info?.icon));
      if (rowData?.user?.id !== undefined) {
        setIsHasAccount(true);
        if (rowData?.user?.access) {
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }

        if (rowData?.user?.active) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      } else {
        setIsHasAccount(false);
        setIsLogin(false);
      }
    }
  }, [rowData]);

  useEffect(() => {
    if (errorUpdate) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorUpdate.message,
        life: 5000,
      });
      dispatch(setDataNull());
    }
    if (dataUpdate) {
      toast.current?.show({
        severity: "success",
        summary: "success",
        detail: "Update Receiving Factory Success",
        life: 5000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      setShowAvata(avata);
      onHide();
    }
    // eslint-disable-next-line
  }, [errorUpdate, dataUpdate]);

  // tao schema khi khong tao tk
  const withoutAccountSchema = Yup.object().shape({
    name_jp: Yup.string().required("This value is required"),
    name_en: Yup.string().required("This value is required"),
    date_of_joining_syndication: Yup.date().max(new Date(), "Date select must be before current date"),
    code: Yup.string(),
  });

  const withAccountSchema = Yup.object().shape({
    name_jp: Yup.string().required("This value is required"),
    name_en: Yup.string().required("This value is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters long").required("Password is required"),
    username: Yup.string().required("Username is required"),
    date_of_joining_syndication: Yup.date().max(new Date(), "Date select must be before current date"),
    code: Yup.string(),
  });

  const getValidationSchema = () => {
    if (isLogin) {
      return withAccountSchema;
    } else {
      return withoutAccountSchema;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: rowData?.info?.id,
      name_jp: rowData?.info.name_jp,
      name_en: rowData?.info.name_en,
      date_of_joining_syndication: rowData?.info.date_of_joining_syndication
        ? new Date(rowData?.info.date_of_joining_syndication)
        : null,
      code: rowData?.info.code,
      description: rowData?.info?.description,
      username: rowData?.user?.username,
      password: isActive ? "********" : process.env.REACT_APP_PASSWORD_DEFAULT,
    },
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      const data = {
        ...values,
        date_of_joining_syndication: values.date_of_joining_syndication
          ? values.date_of_joining_syndication.toISOString()
          : null,
        is_login: isLogin,
        addresses: listAddress,
      };
      dispatch(updateReceivingFactory(data, selectedFile));
    },
  });

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        dispatch(setDataNullAddress());
        onHide();
      }}
      header="Chỉnh sửa Receiving Factory"
      modal
      style={{ width: "90vw", height: "90vh" }}
      footer={
        <div>
          <Button
            label={t("Cancel")}
            onClick={() => {
              formik.resetForm();
              dispatch(setDataNullAddress());
              onHide();
            }}
            severity="secondary"
            className="p-button-text "
          />
          <Button type="submit" label={t("Save")} onClick={formik.handleSubmit} />
        </div>
      }
      pt={{
        content: "surface-100 pt-3",
        footer: "surface-100",
        header: "py-3 pl-4",
      }}
    >
      <div className="flex flex-row gap-5 mb-5">
        <div className="bg-white w-3 p-3 border-round-md">
          <div className="flex border-1 border-300 mb-3 border-round-md">
            <div className="mr-3 p-1">
              <Image src={showAvata} alt="Image" width="93" height="93" />
            </div>
            <div>
              <div className="my-2">
                <Button
                  name="icon"
                  type="file"
                  label={t("Upload logo")}
                  size="small"
                  severity="secondary"
                  outlined
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                />
                <input
                  onChange={handleFileChange}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  hidden
                  name="icon"
                  id="icon"
                />
              </div>
              <div className="flex flex-column">
                <label className="font-italic mb-2">- {t("Image format .jpg .png")}</label>
                <label className="font-italic">- {t("サイズは1MB未満")}</label>
              </div>
            </div>
          </div>
          <div>
            <div className="flex align-items-center mb-3">
              <InputSwitch checked={isLogin} onChange={(e) => setIsLogin(e.value)} />
              <label className="font-bold ml-2">{t("Allow login")}</label>
            </div>
            {(isLogin || isHasAccount) && (
              <div>
                <InputText
                  name="username"
                  placeholder="Tên đăng nhập"
                  className="w-full mb-2"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  invalid={formik.touched.username && formik.errors.username ? true : false}
                  disabled={isHasAccount && isActive}
                />
                <InputText
                  name="password"
                  placeholder="Mật khẩu"
                  className="w-full"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  invalid={formik.touched.password && formik.errors.password ? true : false}
                  disabled={isHasAccount && isActive}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div style={{ color: "red" }}>{formik.errors.password.toString()}</div>
                ) : null}
                {isActive && (
                  <div className="flex flex-row justify-content-center">
                    <img src={checked_icon} width={"20px"} alt="" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="bg-white w-9 p-3 border-round-md">
          <div className="flex flex-row mb-4 gap-3">
            <div className="flex flex-column w-6">
              <label className="mb-2">{t("Receiving Factory")}</label>
              <InputText
                id="name_jp"
                name="name_jp"
                value={formik.values.name_jp}
                onChange={formik.handleChange}
                invalid={formik.touched.name_jp && formik.errors.name_jp ? true : false}
              />
            </div>
            <div className="flex flex-column w-6">
              <label className="mb-2">{t("Receiving Factory (English)")}</label>
              <InputText
                id="name_en"
                name="name_en"
                value={formik.values.name_en}
                onChange={formik.handleChange}
                invalid={formik.touched.name_en && formik.errors.name_en ? true : false}
              />
            </div>
          </div>
          <div className="flex flex-row mb-4 gap-3">
            <div className="flex flex-column w-6">
              <label className="mb-2">Mã Xí nghiệp</label>
              <InputText
                id="code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                invalid={formik.touched.code && formik.errors.code ? true : false}
              />
              {formik.touched.code && formik.errors.code ? (
                <div style={{ color: "red" }}>{formik.errors.code.toString()}</div>
              ) : null}
            </div>
            <div className="flex flex-column w-6">
              <label className="mb-2">{t("Joining date")}</label>
              <Calendar
                value={formik.values.date_of_joining_syndication}
                onChange={(e) => formik.setFieldValue("date_of_joining_syndication", e.value)}
                dateFormat="dd/mm/yy"
              />
              {formik.touched.date_of_joining_syndication && formik.errors.date_of_joining_syndication ? (
                <div style={{ color: "red" }}>{formik.errors.date_of_joining_syndication.toString()}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-column mb-2">
            <label className="mb-2">{t("Description")}</label>
            <InputTextarea
              id="description"
              name="description"
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
      {/* <AddressManage
        getListAddress={getListAddress}
        country_id={2}
        object_id={rowData?.info.receiving_factory_id}
        object_type={ObjectType.RECEIVING_FACTORY}
      /> */}
      <AddressDetail
        object_id={rowData?.info.id}
        object_type={ObjectType.RECEIVING_FACTORY}
        callBackData={callBackData}
      />
    </Dialog>
  );
};

export default ModelEditReceivingFactory;
