export const statusLevelItems = {
  NORMAL: 'normal',
  NEED_ATTENTION: 'need_attention',
  URGENT: 'urgent',
};

export const statusConditionDate = {
  BEFORE: 'before',
  AFTER: 'after',
};

export const statusConditionMilestone = {
  VISA_EXPIRATION_DATE: 'Visa expiration date',
};

export const ObjectType = {
  SYNDICATION: 'syndication',
  DISPATCHING_COMPANY: 'dispatching_company',
  RECEIVING_FACTORY: 'receiving_factory',
  INTERN: 'intern',
};
export const ViolateStatus = {
  NEW: 'New',
  PROCESSING: 'Processing',
  DONE: 'Done',
};
export const TicketCategoryObj = {
  LIFE_SUPPORT: 'Life Support',
  WORK_SUPPORT: 'Work Support',
  PROCEDURE_AND_PAPEWORK_SUPPORT: 'Procedure and Paperwork Support',
  OTHER: 'Other',
};
