import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_DISTRICT_ALL,
  GET_DISTRICT_ID,
  SET_DISTRICT,
  UPDATE_DISTRICT,
  DELETE_DISTRICT,
  GET_DISTRICT_BY_STATE_ID,
} from "./actionTypes";
import {
  getDistrictAllFail,
  getDistrictAllSuccess,
  getDistrictIdSuccess,
  getDistrictIdFail,
  getDistrictByStateIdSuccess,
  getDistrictByStateIdFail,
  setDistrictSuccess,
  setDistrictFail,
  updateDistrictSuccess,
  updateDistrictFail,
  deleteDistrictSuccess,
  deleteDistrictFail,
} from "./actions";

import {
  getDistrictDataAll,
  getDistrictDataId,
  addNewDataDistrict,
  updateDataDistrict,
  deleteDataDistrict,
} from "../../api/backend_helper";
import axios from "axios";
import * as urlAddress from "../../api/url_helper";

function* fetDistrictData() {
  try {
    const response = yield call(getDistrictDataAll);
    yield put(getDistrictAllSuccess(response));
  } catch (error) {
    yield put(getDistrictAllFail(error));
  }
}

function* fetDistrictDataId({ payload: id }) {
  try {
    const response = yield call(getDistrictDataId, id);
    yield put(getDistrictIdSuccess(response));
  } catch (error) {
    yield put(getDistrictIdFail(error));
  }
}

function* fetDistrictByStateDataId({ payload: id }) {
  try {
    const response = yield call(
      axios.get,
      `${urlAddress.GET_DISTRICT_BY_STATE_ID}/${id}`
    );
    yield put(getDistrictByStateIdSuccess(response.data));
  } catch (error) {
    yield put(getDistrictByStateIdFail(error.message));
  }
}

function* onAddNewDistrict({ payload: data }) {
  try {
    const response = yield call(addNewDataDistrict, data);
    yield put(setDistrictSuccess(response));
    yield call(refreshDistrictData);
  } catch (error) {
    yield put(setDistrictFail(error));
  }
}

function* onUpdateDistrict({ payload: data }) {
  try {
    const response = yield call(updateDataDistrict, data);
    yield put(updateDistrictSuccess(response));
    yield call(refreshDistrictData);
  } catch (error) {
    yield put(updateDistrictFail(error));
  }
}

function* onDeleteDistrict({ payload: id }) {
  try {
    const response = yield call(deleteDataDistrict, id);
    yield put(deleteDistrictSuccess(response));
  } catch (error) {
    yield put(deleteDistrictFail(error));
  }
}

function* refreshDistrictData() {
  const response = yield call(getDistrictDataAll);
  yield put(getDistrictAllSuccess(response));
}

function* DistrictSaga() {
  yield takeEvery(GET_DISTRICT_ALL, fetDistrictData);
  yield takeEvery(GET_DISTRICT_ID, fetDistrictDataId);
  yield takeEvery(GET_DISTRICT_BY_STATE_ID, fetDistrictByStateDataId);
  yield takeEvery(SET_DISTRICT, onAddNewDistrict);
  yield takeEvery(UPDATE_DISTRICT, onUpdateDistrict);
  yield takeEvery(DELETE_DISTRICT, onDeleteDistrict);
}

export default DistrictSaga;
