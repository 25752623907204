import {
  GET_DISPATCHING_COMPANY_BY_ID,
  GET_DISPATCHING_COMPANY_BY_ID_SUCCESS,
  GET_DISPATCHING_COMPANY_BY_ID_FAIL,
  GET_DISPATCHING_COMPANY_BY_USER_ID,
  GET_DISPATCHING_COMPANY_BY_USER_ID_SUCCESS,
  GET_DISPATCHING_COMPANY_BY_USER_ID_FAIL,
  GET_DISPATCHING_COMPANY_LIST,
  GET_DISPATCHING_COMPANY_LIST_FAIL,
  GET_DISPATCHING_COMPANY_LIST_SUCCESS,
  GET_DISPATCHING_COMPANY_BY_KEY_ID,
  GET_DISPATCHING_COMPANY_BY_KEY_ID_SUCCESS,
  GET_DISPATCHING_COMPANY_BY_KEY_ID_FAIL,
  SET_DISPATCHING_COMPANY,
  SET_DISPATCHING_COMPANY_SUCCESS,
  SET_DISPATCHING_COMPANY_FAIL,
  UPDATE_DISPATCHING_COMPANY,
  UPDATE_DISPATCHING_COMPANY_SUCCESS,
  UPDATE_DISPATCHING_COMPANY_FAIL,
  DELETE_DISPATCHING_COMPANY,
  DELETE_DISPATCHING_COMPANY_SUCCESS,
  DELETE_DISPATCHING_COMPANY_FAIL,
  SET_DATA_NULL,
} from "./actionTypes";

export const getDispatchingCompanyById = (id) => ({
  type: GET_DISPATCHING_COMPANY_BY_ID,
  payload: id,
});

export const getDispatchingCompanyByIdSuccess = (data) => ({
  type: GET_DISPATCHING_COMPANY_BY_ID_SUCCESS,
  payload: data,
});

export const getDispatchingCompanyByIdFail = (error) => ({
  type: GET_DISPATCHING_COMPANY_BY_ID_FAIL,
  payload: error,
});

export const getDispatchingCompanyByUserId = (id) => ({
  type: GET_DISPATCHING_COMPANY_BY_USER_ID,
  payload: id,
});

export const getDispatchingCompanyByUserIdSuccess = (data) => ({
  type: GET_DISPATCHING_COMPANY_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getDispatchingCompanyByUserIdFail = (error) => ({
  type: GET_DISPATCHING_COMPANY_BY_USER_ID_FAIL,
  payload: error,
});

// Them moi
export const getDispatchingCompanyGetList = () => ({
  type: GET_DISPATCHING_COMPANY_LIST,
});

export const getDispatchingCompanyGetListSuccess = (data) => ({
  type: GET_DISPATCHING_COMPANY_LIST_SUCCESS,
  payload: data,
});

export const getDispatchingCompanyGetListFail = (error) => ({
  type: GET_DISPATCHING_COMPANY_LIST_FAIL,
  payload: error,
});

// end
export const getDispatchingCompanyByKeyId = (id) => ({
  type: GET_DISPATCHING_COMPANY_BY_KEY_ID,
  payload: id,
});

export const getDispatchingCompanyByKeyIdSuccess = (data) => ({
  type: GET_DISPATCHING_COMPANY_BY_KEY_ID_SUCCESS,
  payload: data,
});

export const getDispatchingCompanyByKeyIdFail = (error) => ({
  type: GET_DISPATCHING_COMPANY_BY_KEY_ID_FAIL,
  payload: error,
});

export const setDispatchingCompany = (values, file) => ({
  type: SET_DISPATCHING_COMPANY,
  payload: { values, file },
});

export const setDispatchingCompanySuccess = (data) => ({
  type: SET_DISPATCHING_COMPANY_SUCCESS,
  payload: data,
});

export const setDispatchingCompanyFail = (error) => ({
  type: SET_DISPATCHING_COMPANY_FAIL,
  payload: error,
});

export const updateDispatchingCompany = (values, file) => ({
  type: UPDATE_DISPATCHING_COMPANY,
  payload: { values, file },
});

export const updateDispatchingCompanySuccess = (data) => ({
  type: UPDATE_DISPATCHING_COMPANY_SUCCESS,
  payload: data,
});

export const updateDispatchingCompanyFail = (error) => ({
  type: UPDATE_DISPATCHING_COMPANY_FAIL,
  payload: error,
});

export const deleteDispatchingCompany = (id) => ({
  type: DELETE_DISPATCHING_COMPANY,
  payload: id,
});

export const deleteDispatchingCompanySuccess = (data) => ({
  type: DELETE_DISPATCHING_COMPANY_SUCCESS,
  payload: data,
});

export const deleteDispatchingCompanyFail = (error) => ({
  type: DELETE_DISPATCHING_COMPANY_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
