import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_WARD_ALL,
  GET_WARD_ID,
  SET_WARD,
  UPDATE_WARD,
  DELETE_WARD,
  GET_WARD_BY_DISTRICT_ID,
} from "./actionTypes";
import {
  getWardAllFail,
  getWardAllSuccess,
  getWardIdSuccess,
  getWardIdFail,
  getWardByDistrictIdSuccess,
  getWardByDistrictIdFail,
  setWardSuccess,
  setWardFail,
  updateWardSuccess,
  updateWardFail,
  deleteWardSuccess,
  deleteWardFail,
} from "./actions";

import {
  getWardDataAll,
  getWardDataId,
  addNewDataWard,
  updateDataWard,
  deleteDataWard,
} from "../../api/backend_helper";
import axios from "axios";
import * as urlAddress from "../../api/url_helper";

function* fetWardData() {
  try {
    const response = yield call(getWardDataAll);
    yield put(getWardAllSuccess(response));
  } catch (error) {
    yield put(getWardAllFail(error));
  }
}

function* fetWardDataId({ payload: id }) {
  try {
    const response = yield call(getWardDataId, id);
    yield put(getWardIdSuccess(response));
  } catch (error) {
    yield put(getWardIdFail(error));
  }
}

function* fetWardByDistrictDataId({ payload: id }) {
  try {
    const response = yield call(
      axios.get,
      `${urlAddress.GET_WARD_BY_DISTRICT_ID}/${id}`
    );
    yield put(getWardByDistrictIdSuccess(response.data));
  } catch (error) {
    yield put(getWardByDistrictIdFail(error.message));
  }
}

function* onAddNewWard({ payload: data }) {
  try {
    const response = yield call(addNewDataWard, data);
    yield put(setWardSuccess(response));
    yield call(refreshWardData);
  } catch (error) {
    yield put(setWardFail(error));
  }
}

function* onUpdateWard({ payload: data }) {
  try {
    const response = yield call(updateDataWard, data);
    yield put(updateWardSuccess(response));
    yield call(refreshWardData);
  } catch (error) {
    yield put(updateWardFail(error));
  }
}

function* onDeleteWard({ payload: id }) {
  try {
    const response = yield call(deleteDataWard, id);
    yield put(deleteWardSuccess(response));
  } catch (error) {
    yield put(deleteWardFail(error));
  }
}

function* refreshWardData() {
  const response = yield call(getWardDataAll);
  yield put(getWardAllSuccess(response));
}

function* WardSaga() {
  yield takeEvery(GET_WARD_ALL, fetWardData);
  yield takeEvery(GET_WARD_ID, fetWardDataId);
  yield takeEvery(GET_WARD_BY_DISTRICT_ID, fetWardByDistrictDataId);
  yield takeEvery(SET_WARD, onAddNewWard);
  yield takeEvery(UPDATE_WARD, onUpdateWard);
  yield takeEvery(DELETE_WARD, onDeleteWard);
}

export default WardSaga;
