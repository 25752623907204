import { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes";
import { privateRoutes } from "./routes";

import PrivateLayout from "./components/Layout/PrivateLayout";
import PublicLayout from "./components/Layout/PublicLayout";

import Authmiddleware from "./routes/authMiddleware";
import history from "./routes/history";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/scss/themes/main/green/theme.scss";

import io from "socket.io-client";
import { site } from "./api/url_helper";

import { DataContext } from "./contexts/data/DataProvider";
import socketInstance from "./Singleton/SocketSingleton";

function App() {
  const {
    message,
    setMessage,
    skTicket,
    setSkTicket,
    setSocket,
    setUpdateUserCount,
    notification,
  } = useContext(DataContext);

  useEffect(() => {
    const socket = socketInstance.getSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes history={history}>
        {publicRoutes.map((route, index) => {
          const Page = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <PublicLayout>
                  <Page />
                </PublicLayout>
              }
            />
          );
        })}
        {privateRoutes.map((route, index) => {
          const Page = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Authmiddleware path={route.path}>
                  <PrivateLayout>
                    <Page />
                  </PrivateLayout>
                </Authmiddleware>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
