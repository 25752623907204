import {
  GET_STATUS_BY_ID,
  GET_STATUS_BY_ID_SUCCESS,
  GET_STATUS_BY_ID_FAIL,
  GET_STATUS_BY_USER_ID,
  GET_STATUS_BY_USER_ID_SUCCESS,
  GET_STATUS_BY_USER_ID_FAIL,
  SET_STATUS,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAIL,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  DELETE_STATUS,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAIL,
  SET_DATA_NULL,
} from "./actionTypes";

const INIT_STATE = {
  dataById: null,
  dataByUserId: null,
  dataInsert: null,
  dataUpdate: null,
  dataDelete: null,
  loading: false,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
  error: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const Status = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATUS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_STATUS_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case GET_STATUS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_STATUS_BY_USER_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_STATUS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        dataByUserId: action.payload,
        loading: false,
      };
    case GET_STATUS_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_STATUS:
      return {
        ...state,
        data: action.payload,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_STATUS_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        loadingInsert: false,
        errorInsert: null,
      };

    case SET_STATUS_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case UPDATE_STATUS:
      return {
        ...state,
        data: action.payload,
        dataUpdate: null,
        loadingUpdate: true,
        errorUpdate: null,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        errorUpdate: null,
      };
    case UPDATE_STATUS_FAIL:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: false,
        errorUpdate: action.payload,
      };
    case DELETE_STATUS:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: true,
        errorDelete: null,
      };
    case DELETE_STATUS_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: false,
        errorDelete: null,
      };
    case DELETE_STATUS_FAIL:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: false,
        errorDelete: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        dataInsert: null,
        dataUpdate: null,
        dataDelete: null,
        error: null,
        errorInsert: null,
        errorUpdate: null,
        errorDelete: null,
      };

    default:
      return state;
  }
};

export default Status;
