import {
  GET_WARD_ALL,
  GET_WARD_ALL_SUCCESS,
  GET_WARD_ALL_FAIL,
  GET_WARD_ID,
  GET_WARD_ID_SUCCESS,
  GET_WARD_ID_FAIL,
  GET_WARD_BY_DISTRICT_ID,
  GET_WARD_BY_DISTRICT_ID_SUCCESS,
  GET_WARD_BY_DISTRICT_ID_FAIL,
  SET_WARD,
  SET_WARD_SUCCESS,
  SET_WARD_FAIL,
  UPDATE_WARD,
  UPDATE_WARD_SUCCESS,
  UPDATE_WARD_FAIL,
  DELETE_WARD_SUCCESS,
  DELETE_WARD_FAIL
  } from "./actionTypes";
  
  const INIT_STATE = {
    datas: [],
    dataId: null,
    dataByDistrictId: [],
    data: {},
    loading: false
  };
  
  const Ward = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_WARD_ALL:
        return {
          ...state,
          loading: true,
        };
      case GET_WARD_ALL_SUCCESS:
        return {
          ...state,
          datas: action.payload,
          loading: false
        };
  
      case GET_WARD_ALL_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case GET_WARD_ID:
        return {
          ...state,
          loading: true,
        };
      case GET_WARD_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          dataId: action.payload,
        };
      case GET_WARD_ID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case GET_WARD_BY_DISTRICT_ID:
        return {
          ...state,
          loading: true,
        };
      case GET_WARD_BY_DISTRICT_ID_SUCCESS:
        return {
          ...state,
          dataByDistrictId: action.payload,
          loading: false
        };
      case GET_WARD_BY_DISTRICT_ID_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case SET_WARD:
        return {
          ...state,
          data: action.payload,
        };
      case SET_WARD_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
  
      case SET_WARD_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case UPDATE_WARD:
        return {
          ...state,
          data: action.payload,
        };
      case UPDATE_WARD_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
      case UPDATE_WARD_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case DELETE_WARD_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
      case DELETE_WARD_FAIL:
        return {
          ...state,
          error: action.payload,
        };

      default:
        return state;
    }
  };
  
  export default Ward;