export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const REGISTER_ADMIN = 'REGISTER_ADMIN';
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAIL = 'REGISTER_ADMIN_FAIL';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const VERIFY_KEY = 'VERIFY_KEY';
export const VERIFY_KEY_SUCCESS = 'VERIFY_KEY_SUCCESS';
export const VERIFY_KEY_FAIL = 'VERIFY_KEY_FAIL';

export const VERIFY_KEY_LICENSE = 'VERIFY_KEY_LICENSE';
export const VERIFY_KEY_LICENSE_SUCCESS = 'VERIFY_KEY_LICENSE_SUCCESS';
export const VERIFY_KEY_LICENSE_FAIL = 'VERIFY_KEY_LICENSE_FAIL';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const GET_INFOR = 'GET_INFOR';
export const GET_INFOR_SUCCESS = 'GET_INFOR_SUCCESS';
export const GET_INFOR_FAIL = 'GET_INFOR_FAIL';

export const SET_DATA_NULL = 'SET_DATA_NULL';
