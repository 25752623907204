import { takeEvery, takeLatest, put, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_USER_BY_ID,
  LOGIN,
  LOGOUT,
  REGISTER_ADMIN,
  CHANGE_PASSWORD,
  DELETE_USER,
  REGISTER_USER,
  VERIFY_KEY,
  VERIFY_KEY_LICENSE,
  VERIFY_EMAIL,
  GET_INFOR,
} from "./actionTypes";
import {
  getUserByIdSuccess,
  getUserByIdFail,
  loginSuccess,
  loginFail,
  logoutSuccess,
  registerAdminSuccess,
  registerAdminFail,
  registerUserSuccess,
  registerUserFail,
  verifyKeySuccess,
  verifyKeyFail,
  verifyKeyLicenseSuccess,
  verifyKeyLicenseFail,
  verifyEmailSuccess,
  verifyEmailFail,
  changePasswordSuccess,
  changePasswordFail,
  deleteUserSuccess,
  deleteUserFail,
  getInforSuccess,
  getInforFail,
} from "./actions";

import * as urlApi from "../../api/url_helper";
import { site_front_end } from "../../api/url_helper";

import {
  getUserDataById,
  // getUserDataByUserId,
  registerAdmin,
  registerUser,
  changePassword,
  deleteDataUser,
  verifyKey,
  verifyKeyLicense,
  verifyEmail,
  getInfor,
} from "../../api/backend_helper";
import axios from "axios";

// function* onGetDataByUserId() {
//   try {
//     const response = yield call(getUserDataByUserId);
//     yield put(loginSuccess(response));
//   } catch (error) {
//     yield put(loginFail(error))
//   }
// }

function* onLogin({ payload: credentials }) {
  try {
    const response = yield call(axios.post, urlApi.LOGIN, credentials, {
      withCredentials: true,
    });
    const accessToken = response.data;

    console.log(accessToken);

    yield put(loginSuccess(accessToken));
    localStorage.setItem(
      "object_type",
      JSON.stringify(accessToken.object_type)
    );
    localStorage.setItem("object_id", JSON.stringify(accessToken.object_id));
    localStorage.setItem("userId", accessToken.id)
    localStorage.setItem("auth", true)
    localStorage.setItem("role", accessToken.is_admin)
    // window.location.href = site_front_end;
  } catch (error) {
    console.log("auth saga - login - error: ", error);
    yield put(loginFail(error.response.data));
  }
}

function* onGetDataById({ payload: id }) {
  try {
    const response = yield call(getUserDataById, id);
    yield put(getUserByIdSuccess(response));
  } catch (error) {
    yield put(getUserByIdFail(error));
  }
}

function* onGetDataInfor() {
  try {
    const response = yield call(getInfor);
    yield put(getInforSuccess(response));
  } catch (error) {
    yield put(getInforFail(error));
  }
}

function* onLogout() {
  try {
    const response = yield call(axios.post, urlApi.LOGOUT, null, {
      withCredentials: true,
    });
    yield put(logoutSuccess(response));
    localStorage.clear();
    window.location.href = `${site_front_end}/login`;
  } catch (error) {
    yield put(loginFail(error.response.data));
  }
}

function* onRegisterAdmin({ payload: data }) {
  try {
    const response = yield call(registerAdmin, data);
    yield put(registerAdminSuccess(response));
  } catch (error) {
    yield put(registerAdminFail(error));
  }
}

function* onRegisterUser({ payload: data }) {
  try {
    const response = yield call(registerUser, data);
    yield put(registerUserSuccess(response));
  } catch (error) {
    yield put(registerUserFail(error));
  }
}

function* onVerifyKey({ payload: data }) {
  try {
    const response = yield call(verifyKey, data);
    yield put(verifyKeySuccess(response));
  } catch (error) {
    yield put(verifyKeyFail(error));
  }
}

export function* onVerifyKeyLicense({ payload: data }) {
  try {
    const response = yield call(verifyKeyLicense, data);
    yield put(verifyKeyLicenseSuccess(response));
  } catch (error) {
    yield put(verifyKeyLicenseFail(error));
  }
}

function* onVerifyEmail({ payload: data }) {
  try {
    const response = yield call(verifyEmail, data);
    yield put(verifyEmailSuccess(response));
  } catch (error) {
    yield put(verifyEmailFail(error));
  }
}

function* onChangePassword({ payload: data }) {
  try {
    const response = yield call(changePassword, data);
    yield put(changePasswordSuccess(response));
  } catch (error) {
    yield put(changePasswordFail(error.response.data.message));
  }
}

function* onDeleteUser({ payload: id }) {
  try {
    const response = yield call(deleteDataUser, id);
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* UserSaga() {
  yield takeLatest(LOGIN, onLogin);
  yield takeEvery(GET_INFOR, onGetDataInfor);
  yield takeEvery(GET_USER_BY_ID, onGetDataById);
  yield takeLatest(LOGOUT, onLogout);
  yield takeEvery(REGISTER_ADMIN, onRegisterAdmin);
  yield takeEvery(REGISTER_USER, onRegisterUser);
  yield takeEvery(VERIFY_KEY, onVerifyKey);
  yield takeEvery(VERIFY_KEY_LICENSE, onVerifyKeyLicense);
  yield takeEvery(VERIFY_EMAIL, onVerifyEmail);
  yield takeEvery(CHANGE_PASSWORD, onChangePassword);
  yield takeEvery(DELETE_USER, onDeleteUser);
}

export default UserSaga;
