export const GET_VIOLATE_BY_ID = "GET_VIOLATE_BY_ID";
export const GET_VIOLATE_BY_ID_SUCCESS = "GET_VIOLATE_BY_ID_SUCCESS";
export const GET_VIOLATE_BY_ID_FAIL = "GET_VIOLATE_BY_ID_FAIL";

export const GET_VIOLATE_BY_USER_ID = "GET_VIOLATE_BY_USER_ID";
export const GET_VIOLATE_BY_USER_ID_SUCCESS = "GET_VIOLATE_BY_USER_ID_SUCCESS";
export const GET_VIOLATE_BY_USER_ID_FAIL = "GET_VIOLATE_BY_USER_ID_FAIL";

export const SET_VIOLATE = "SET_VIOLATE";
export const SET_VIOLATE_SUCCESS = "SET_VIOLATE_SUCCESS";
export const SET_VIOLATE_FAIL = "SET_VIOLATE_FAIL";

export const UPDATE_VIOLATE = "UPDATE_VIOLATE";
export const UPDATE_VIOLATE_SUCCESS = "UPDATE_VIOLATE_SUCCESS";
export const UPDATE_VIOLATE_FAIL = "UPDATE_VIOLATE_FAIL";

export const DELETE_VIOLATE = "DELETE_VIOLATE";
export const DELETE_VIOLATE_SUCCESS = "DELETE_VIOLATE_SUCCESS";
export const DELETE_VIOLATE_FAIL = "DELETE_VIOLATE_FAIL";

export const HANDLE_VIOLATE = "HANDLE_VIOLATE";
export const HANDLE_VIOLATE_SUCCESS = "HANDLE_VIOLATE_SUCCESS";
export const HANDLE_VIOLATE_FAIL = "HANDLE_VIOLATE_FAIL";

export const SET_DATA_NULL = "SET_DATA_NULL";
