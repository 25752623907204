import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login, setDataNull, verifyEmail } from '../../store/auth/actions';
import axios from 'axios';
import { BrowserInfo } from '../../ultis/GetInfoClient';
import ModalActiveAccount from './ModalActiveAccount';

import { useTranslation } from 'react-i18next';
import DialogVerify from './DialogVerify';
import { site } from '../../api/url_helper';

function Login() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const browserInfo = BrowserInfo();
  const [client, setClient] = React.useState({});

  //forget password
  const [forgetPassword, setForgetPassword] = useState(false);

  //dialog confirm email
  const [visibleActive, setVisibleActive] = useState(false);
  const [email, setEmail] = useState('');
  //dialog verify code
  const [visibleVerifyCode, setVisibleVerifyCode] = useState(false);
  const [code, setCode] = useState('');

  const { t } = useTranslation();

  // lay thong tin ip nguoi dung
  useEffect(() => {
    axios.get('https://api.ipify.org?format=json').then((res) => {
      browserInfo.ip = res.data.ip;
    });
    setClient(browserInfo);
  }, []);

  const { userData, errorLogin, dataVerifyEmail, errorVerifyEmail } =
    useSelector(
      (state) => ({
        userData: state.User.dataLogin,
        errorLogin: state.User.errorLogin,
        dataVerifyEmail: state.User.dataVerifyEmail,
        errorVerifyEmail: state.User.errorByEmail,
      }),
      shallowEqual
    );

  //validate form login
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(t('Do not leave it blank')),
      password: Yup.string().min(6).required(t('Do not leave it blank')),
    }),
    onSubmit: async (values) => {
      const data = { ...values, ...client };
      dispatch(login(data));
    },
  });

  //check email
  function validateEmail(email) {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  //show error login
  useEffect(() => {
    if (errorLogin) {
      toast.current?.show({
        severity: 'error',
        summary: 'error',
        detail: errorLogin.message,
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [errorLogin]);

  //check quyền đăng nhập
  useEffect(() => {
    if (userData) {
      //access: true - có quyền truy cập
      if (userData.access) {
        //active: true - tài khoản đã được kích hoạt - đăng nhập bình thường
        if (userData.active) {
          toast.current?.show({
            severity: 'success',
            summary: t('Login Success'),
            // detail: 'Đăng nhập thành công',
            life: 1000,
          });
          setTimeout(() => {
            //quyền admin - full quyền
            if (userData.object_type !== 'intern') {
              navigate('/dashboard');
            }
            // quyền intern - chỉ hỗ trợ
            else {
              navigate('/support');
            }
          }, 2000);
        }
        //active: false - is_admin = true => tài khoản admin chưa active => active lại bằng key_license
        else if (!userData.active && userData.is_admin) {
          console.log('tài khoản lỗi');
          toast.current?.show({
            severity: 'error',
            summary: t('Login Fail'),
            detail:
              'Tài khoản chưa được kích hoạt. Nhấn kích hoạt phần mềm và làm theo hướng dẫn.',
            life: 3000,
          });
        } else {
          setForgetPassword(false);
          setVisibleActive(true);
        }
      }
      //access: false - tài khoản bị vô hiệu hóa
      else {
        toast.current?.show({
          severity: 'error',
          summary: t('Login Fail'),
          detail:
            'Tài khoản đang bị vô hiệu hóa. Liên hệ với quản trị viên để được hỗ trợ.',
          life: 3000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // send code to verify email
  const sendCodeToVerifyEmail = () => {
    console.log('email', email);
    if (validateEmail(email)) {
      dispatch(verifyEmail({ email: email }));
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Email is invalid',
        life: 3000,
      });
    }
  };

  // send code to reset password
  const sendCodeToResetPassword = () => {
    if (validateEmail(email)) {
      axios
        .post(`${site}/api/users/check-email`, { email: email })
        .then(() => {
          toast.current?.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Check your email to reset password',
            life: 3000,
          });
          setVisibleActive(false);
          setVisibleVerifyCode(true);
        })
        .catch((error) => {
          toast.current?.show({
            severity: 'error',
            summary: 'Error Message',
            detail: error.response.data.message,
            life: 3000,
          });
        });
    } else {
      toast.current?.show({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Email is invalid',
        life: 3000,
      });
    }
  };

  // verify email success or fail
  useEffect(() => {
    if (dataVerifyEmail) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success Message',
        detail: 'Check your email to activate your account',
        life: 3000,
      });
      dispatch(setDataNull());
      setVisibleActive(false);
      setTimeout(() => {
        setVisibleVerifyCode(true);
      }, 500);
    }
  }, [dataVerifyEmail]);

  useEffect(() => {
    if (errorVerifyEmail) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error Message',
        detail: t(`${errorVerifyEmail.response.data.message}`),
        life: 3000,
      });
      dispatch(setDataNull());
    }
  }, [errorVerifyEmail, dispatch]);

  const handleChangeEmail = () => {
    setVisibleVerifyCode(false);
    setTimeout(() => {
      setVisibleActive(true);
    }, 1000);
  };

  const handleForgotPassword = () => {
    setForgetPassword(true);
    setVisibleActive(true);
  };

  return (
    <div
      className='p-3'
      style={{ width: '500px' }}>
      <form>
        <Toast ref={toast} />
        <div
          className='flex align-content-center justify-content-between bg-green-100 px-5 pt-5'
          style={{
            borderTopLeftRadius: '0.375rem',
            borderTopRightRadius: '0.375rem',
          }}>
          <div className='flex flex-column'>
            <span className='mb-2 text-green-800'>{t('Welcome back')}!</span>
            <span className='text-green-800'>{t('Login now')}.</span>
          </div>
          <Image
            src='/images/logo/logo.png'
            alt='Image'
            width='100'
          />
        </div>
        <div
          className='bg-white p-5'
          style={{
            borderBottomLeftRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}>
          <div className='flex flex-column mb-4'>
            <label className='mb-2'>{t('Username or Email')}</label>
            <InputText
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='username'
              name='username'
              type='text'
              placeholder={t('Username')}
              className='w-full '
              style={{ padding: '1rem' }}
              invalid={
                formik.touched.username && formik.errors.username ? true : false
              }
            />
            {formik.touched.username && formik.errors.username ? (
              <p className='text-red-500'>{formik.errors.username}</p>
            ) : null}
          </div>
          <div className='flex flex-column my-5'>
            <label className='mb-2'>{t('Password')}</label>
            <InputText
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='password'
              name='password'
              type='password'
              autoComplete='off'
              placeholder={t('Password')}
              className='w-full'
              style={{ padding: '1rem' }}
              invalid={
                formik.touched.password && formik.errors.password ? true : false
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <p className='text-red-500'>{formik.errors.password}</p>
            ) : null}
          </div>
          <Button
            type='submit'
            label={t('Login')}
            className='w-full mt-5'
            onClick={formik.handleSubmit}
          />
          <div className='flex justify-content-center flex-row mt-8'>
            <div className='flex align-content-center cursor-pointer hover:text-green-600'>
              <i className='pi pi-key mr-2 font-bold'></i>
              <Link to={'/license'}>
                <span className=''>{t('Activate license')}</span>
              </Link>
            </div>
            <span className='mx-3'>|</span>
            <div
              className='flex align-content-center cursor-pointer hover:text-green-600'
              onClick={handleForgotPassword}>
              <i className='pi pi-lock mr-2'></i>
              <span className=''>{t('Forgot password')}?</span>
            </div>
          </div>
        </div>
      </form>
      <ModalActiveAccount
        visibleActive={visibleActive}
        setVisibleActive={setVisibleActive}
        email={email}
        setEmail={setEmail}
        sendCodeToVerifyEmail={sendCodeToVerifyEmail}
        forgetPassword={forgetPassword}
        sendCodeToResetPassword={sendCodeToResetPassword}
      />
      <DialogVerify
        email={email}
        handleChangeEmail={handleChangeEmail}
        forgetPassword={forgetPassword}
        visible={visibleVerifyCode}
        onHide={() => {
          setVisibleVerifyCode(false);
        }}
        value={code}
        onChange={(e) => {
          setCode(e);
        }}
        reSendCode={() => {
          setCode('');
          let data = forgetPassword
            ? { email: email, action: 'forgot_password' }
            : { email: email };
          axios
            .post(`${site}/api/users/resend-code`, data)
            .then(() => {
              toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Resend verify code success',
                life: 3000,
              });
            })
            .catch((error) => {
              toast.current?.show({
                severity: 'error',
                summary: 'Verify fail',
                detail: 'Re-enter the code or request to resend the code',
                life: 3000,
              });
            });
        }}
        submitClick={() => {
          axios
            .post(`${site}/api/users/verify-code`, {
              email: email,
              code: code,
            })
            .then(() => {
              toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Activate Success. Login to continue',
                life: 3000,
              });
              setTimeout(() => navigate('/login'), 3000);
              setVisibleVerifyCode(false);
              setEmail('');
              setCode('');
            })
            .catch((error) => {
              return toast.current?.show({
                severity: 'error',
                summary: 'Verify fail',
                detail: error.response.data.message,
                life: 3000,
              });
            });
        }}
        resetClick={() => {
          axios
            .post(`${site}/api/users/verify-code`, {
              email: email,
              code: code,
              action: 'forgot_password',
            })
            .then(() => {
              toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Verify Success',
                life: 3000,
              });
              setTimeout(
                () => navigate('/forget-password', { state: { email } }),
                3000
              );
              setVisibleVerifyCode(false);
            })
            .catch((error) => {
              return toast.current?.show({
                severity: 'error',
                summary: 'Verify fail',
                detail: error.response.data.message,
                life: 3000,
              });
            });
        }}
      />
    </div>
  );
}

export default Login;
