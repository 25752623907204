import {
  GET_INTERN_BY_ID,
  GET_INTERN_BY_ID_SUCCESS,
  GET_INTERN_BY_ID_FAIL,
  GET_INTERN_BY_USER_ID,
  GET_INTERN_BY_USER_ID_SUCCESS,
  GET_INTERN_BY_USER_ID_FAIL,
  GET_INTERN_BY_KEY_ID,
  GET_INTERN_BY_KEY_ID_SUCCESS,
  GET_INTERN_BY_KEY_ID_FAIL,
  SET_INTERN,
  SET_INTERN_SUCCESS,
  SET_INTERN_FAIL,
  UPDATE_INTERN,
  UPDATE_INTERN_SUCCESS,
  UPDATE_INTERN_FAIL,
  DELETE_INTERN,
  DELETE_INTERN_SUCCESS,
  DELETE_INTERN_FAIL,
  SET_DATA_NULL,
  GET_SEARCH_INTERN,
  GET_SEARCH_INTERN_SUCCESS,
  GET_SEARCH_INTERN_FAIL,
  GET_TIME_LINE,
  GET_TIME_LINE_SUCCESS,
  GET_TIME_LINE_FAIL,
  GET_LIST_CARD,
  GET_LIST_CARD_SUCCESS,
  GET_LIST_CARD_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  dataTimeLine: [],
  dataListCard: [],
  dataById: null,
  dataByUserId: null,
  dataByKeyId: null,
  dataSearch: null,
  dataInsert: null,
  dataUpdate: null,
  dataDelete: null,
  loading: false,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
  error: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const Intern = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INTERN_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_INTERN_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case GET_INTERN_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_INTERN_BY_USER_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_INTERN_BY_USER_ID_SUCCESS:
      return {
        ...state,
        dataByUserId: action.payload,
        loading: false,
      };
    case GET_INTERN_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    //
    case GET_SEARCH_INTERN:
      return {
        ...state,
        loading: true,
      };
    case GET_SEARCH_INTERN_SUCCESS:
      return {
        ...state,
        dataSearch: action.payload,
        loading: false,
      };
    case GET_SEARCH_INTERN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    //
    case GET_INTERN_BY_KEY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_INTERN_BY_KEY_ID_SUCCESS:
      return {
        ...state,
        dataByKeyId: action.payload,
        loading: false,
      };
    case GET_INTERN_BY_KEY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_INTERN:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_INTERN_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        loadingInsert: false,
        errorInsert: null,
      };

    case SET_INTERN_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case UPDATE_INTERN:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: true,
        errorUpdate: null,
      };
    case UPDATE_INTERN_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        errorUpdate: null,
      };
    case UPDATE_INTERN_FAIL:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: false,
        errorUpdate: action.payload,
      };
    case DELETE_INTERN:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: true,
        errorDelete: null,
      };
    case DELETE_INTERN_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: false,
        errorDelete: null,
      };
    case DELETE_INTERN_FAIL:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: false,
        errorDelete: action.payload,
      };
    case GET_TIME_LINE:
      return {
        ...state,
        loading: true,
      };
    case GET_TIME_LINE_SUCCESS:
      return {
        ...state,
        dataTimeLine: action.payload,
        loading: false,
      };
    case GET_TIME_LINE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_LIST_CARD:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_CARD_SUCCESS:
      return {
        ...state,
        dataListCard: action.payload,
        loading: false,
      };
    case GET_LIST_CARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SET_DATA_NULL:
      return {
        ...state,
        dataById: null,
        dataInsert: null,
        dataUpdate: null,
        dataDelete: null,
        error: null,
        errorInsert: null,
        errorUpdate: null,
        errorDelete: null,
      };

    default:
      return state;
  }
};

export default Intern;
