import {
  GET_SYNDICATION_BY_ID,
  GET_SYNDICATION_BY_ID_SUCCESS,
  GET_SYNDICATION_BY_ID_FAIL,
  GET_SYNDICATION_BY_USER_ID,
  GET_SYNDICATION_BY_USER_ID_SUCCESS,
  GET_SYNDICATION_BY_USER_ID_FAIL,
  GET_SYNDICATION_LIST,
  GET_SYNDICATION_LIST_FAIL,
  GET_SYNDICATION_LIST_SUCCESS,
  GET_SYNDICATION_BY_KEY_ID,
  GET_SYNDICATION_BY_KEY_ID_SUCCESS,
  GET_SYNDICATION_BY_KEY_ID_FAIL,
  SET_SYNDICATION,
  SET_SYNDICATION_SUCCESS,
  SET_SYNDICATION_FAIL,
  UPDATE_SYNDICATION,
  UPDATE_SYNDICATION_SUCCESS,
  UPDATE_SYNDICATION_FAIL,
  DELETE_SYNDICATION,
  DELETE_SYNDICATION_SUCCESS,
  DELETE_SYNDICATION_FAIL,
  SET_DATA_NULL,
} from './actionTypes';

const INIT_STATE = {
  dataById: null,
  dataByUserId: null,
  dataByKeyId: null,
  dataGetList: null,
  dataInsert: null,
  dataUpdate: null,
  dataDelete: null,
  loading: false,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
  error: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const Syndication = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SYNDICATION_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SYNDICATION_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case GET_SYNDICATION_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SYNDICATION_BY_USER_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SYNDICATION_BY_USER_ID_SUCCESS:
      return {
        ...state,
        dataByUserId: action.payload,
        loading: false,
      };
    case GET_SYNDICATION_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    //
    case GET_SYNDICATION_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_SYNDICATION_LIST_SUCCESS:
      return {
        ...state,
        dataGetList: action.payload,
        loading: false,
      };
    case GET_SYNDICATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    //
    case GET_SYNDICATION_BY_KEY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SYNDICATION_BY_KEY_ID_SUCCESS:
      return {
        ...state,
        dataByKeyId: action.payload,
        loading: false,
      };
    case GET_SYNDICATION_BY_KEY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_SYNDICATION:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_SYNDICATION_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        loadingInsert: false,
        errorInsert: null,
      };

    case SET_SYNDICATION_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case UPDATE_SYNDICATION:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: true,
        errorUpdate: null,
      };
    case UPDATE_SYNDICATION_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        errorUpdate: null,
      };
    case UPDATE_SYNDICATION_FAIL:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: false,
        errorUpdate: action.payload,
      };
    case DELETE_SYNDICATION:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: true,
        errorDelete: null,
      };
    case DELETE_SYNDICATION_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: false,
        errorDelete: null,
      };
    case DELETE_SYNDICATION_FAIL:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: false,
        errorDelete: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        dataInsert: null,
        dataUpdate: null,
        dataDelete: null,
        error: null,
        errorInsert: null,
        errorUpdate: null,
        errorDelete: null,
      };

    default:
      return state;
  }
};

export default Syndication;
