import React, { useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { DataView } from 'primereact/dataview';
import { getNotification } from '../../store/dashboard/action';
import './Dashboard.scss';
import { useTranslation } from 'react-i18next';
import { convert } from 'html-to-text';
import { DataContext } from '../../contexts/data/DataProvider';
import { useNavigate } from 'react-router-dom';
import CircleImage from '../../components/CircleImage';

const NewNoti = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataNotification } = useSelector(
    (state) => ({
      dataNotification: state.Dashboard.dataNotification,
      loading: state.Dashboard.loading,
      error: state.Dashboard.error,
    }),
    shallowEqual
  );
  const { t } = useTranslation();
  const { selectedLang } = useContext(DataContext);
  const auth = JSON.parse(localStorage.getItem('auth'));

  const formatDate = useMemo(
    () => (date) => {
      const now = new Date();
      const yesterday = new Date(now.getTime() - 86400000);
      const messageDate = new Date(date);
      const isToday = now.toDateString() === messageDate.toDateString();
      const isYesterday =
        yesterday.toDateString() === messageDate.toDateString();

      const timeString = messageDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });

      if (isToday) {
        return timeString;
      } else if (isYesterday) {
        return `${t('Yesterday')} ${timeString}`;
      } else {
        return `${messageDate.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })} ${timeString}`;
      }
    },
    [t]
  );

  useEffect(() => {
    if (auth) {
      dispatch(
        getNotification({
          page: 1,
          limit: 3,
        })
      );
    }
  }, [dispatch]);

  const templateContentMessage = (rowData) => {
    const options = {
      wordwrap: 130,
      ignoreImage: true,
      ignoreHref: true,
      preserveNewlines: true,
      baseElement: 'body',
    };

    return (
      <p className='overflow-hidden white-space-nowrap text-overflow-ellipsis font-bold'>
        {selectedLang === 'vi' ? convert(rowData.title, options) : null}
        {selectedLang === 'en' ? convert(rowData.title, options) : null}
        {selectedLang === 'ja' ? convert(rowData.title, options) : null}
      </p>
    );
  };

  const itemTemplate = (item) => (
    <div className='p-dview-item py-4 w-full border-round-xl'>
      <div className='flex border-round-xl justify-content-between'>
        <div className='flex justify-content-center align-items-center w-2'>
          <CircleImage
            src={item.sender_info.avata}
            alt='Avatar'
            size={55}
          />
        </div>
        <div className='w-10 flex flex-column justify-content-center gap-2'>
          {templateContentMessage(item)}
          <div className='text-base flex justify-content-between'>
            <div>{item.sender_info.name}</div>
            <div>{formatDate(item.send_at)}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='p-3'>
      <div className='flex justify-content-between'>
        <p className='text-xl font-bold'>{t('New Notify')}</p>
        <a
          onClick={() => {
            navigate('/notification');
          }}
          style={{ color: '#0C4DA2', cursor: 'pointer' }}>
          {t('View All')}
        </a>
      </div>

      <DataView
        value={dataNotification.data}
        emptyMessage={t('No data available')}
        itemTemplate={itemTemplate}
        layout='list'
      />
    </div>
  );
};

export default NewNoti;
