import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Toast } from "primereact/toast";
import { getTicketList } from "../../store/ticket/action";
import MailboxSidebar from "./MailboxSidebar";
import TicketList from "./TicketList";
import TicketDetail from "./TicketDetail";
import "./Support.css";
import { useLocation } from "react-router-dom";
import { getInfor } from "../../store/auth/actions";
import socketInstance from '../../Singleton/SocketSingleton';

const ModalAdd = lazy(() => import("./ModalAdd"));
const ROWS_PER_PAGE = 10;

const Support = () => {
  const location = useLocation();
  const [tabMenuActive, setTabMenuActive] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ticketBox, setTicketBox] = useState("Inbox");
  const [ticketStatus, setTicketStatus] = useState(
    location.state?.ticket_status ?? "All"
  );
  const [dialogVisible, setDialogVisible] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(ROWS_PER_PAGE);
  const toast = useRef(null);
  const [category, setCategory] = useState(null);
  const [global, setGlobal] = useState(null);
  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem("auth"));

  const { dataTicketList, menu, totalInbox, totalOutbox, total } = useSelector(
    (state) => ({
      dataTicketList: state.Ticket.dataTicketList,
      menu: state.Ticket.menuTicketList,
      loadingTranslate: state.Ticket.loadingTranslate,
      totalInbox: state.Ticket.totalInBox,
      totalOutbox: state.Ticket.totalOutBox,
      total: state.Ticket.total,
    }),
    shallowEqual
  );

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const socket = socketInstance.getSocket();

    // Lắng nghe sự kiện "get-message"
    socket.on('get-message', (msg) => {
      console.log('Received message: ', msg);
      // Cập nhật state với tin nhắn mới
      setMessages(prevMessages => [...prevMessages, msg]);
    });

    // Cleanup function để loại bỏ sự kiện lắng nghe khi component unmount
    return () => {
      socket.off('get-message');
    };
  }, []);


  useEffect(() => {
    dispatch(
      getTicketList({
        page: 1,
        limit: rows,
        ticket_box: ticketBox,
        ticket_status: ticketStatus,
        s_category: category,
        s_global: global,
      })
    );
  }, [dispatch, ticketBox, ticketStatus, rows, category, global, messages]);

  useEffect(() => {
    if(auth) {
      dispatch(getInfor());
    }
  }, [dispatch]);

  useEffect(() => {
    const newStatus = menu?.[tabMenuActive]?.name;
    if (location.state?.ticket_status != null) {
      setTabMenuActive(1);
    }
    setTicketStatus(newStatus);
  }, [tabMenuActive, menu, ticketStatus]);

  const onPageChange = useCallback(
    (event) => {
      setFirst(event.first);
      setRows(event.rows);
      dispatch(
        getTicketList({
          page: Math.floor(event.first / event.rows) + 1,
          limit: event.rows,
          ticket_box: ticketBox,
          // ticket_status: ticketStatus || "All",
          ticket_status: ticketStatus || "All",
        })
      );
    },
    [dispatch, ticketBox, ticketStatus]
  );

  const handleMailboxClick = useCallback((newTicketBox) => {
    setTicketBox(newTicketBox);
    setSelectedRow(null);
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-row flex-wrap">
        <div className="w-12 md:w-3 md:pr-3">
          <MailboxSidebar
            ticketBox={ticketBox}
            totalInbox={totalInbox}
            totalOutbox={totalOutbox}
            onMailboxClick={handleMailboxClick}
            onAddNew={() => setDialogVisible(true)}
            setCategory={setCategory}
          />
        </div>
        <div className="w-12 md:w-9 border-round-md border-1 border-200 px-4 py-2 surface-50">
          {!selectedRow ? (
            <TicketList
              total={total}
              dataTicketList={dataTicketList}
              tabMenuActive={tabMenuActive}
              setTabMenuActive={setTabMenuActive}
              menu={menu}
              first={first}
              rows={rows}
              onPageChange={onPageChange}
              setSelectedRow={setSelectedRow}
              setGlobal={setGlobal}
              ticketBox={ticketBox}
            />
          ) : (
            <TicketDetail
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              toast={toast}
              ticket_box={ticketBox}
            />
          )}
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <ModalAdd
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          toast={toast}
        />
      </Suspense>
    </>
  );
};

export default React.memo(Support);
