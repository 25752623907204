import {
  GET_BRANCH_RECEIVING,
  GET_BRANCH_RECEIVING_SUCCESS,
  GET_BRANCH_RECEIVING_FAIL,
  GET_BRANCH_SYNDICATION,
  GET_BRANCH_SYNDICATION_SUCCESS,
  GET_BRANCH_SYNDICATION_FAIL,
  GET_BRANCH_DISPATCHING,
  GET_BRANCH_DISPATCHING_SUCCESS,
  GET_BRANCH_DISPATCHING_FAIL,
  GET_BRANCH_FULL,
  GET_BRANCH_FULL_SUCCESS,
  GET_BRANCH_FULL_FAIL,
  SET_DATA_NULL,
} from './actionTypes';

const INIT_STATE = {
  dataBranchReceving: [],
  dataBranchSyndication: [],
  dataBranchDispatching: [],
  dataBranchFull: [],
  loading: false,
};

const Address = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCH_RECEIVING:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANCH_RECEIVING_SUCCESS:
      return {
        ...state,
        dataBranchReceving: action.payload,
        loading: false,
      };
    case GET_BRANCH_RECEIVING_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_BRANCH_SYNDICATION:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANCH_SYNDICATION_SUCCESS:
      return {
        ...state,
        dataBranchSyndication: action.payload,
        loading: false,
      };
    case GET_BRANCH_SYNDICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_BRANCH_DISPATCHING:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANCH_DISPATCHING_SUCCESS:
      return {
        ...state,
        dataBranchDispatching: action.payload,
        loading: false,
      };
    case GET_BRANCH_DISPATCHING_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_BRANCH_FULL:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANCH_FULL_SUCCESS:
      return {
        ...state,
        dataBranchFull: action.payload,
        loading: false,
      };
    case GET_BRANCH_FULL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        dataBranchFull: [],
      };
    default:
      return state;
  }
};

export default Address;
