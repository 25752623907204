import React from 'react';
import { useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button } from 'primereact/button';
import { changePassword, setDataNull } from '../../store/auth/actions';

function ChangePassword() {
  const toast = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { dataChangePassword, errorChangePassword } = useSelector(
    (state) => ({
      dataChangePassword: state.User.dataChangePassword,
      errorChangePassword: state.User.errorChangePassword,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required(t('Do not leave it blank')),
      newPassword: Yup.string()
        .min(6, t('Password must be at least 6 characters long'))
        .required(t('Do not leave it blank'))
        .notOneOf(
          [Yup.ref('oldPassword')],
          t('New password must be different from old password')
        ),
      confirmPassword: Yup.string()
        .required(t('Please confirm your new password'))
        .oneOf([Yup.ref('newPassword'), null], t('Password incorrect')),
    }),
    onSubmit: async (values) => {
      dispatch(
        changePassword({
          current_password: values.oldPassword,
          new_password: values.newPassword,
        })
      );
    },
  });

  useEffect(() => {
    if (dataChangePassword) {
      toast.current.show({
        severity: 'success',
        summary: 'Success Message',
        detail: 'Change password successfully',
        life: 3000,
      });
      dispatch(setDataNull());
      formik.resetForm();
    }
    if (errorChangePassword) {
      toast.current.show({
        severity: 'error',
        summary: 'Error Message',
        detail: errorChangePassword,
        life: 3000,
      });
    }
  }, [dataChangePassword, errorChangePassword]);

  return (
    <div className='bg-white p-3 flex justify-content-center'>
      <form>
        <Toast ref={toast} />
        <div
          className=' p-5 '
          style={{
            borderBottomLeftRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
            width: '500px',
          }}>
          <div className='flex flex-column mb-4 font-bold text-2xl text-center p-5'>
            {t('Change password')}
          </div>
          <div className='flex flex-column mb-4'>
            <label className='mb-1'>{t('Current password')}</label>
            <Password
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              placeholder={t('Current password')}
              feedback={false}
              toggleMask
              autoComplete='off'
              name='oldPassword'
              className='w-full mb-2'
              inputClassName='w-full p-3'
              pt={{ iconField: { root: { className: 'w-full' } } }}
              invalid={formik.touched.password && !!formik.errors.password}
            />
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
              <p className='text-red-500'>{formik.errors.oldPassword}</p>
            ) : null}
          </div>
          <div className='flex flex-column my-5'>
            <label className='mb-1'>{t('New password')}</label>
            <Password
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              placeholder={t('New password')}
              toggleMask
              feedback={false}
              autoComplete='off'
              name='newPassword'
              className='w-full mb-2'
              inputClassName='w-full p-3'
              pt={{ iconField: { root: { className: 'w-full' } } }}
              invalid={
                formik.touched.newPassword && !!formik.errors.newPassword
              }
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <p className='text-red-500'>{formik.errors.newPassword}</p>
            ) : null}
          </div>
          <div className='flex flex-column my-5'>
            <label className='mb-1'>{t('Confirm password')}</label>
            <Password
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              placeholder={t('Confirm password')}
              toggleMask
              feedback={false}
              autoComplete='off'
              name='confirmPassword'
              className='w-full mb-2'
              inputClassName='w-full p-3'
              pt={{ iconField: { root: { className: 'w-full' } } }}
              invalid={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className='text-red-500'>{formik.errors.confirmPassword}</p>
            ) : null}
          </div>
          <Button
            type='submit'
            label={t('Update')}
            className='w-full mt-5'
            onClick={formik.handleSubmit}
          />
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
