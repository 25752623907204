import {
  GET_ANNOUNCED,
  GET_ANNOUNCED_SUCCESS,
  GET_ANNOUNCED_FAIL,
  SET_ANNOUNCED,
  SET_ANNOUNCED_SUCCESS,
  SET_ANNOUNCED_FAIL,
  GET_LIST_TARGET,
  GET_LIST_TARGET_SUCCESS,
  GET_LIST_TARGET_FAIL,
  GET_LIST_SYNDICATION,
  GET_LIST_SYNDICATION_SUCCESS,
  GET_LIST_SYNDICATION_FAIL,
  GET_LIST_FACTORY,
  GET_LIST_FACTORY_SUCCESS,
  GET_LIST_FACTORY_FAIL,
  GET_LIST_DISPATCH,
  GET_LIST_DISPATCH_SUCCESS,
  GET_LIST_DISPATCH_FAIL,
  GET_LIST_INTERN,
  GET_LIST_INTERN_SUCCESS,
  GET_LIST_INTERN_FAIL,
  GET_LIST_INTERN_TARGET,
  GET_LIST_INTERN_TARGET_SUCCESS,
  GET_LIST_INTERN_TARGET_FAIL,
  SET_DATA_NULL,
} from './actionTypes';

const INIT_STATE = {
  announced: [],
  announcedInsert: null,
  listTarget: [],
  listInternTarget: null,
  listSyndication: [],
  listFactory: [],
  listDispatch: [],
  listIntern: [],
  loading: false,
  error: null,
  errorInsert: null,
  loadingInsert: false,
};

const Announced = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANNOUNCED:
      return {
        ...state,
        loading: true,
      };
    case GET_ANNOUNCED_SUCCESS:
      return {
        ...state,
        announced: action.payload,
        loading: false,
      };
    case GET_ANNOUNCED_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SET_ANNOUNCED:
      return {
        ...state,
        loadingInsert: true,
      };
    case SET_ANNOUNCED_SUCCESS:
      return {
        ...state,
        announcedInsert: action.payload,
        loadingInsert: false,
      };
    case SET_ANNOUNCED_FAIL:
      return {
        ...state,
        errorInsert: action.payload,
        loadingInsert: false,
      };

    case GET_LIST_TARGET:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_TARGET_SUCCESS:
      return {
        ...state,
        listTarget: action.payload,
        loading: false,
      };
    case GET_LIST_TARGET_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LIST_SYNDICATION:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_SYNDICATION_SUCCESS:
      return {
        ...state,
        listSyndication: action.payload,
        loading: false,
      };
    case GET_LIST_SYNDICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LIST_FACTORY:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_FACTORY_SUCCESS:
      return {
        ...state,
        listFactory: action.payload,
        loading: false,
      };
    case GET_LIST_FACTORY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LIST_DISPATCH:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_DISPATCH_SUCCESS:
      return {
        ...state,
        listDispatch: action.payload,
        loading: false,
      };
    case GET_LIST_DISPATCH_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LIST_INTERN:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_INTERN_SUCCESS:
      return {
        ...state,
        listIntern: action.payload,
        loading: false,
      };
    case GET_LIST_INTERN_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LIST_INTERN_TARGET:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_INTERN_TARGET_SUCCESS:
      return {
        ...state,
        listInternTarget: action.payload,
        loading: false,
      };
    case GET_LIST_INTERN_TARGET_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SET_DATA_NULL:
      return {
        ...state,
        // announced: null,
        announcedInsert: null,
        listSyndication: [],
        listInternTarget: null,
        loading: false,
        error: null,
        errorInsert: null,
        loadingInsert: false,
      };

    default:
      return state;
  }
};

export default Announced;
