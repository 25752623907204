import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import CircleImage from '../../components/CircleImage';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AddressDetail from '../../components/AddressDetail';
import { useTranslation } from 'react-i18next';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
// import { site } from "./../../api/url_helper";
import { useFormik } from 'formik';
import { updateSyndication } from '../../store/syndication/actions';
import { updateReceivingFactory } from '../../store/receiving_factory/actions';
import { updateDispatchingCompany } from '../../store/dispatching_company/actions';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { getInfor } from '../../store/auth/actions';
import { useNavigate } from 'react-router-dom';
import { ObjectType } from '../../components/Interface';

function Profile() {
  const [showAvata, setShowAvata] = useState();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [address, setAddress] = useState([]);
  const auth = JSON.parse(localStorage.getItem('auth'));

  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef(null);

  const callBackData = (data) => {
    setAddress(data);
  };

  //data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowAvata(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { inforUser } = useSelector(
    (state) => ({
      inforUser: state.User.inforUser?.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (auth) {
      dispatch(getInfor());
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: inforUser?.object_id,
      name_jp: inforUser?.object_name,
      name_en: inforUser?.object_name_en,
      code: inforUser?.code,
      description: '',
      username: '',
      password: '',
      is_login: true,
      type: 'profile',
    },
    validationSchema: Yup.object().shape({
      name_jp: Yup.string().required('This value is required'),
      // name_en: Yup.string().required('This value is required'),
      // code: Yup.string().required('This value is required'),
    }),
    onSubmit: (values) => {
      const data = { ...values, addresses: address };
      switch (inforUser?.object_type) {
        case 'syndication':
          dispatch(updateSyndication(data, selectedFile));
          break;
        case 'receiving_factory':
          dispatch(updateReceivingFactory(data, selectedFile));
          break;
        case 'dispatching_company':
          dispatch(updateDispatchingCompany(data, selectedFile));
          break;
        default:
          break;
      }
      setTimeout(async () => {
        window.location.reload();
      }, 2000);
    },
  });

  return (
    <div className='card border-round-md bg-white flex justify-content-center p-5'>
      <Toast ref={toast} />
      <div
        className='p-3'
        style={{ width: '50vw' }}>
        <div className='flex flex-column mb-3 justify-content-center align-items-center'>
          <div className='flex flex-column align-items-center'>
            <CircleImage
              src={showAvata ? showAvata : `${inforUser?.avata}`}
              size={100}
              altImage='Avatar'
            />
            <div className='my-2 text-center'>
              <Button
                name='icon'
                type='file'
                label={t('Upload Photo')}
                size='small'
                severity='primary'
                text
                className='text-base'
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              />
              <input
                onChange={handleFileChange}
                multiple={false}
                ref={fileInputRef}
                type='file'
                accept='image/*'
                hidden
                name='icon'
                id='icon'
              />
            </div>
            <div className='font-bold'>
              {inforUser?.object_type === ObjectType.INTERN ? t('Intern Code') : t('Company Code')}: {inforUser?.code}
            </div>
          </div>
        </div>
        <div className='flex mb-2 justify-content-between p-3'>
          <div className='flex flex-column w-5'>
            <label
              htmlFor='name_jp'
              className='mb-2'>
              {inforUser?.object_type === ObjectType.INTERN ? t('Intern name') : t('Company name')}
            </label>
            <InputText
              value={formik.values.name_jp || ''}
              id='name_jp'
              name='name_jp'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.errors.name_jp}
            />
          </div>
          <div className='flex flex-column w-5'>
            <label
              htmlFor='name_en'
              className='mb-2'>
              {inforUser?.object_type === ObjectType.INTERN ? t('Intern name(English)') : t('Company name(English)')}
            </label>
            <InputText
              id='name_en'
              name='name_en'
              value={formik.values.name_en || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.errors.name_en}
            />
          </div>
        </div>

        <div>
          <AddressDetail
            object_id={inforUser?.object_id}
            object_type={inforUser?.object_type}
            callBackData={callBackData}
          />
        </div>

        <div className='text-center'>
          <Button
            label='Lưu thay đổi'
            type='submit'
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default Profile;
