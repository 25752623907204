import React from 'react';
import { Image } from 'primereact/image';
import SelectLanguage from '../../SelectLanguage';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();
  return (
    <div className='bg-white flex justify-content-center p-2'>
      <div
        className='flex flex-row justify-content-between'
        style={{ width: '500px' }}>
        <div className='flex align-items-center ml-2'>
          <Image
            src='/images/logo/logo.png'
            alt='Image'
            width='50'
          />
          <span className='ml-3 font-bold'>LOTUS APP</span>
        </div>
        <div className='flex align-items-center'>
          <span className='mb-1'>{t('Language')}:</span>
          <SelectLanguage />
        </div>
      </div>
    </div>
  );
}

export default Header;
