import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

const ActionMenu = ({
  onViewDetail,
  onEdit,
  onDelete,
  onUpdate,
  onAddHandleViolate,
  type,
}) => {
  const { t } = useTranslation();
  const menuAction = useRef(null);
  const role = JSON.parse(localStorage.getItem("role"));

  const actions = [];

  if (type === "intern") {
    actions.push({
      label: t("View internship process"),
      icon: "pi pi-calendar-clock",
      command: onUpdate,
    });
  } else if (type === "violate") {
    actions.push({
      label: t("Add processing"),
      icon: "pi pi-plus",
      command: onAddHandleViolate,
    });
  }

  if (role) {
    actions.push({
      label: t("Edit"),
      icon: "pi pi-pencil",
      command: onEdit,
    });

    actions.push({
      label: t("Delete"),
      icon: (
        <i className="pi pi-trash" style={{ color: "red", marginRight: "8px" }} />
      ),
      command: onDelete,
    });
  }



  return (
    <div className="flex justify-content-end align-items-center">
      <i
        className="pi pi-eye cursor-pointer"
        onClick={() => {type === "violate"?onAddHandleViolate():onEdit()}}></i>
      <div>
        <Menu
          model={actions}
          popup
          ref={menuAction}
          className="w-auto"
          pt={{ menuitem: "mx-2" }}
        />
        <Button
          icon="pi pi-ellipsis-v"
          className="mr-2"
          onClick={(event) => {
            menuAction.current?.toggle(event);
          }}
          text
          rounded
          style={{ color: 'black' }}
        />
      </div>
    </div>
  );
};

export default ActionMenu;
