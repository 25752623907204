import './Dashboard.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HeaderDashboard = ({ dataIntern }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='grid'>
      <div
        className='header-container col m-3 p-3 pt-5'
        style={{ cursor: 'pointer', justifyContent: 'space-between' }}
        onClick={() => {
          navigate('/support', {
            state: { ticket_status: 'New' },
          });
        }}>
        <div className='grid'>
          <div
            className='icon'
            style={{ backgroundColor: '#E4E4FF', marginLeft: '1rem' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='2em'
              height='2em'
              viewBox='0 0 24 24'>
              <path
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d='M8.5 14.5h7m-7-5H12m2.17 11.39c4.184-.277 7.516-3.657 7.79-7.9c.053-.83.053-1.69 0-2.52c-.274-4.242-3.606-7.62-7.79-7.899a33 33 0 0 0-4.34 0c-4.184.278-7.516 3.657-7.79 7.9a20 20 0 0 0 0 2.52c.1 1.545.783 2.976 1.588 4.184c.467.845.159 1.9-.328 2.823c-.35.665-.526.997-.385 1.237c.14.24.455.248 1.084.263c1.245.03 2.084-.322 2.75-.813c.377-.279.566-.418.696-.434s.387.09.899.3c.46.19.995.307 1.485.34c1.425.094 2.914.094 4.342 0'
                color='currentColor'
              />
            </svg>
          </div>
          <div
            className='flex flex-column justify-content-between text-xl font-bold '
            style={{ marginLeft: '1rem' }}>
            <p>{t('New support requests')}</p>
            <p className='text-2xl'>{dataIntern.ticketCount}</p>
          </div>
        </div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1em'
          height='1em'
          viewBox='0 0 1024 1024'>
          <path
            fill='currentColor'
            d='M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0'
          />
        </svg>
      </div>
      <div
        className='header-container col m-3 p-3 pt-5'
        style={{ cursor: 'pointer', justifyContent: 'space-between' }}
        onClick={() => {
          navigate('/violate', {
            state: { violate_status: 'New' },
          });
        }}>
        <div className='grid'>
          <div
            className='icon'
            style={{ backgroundColor: '#FDEAE8', marginLeft: '1rem' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1em'
              height='1em'
              viewBox='0 0 24 24'>
              <path
                fill='currentColor'
                d='M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14zM17 8.4L13.4 12l3.6 3.6l-1.4 1.4l-3.6-3.6L8.4 17L7 15.6l3.6-3.6L7 8.4L8.4 7l3.6 3.6L15.6 7z'
                style={{ color: 'EF3826' }}
              />
            </svg>
          </div>
          <div
            className='flex flex-column justify-content-between text-xl font-bold '
            style={{ marginLeft: '1rem' }}>
            <p>{t('New Violations')}</p>
            <p className='text-2xl'>{dataIntern.violateCount}</p>
          </div>
        </div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1em'
          height='1em'
          viewBox='0 0 1024 1024'>
          <path
            fill='currentColor'
            d='M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0'
          />
        </svg>
      </div>
      <div
        className='header-container col m-3 p-3 pt-5'
        style={{ cursor: 'pointer', justifyContent: 'space-between' }}
        onClick={() => {
          navigate('/intern', {
            state: { intern_status: 'Visa expiration date' },
          });
        }}>
        <div className='grid'>
          <div
            className='icon'
            style={{ backgroundColor: '#FFF0D5', marginLeft: '1rem' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1em'
              height='1em'
              viewBox='0 0 24 24'>
              <path
                fill='currentColor'
                fillRule='evenodd'
                d='M16.67 13.13C18.04 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87'
                style={{ color: '#8280FF' }}
              />
              <circle
                cx='9'
                cy='8'
                r='4'
                fill='currentColor'
                fillRule='evenodd'
                style={{ color: '#B5AEEE' }}
              />
              <path
                fill='currentColor'
                fillRule='evenodd'
                d='M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-.47 0-.91.1-1.33.24a5.98 5.98 0 0 1 0 7.52c.42.14.86.24 1.33.24m-6 1c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4'
                style={{ color: '#8280FF' }}
              />
            </svg>
          </div>
          <div
            className='flex flex-column justify-content-between text-xl font-bold '
            style={{ marginLeft: '1rem' }}>
            <p>{t('Interns with Expiring Visas')}</p>
            <p className='text-2xl'>{dataIntern.internExpiredVisaCount}</p>
          </div>
        </div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1em'
          height='1em'
          viewBox='0 0 1024 1024'>
          <path
            fill='currentColor'
            d='M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8l-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0'
          />
        </svg>
      </div>
    </div>
  );
};

export default HeaderDashboard;
