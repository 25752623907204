import React, { useState } from "react";
import DetailMessage from "./detailNotify";
import ListNotify from "./listNotify";

function Notification() {
  const [selectedNotify, setSelectedNotify] = useState(null);
  const [tabMenuActive, setTabMenuActive] = useState(0);

  return (
    <>
      {selectedNotify == null ? (
        <ListNotify
          selectedNotify={selectedNotify}
          setSelectedNotify={setSelectedNotify}
          tabMenuActive={tabMenuActive}
          setTabMenuActive={setTabMenuActive}
        />
      ) : (
        <DetailMessage
          selectedNotify={selectedNotify}
          setSelectedNotify={setSelectedNotify}
          tabMenuActive={tabMenuActive}
          setTabMenuActive={setTabMenuActive}
        />
      )}
    </>
  );
}

export default Notification;
