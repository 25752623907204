import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputOtp } from 'primereact/inputotp';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

const DialogVerify = ({
  visible,
  handleChangeEmail,
  resetClick,
  onHide,
  submitClick,
  reSendCode,
  value,
  onChange,
  email,
  forgetPassword,
}) => {
  const { t } = useTranslation();

  const handleResetPassword = () => {
    resetClick();
  };

  const handleSubmit = () => {
    submitClick();
  };

  const handleResend = () => {
    reSendCode();
  };

  const footerDialog = () => {
    return (
      <div className='flex justify-content-between mt-5 align-self-stretch'>
        <Button
          label={t('Resend')}
          text
          className='hover:text-blue-600'
          onClick={handleResend}></Button>
        <Button
          label={`${t('Confirm')}`}
          style={{ height: '35px' }}
          onClick={
            forgetPassword ? handleResetPassword : handleSubmit
          }></Button>
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      modal
      header={t('Enter the verification code')}
      style={{ width: '20vw', borderRadius: 10, overflow: 'hidden' }}
      breakpoints={{ '960px': '60vw', '641px': '94vw' }}
      footer={footerDialog}
      onHide={onHide}>
      <div className='flex flex-column '>
        <p className='text-color-secondary block text-center'>
          {t('Enter the verification code sent to your email')}:
        </p>
        <p className='text-center font-bold'>
          {email}
          <span
            className='text-primary cursor-pointer hover:text-blue-600'
            onClick={handleChangeEmail}>
            {' '}
            {t('Change')}
          </span>
        </p>

        <div className='h-1rem'></div>

        <div className='flex justify-content-center'>
          <InputOtp
            value={value}
            onChange={(e) => onChange(e.value)}
            integerOnly
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DialogVerify;
