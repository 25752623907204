import {
  GET_SYNDICATION_BY_ID,
  GET_SYNDICATION_BY_ID_SUCCESS,
  GET_SYNDICATION_BY_ID_FAIL,
  GET_SYNDICATION_BY_USER_ID,
  GET_SYNDICATION_BY_USER_ID_SUCCESS,
  GET_SYNDICATION_BY_USER_ID_FAIL,
  GET_SYNDICATION_LIST,
  GET_SYNDICATION_LIST_FAIL,
  GET_SYNDICATION_LIST_SUCCESS,
  GET_SYNDICATION_BY_KEY_ID,
  GET_SYNDICATION_BY_KEY_ID_SUCCESS,
  GET_SYNDICATION_BY_KEY_ID_FAIL,
  SET_SYNDICATION,
  SET_SYNDICATION_SUCCESS,
  SET_SYNDICATION_FAIL,
  UPDATE_SYNDICATION,
  UPDATE_SYNDICATION_SUCCESS,
  UPDATE_SYNDICATION_FAIL,
  DELETE_SYNDICATION,
  DELETE_SYNDICATION_SUCCESS,
  DELETE_SYNDICATION_FAIL,
  SET_DATA_NULL,
} from "./actionTypes";

export const getSyndicationById = (id) => ({
  type: GET_SYNDICATION_BY_ID,
  payload: id,
});

export const getSyndicationByIdSuccess = (data) => ({
  type: GET_SYNDICATION_BY_ID_SUCCESS,
  payload: data,
});

export const getSyndicationByIdFail = (error) => ({
  type: GET_SYNDICATION_BY_ID_FAIL,
  payload: error,
});

export const getSyndicationByUserId = (id) => ({
  type: GET_SYNDICATION_BY_USER_ID,
  payload: id,
});

export const getSyndicationByUserIdSuccess = (data) => ({
  type: GET_SYNDICATION_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getSyndicationByUserIdFail = (error) => ({
  type: GET_SYNDICATION_BY_USER_ID_FAIL,
  payload: error,
});

// Them moi
export const getSyndicationGetList = () => ({
  type: GET_SYNDICATION_LIST,
});

export const getSyndicationGetListSuccess = (data) => ({
  type: GET_SYNDICATION_LIST_SUCCESS,
  payload: data,
});

export const getSyndicationGetListFail = (error) => ({
  type: GET_SYNDICATION_LIST_FAIL,
  payload: error,
});

// end

export const getSyndicationByKeyId = (id) => ({
  type: GET_SYNDICATION_BY_KEY_ID,
  payload: id,
});

export const getSyndicationByKeyIdSuccess = (data) => ({
  type: GET_SYNDICATION_BY_KEY_ID_SUCCESS,
  payload: data,
});

export const getSyndicationByKeyIdFail = (error) => ({
  type: GET_SYNDICATION_BY_KEY_ID_FAIL,
  payload: error,
});

export const setSyndication = (values, file) => ({
  type: SET_SYNDICATION,
  payload: { values, file },
});

export const setSyndicationSuccess = (data) => ({
  type: SET_SYNDICATION_SUCCESS,
  payload: data,
});

export const setSyndicationFail = (error) => ({
  type: SET_SYNDICATION_FAIL,
  payload: error,
});

export const updateSyndication = (values, file) => ({
  type: UPDATE_SYNDICATION,
  payload: { values, file },
});

export const updateSyndicationSuccess = (data) => ({
  type: UPDATE_SYNDICATION_SUCCESS,
  payload: data,
});

export const updateSyndicationFail = (error) => ({
  type: UPDATE_SYNDICATION_FAIL,
  payload: error,
});

export const deleteSyndication = (id) => ({
  type: DELETE_SYNDICATION,
  payload: id,
});

export const deleteSyndicationSuccess = (data) => ({
  type: DELETE_SYNDICATION_SUCCESS,
  payload: data,
});

export const deleteSyndicationFail = (error) => ({
  type: DELETE_SYNDICATION_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
