import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { Slider } from "primereact/slider";
import { Calendar } from "primereact/calendar";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import axios from "axios";

import { useTranslation } from "react-i18next";

import ActionMenu from "../../components/ActionMenu";
import AddInternDialog from "./ModelAddIntern";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import EditInternDialog from "./ModelEditIntern";
import ModelDelete from "../../components/ModalDelete";
import AddViolateDialog from "../Violate/ModelAddViolate";
import CircleImage from "../../components/CircleImage";

import { DataContext } from "../../contexts/data/DataProvider";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { deleteIntern, setDataNull, getInternById } from "../../store/intern/actions";
import { site } from "../../api/url_helper";
import moment from "moment";
import { getReceivingFactoryGetList } from "../../store/receiving_factory/actions";
import { dataInternAll, residenceItems } from "../../contexts/data/defaultData";
import { dropdownSelected, dropdownOption } from "../../assets/templete/dropdownTemplete";
import { getCountryAll } from "../../store/country/actions";
import { getSyndicationGetList } from "../../store/syndication/actions";
import { getBranchDispatching, getBranchReceiving, getBranchSyndication } from "../../store/address/actions";
import { getDispatchingCompanyGetList } from "../../store/dispatching_company/actions";
import { ObjectType } from "../../components/Interface";
import { getSearchIntern } from "../../store/intern/actions";
import { getStatusByUserId } from "../../store/status/actions";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useLocation, useParams } from "react-router-dom";

// import ImportExcelDialog from "./ImportExcel";
import TimeLines from "./TimeLines";

import ButtonImport from "../../components/Excel/ButtonImport";
import ImportExcelDialog from "../../components/Excel/ImportExcel";
import { changeLanguage } from "i18next";
import { ConvertLinkGoogleDrive } from "../../ultis/ConvertLinkGoogleDrive";

// const defaultImage = "images/logo/lego.png";
const defaultAvatarIntern = "images/avatas/logo-intern-null.svg";
const defaultAvatarSyndication = "images/avatas/logo-syndication-null.svg";
const defaultAvatarFactory = "images/avatas/logo-factory-null.svg";
const defaultAvatarCompany = "images/avatas/logo-dispatching-null.svg";

const admin = JSON.parse(localStorage.getItem("object_type"));
const adminId = JSON.parse(localStorage.getItem("object_id"));
const currentYear = moment().year();
const firstDayOfYear = moment({ year: currentYear }).startOf("year").toDate();
const lastDayOfYear = moment({ year: currentYear }).endOf("year").toDate();

function Intern() {
  const { param } = useParams();
  const location = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const switchDetailItems = [t("Detail"), t("Summary")];
  const [dialogVisible, setDialogVisible] = useState(false);
  const [addInternDialogVisible, setAddInternDialogVisible] = useState(false);
  const [editInternDialogVisible, setEditInternDialogVisible] = useState(false);

  const toast = useRef(null);
  const [selectedResidence, setSelectedResidence] = useState(null);

  const sumary = location.state?.sumary;

  const [dialogExcelVisible, setDialogExcelVisible] = useState(false);
  const [tab, setTab] = useState(0);
  const [switchDetail, setSwitchDetail] = useState(switchDetailItems[tab]);
  const [timeLine, setTimeLine] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [agetmp, setAgetmp] = useState([16, 55]);
  const [ageRange, setAgeRange] = useState(agetmp);
  const [selectRowInternEdit, setSelectRowInternEdit] = useState("");
  const [selectRowInternTimeLine, setSelectRowInternTimeLine] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [rowData, setRowData] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSyndication, setSelectedSyndication] = useState(null);
  const [selectedFactory, setSelectedFactory] = useState(null);
  const [selectedDispatching, setSelectedDispatching] = useState(null);
  const [selectedSyndicationBranch, setSelectedSyndicationBranch] = useState(null);
  const [selectedFactoryBranch, setSelectedFactoryBranch] = useState(null);
  const [selectedDispatchingBranch, setSelectedDispatchingBranch] = useState(null);

  const [entryDateRange, setEntryDateRange] = useState([firstDayOfYear, lastDayOfYear]);
  const [selectedIntern, setSelectedIntern] = useState(null);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [fileUrl, setFileUrl] = useState("");

  const [loading, setLoading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState(globalSearch);

  const [loadingExcel, setLoadingExcel] = useState(true);
  const { selectedLang } = useContext(DataContext);
  const [limit, setLimit] = useState(10);

  let defaultImage;

  switch (admin) {
    case ObjectType.SYNDICATION:
      defaultImage = defaultAvatarSyndication;
      break;
    case ObjectType.DISPATCHING_COMPANY:
      defaultImage = defaultAvatarCompany;
      break;
    case ObjectType.RECEIVING_FACTORY:
      defaultImage = defaultAvatarFactory;
      break;
    default:
      defaultImage = defaultAvatarIntern;
  }

  useEffect(() => {
    if (sumary) {
      setTab(sumary);
    }
  }, []);

  useEffect(() => {
    setSwitchDetail(switchDetailItems[tab]);
  }, [selectedLang, tab]);

  const {
    loadingDelete,
    dataDelete,
    errorDelete,
    dataCountry,
    dataReceiving,
    dataSydication,
    dataBranchReceiving,
    dataDispatching,
    dataBranchDispatching,
    dataBranchSyndication,
    dataStatus,
    dataSynthetic,
    dataSearchIntern,
    loadInsert,
    loadingUpdate,
  } = useSelector(
    (state) => ({
      loadingDelete: state.Intern.loadingDelete,
      dataDelete: state.Intern.dataDelete,
      errorDelete: state.Intern.errorDelete,
      dataSynthetic: state.Intern.dataSearch?.synthetic,
      dataCountry: state.Country.datas,
      dataSydication: state.Syndication.dataGetList,
      dataReceiving: state.ReceivingFactory.dataGetList,
      dataBranchReceiving: state.Address.dataBranchReceving,
      dataDispatching: state.DispatchingCompany.dataGetList,
      dataBranchDispatching: state.Address.dataBranchDispatching,
      dataBranchSyndication: state.Address.dataBranchSyndication,
      dataSearchIntern: state.Intern.dataSearch,
      dataStatus: state.Status.dataByUserId,
      loadInsert: state.Intern.loadingInsert,
      loadingUpdate: state.Intern.loadingUpdate,
    }),
    shallowEqual
  );

  // Lấy dữ liệu ban đầu vào
  useEffect(() => {
    dispatch(getCountryAll());
    dispatch(getStatusByUserId());
    dispatch(getSyndicationGetList());
    dispatch(getReceivingFactoryGetList());
    dispatch(getDispatchingCompanyGetList());
  }, [dispatch]);

  useEffect(() => {
    if (agetmp[0] != 16 || agetmp[1] != 55) {
      setLoading(true);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
      setAgeRange(agetmp);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [agetmp]);

  useEffect(() => {
    if (globalSearch != "") {
      setLoading(true);
    }
    const timeoutId = setTimeout(() => {
      setLoading(false);
      setGlobalFilterValue(globalSearch);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [globalSearch]);

  // download file mẫu
  useEffect(() => {
    if (dialogExcelVisible) {
      downloadFileHandle();
    }
  }, [dialogExcelVisible]);

  const downloadFileHandle = async () => {
    try {
      const response = await axios.post(
        `${site}/api/users/dashboard/excel/sample`,
        { object_type: "intern" },
        { withCredentials: true }
      );

      if (response && response.data) {
        setLoadingExcel(false);
        setFileUrl(`${site}/${response.data.file}`);
      }
    } catch (error) {
      console.error("Error while sending POST request:", error);
    }
  };

  const exportExcelHandle = async () => {
    setLimit(dataSearchIntern.total);
    // xử lý tìm kiếm khi chọn lọc xét lại limit
    const fetchDataForExport = async () => {
      let dataSearch = {
        page: (first + rows) / rows,
        limit: dataSearchIntern.total > 10 ? dataSearchIntern.total : 10,
        tab: dataSearchIntern?.menu[tabMenuActive].id ?? 0,
        s_country: selectedCountry,
        s_receiving_factory_id: selectedFactory?.id,
        s_dispatching_company_id: selectedDispatching?.id,
        s_status_of_residence: selectedResidence,
        s_syndication_id: selectedSyndication?.id,
        s_gender: selectedGender,
        s_receiving_factory_branch_id: selectedFactoryBranch,
        s_dispatching_company_branch_id: selectedDispatchingBranch,
        s_ages: `${ageRange[0]}-${ageRange[1]}`,
        s_global: globalFilterValue,
      };
      dispatch(getSearchIntern(dataSearch));
      try {
      } catch (error) {
        console.error(error);
      }
    };
    await fetchDataForExport();

    let exportExcel = {
      type: "intern",
      lan: selectedLang,
    };

    if (selectedIntern !== null) {
      const idSelectedIntern = selectedIntern.map((e) => e.id);
      exportExcel.list_id = idSelectedIntern;
    }
    if (selectedIntern === null && dataSearchIntern?.data) {
      const idSearchIntern = dataSearchIntern?.data?.map((e) => e.id);
      exportExcel.list_id = idSearchIntern;
    }

    try {
      const response = await axios.post(`${site}/api/users/dashboard/excel/export-data`, exportExcel, {
        withCredentials: true,
      });

      if (response && response.data) {
        try {
          const result = await axios.get(`${site}/${response.data.file}`, {
            responseType: "blob",
          });

          const urlObject = new URL(`${site}/${response.data.file}`);

          // Lấy đường dẫn pathname của URL, được chia thành các phần bởi dấu '/'
          const pathParts = urlObject.pathname.split("/");

          // Lấy phần cuối cùng của mảng pathParts, đó là tên file với phần đuôi
          const fileNameWithExtension = pathParts[pathParts.length - 1];
          const url = window.URL.createObjectURL(new Blob([result.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileNameWithExtension);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // format Date
  const formatDate = (dateString) => {
    return moment(dateString).format("YYYY/MM/DD");
  };

  //Gender
  const genderItems = [
    {
      value: "Male",
      name: t("male"),
    },
    {
      value: "Female",
      name: t("female"),
    },
  ];

  const residenceItems = [
    {
      code: 1,
      name: t("Intern training (i)"),
      value: "Intern training (i)",
    },
    {
      code: 2,
      name: t("Intern training (ii) (2nd year)"),
      value: "Intern training (ii) (2nd year)",
    },
    {
      code: 3,
      name: t("Intern training (ii) (3nd year)"),
      value: "Intern training (ii) (3nd year)",
    },
    {
      code: 4,
      name: t("Technical Intern training No. (iii) (4th year)"),
      value: "Technical Intern training No. (iii) (4th year)",
    },
    {
      code: 5,
      name: t("Technical Intern training No. (iii) (5th year)"),
      value: "Technical Intern training No. (iii) (5th year)",
    },
    {
      code: 6,
      name: t("Specification"),
      value: "Specification",
    },
  ];

  const nameTemplete = (rowData) => {
    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        <CircleImage
          src={rowData.avata !== null || rowData.avata !== undefined ? rowData?.avata : defaultAvatarIntern}
          size={45}
          altImage="Avatar"
        />
        <div className="flex flex-column">
          <span className="font-bold mb-1">{`${rowData?.first_name_jp || ""} ${rowData?.middle_name_jp || ""} ${
            rowData?.last_name_jp || ""
          }`}</span>
          <span>{`${rowData?.first_name_en || ""} ${rowData?.middle_name_en || ""} ${
            rowData?.last_name_en || ""
          }`}</span>
        </div>
      </div>
    );
  };

  const synthenticTempleteSyndication = (rowData) => {
    return (
      <div className="flex flex-row align-items-center gap-2 py-2 w-full">
        <Avatar
          image={rowData.image ? `${rowData.image}` : defaultAvatarSyndication}
          shape="circle"
          style={{ minWidth: "2rem" }}
        />
        <div className="flex flex-column">
          <span className="font-bold">{rowData.name}</span>
          <span>{rowData.name_en}</span>
        </div>
      </div>
    );
  };
  const synthenticTempleteCompany = (rowData) => {
    return (
      <div className="flex flex-row align-items-center gap-2 py-2 w-full">
        <Avatar
          image={rowData.image ? `${rowData.image}` : defaultAvatarCompany}
          shape="circle"
          style={{ minWidth: "2rem" }}
        />
        <div className="flex flex-column">
          <span className="font-bold">{rowData.name}</span>
          <span>{rowData.name_en}</span>
        </div>
      </div>
    );
  };
  const synthenticTempleteFactory = (rowData) => {
    return (
      <div className="flex flex-row align-items-center gap-2 py-2 w-full">
        <Avatar
          image={rowData.image ? `${rowData.image}` : defaultAvatarFactory}
          shape="circle"
          style={{ minWidth: "2rem" }}
        />
        <div className="flex flex-column">
          <span className="font-bold">{rowData.name}</span>
          <span>{rowData.name_en}</span>
        </div>
      </div>
    );
  };

  const countryTemplete = (rowData) => {
    return (
      <div className="flex flex-row align-items-center gap-2 py-2 w-full">
        <Avatar image={rowData.image ? rowData.image : defaultImage} style={{ minWidth: "2rem" }} />
        <div className="w-10">
          <span>{rowData.name}</span>
        </div>
      </div>
    );
  };

  const onDateTime = (rowData) => {
    return (
      <div className="flex flex-row align-items-center py-2">
        <div className="flex flex-column">
          <span>{formatDate(rowData?.dob)}</span>
        </div>
      </div>
    );
  };

  // Hiển thị format hiển thị ra bảng
  const onEntryDate = (rowData) => {
    const formatDate = (dateString) => {
      if (!dateString) {
        return "-";
      }
      return moment(dateString).format("YYYY/MM/DD");
    };
    return (
      <div className="flex flex-row align-items-center py-2">
        <div className="flex flex-column">
          <span>{formatDate(rowData?.entry_date)}</span>
        </div>
      </div>
    );
  };

  // fomat status
  const OnResidence = (rowData) => {
    const dataCard = rowData?.alien_registration_card_info;
    let sst = "-";
    let toDay = moment();
    if (dataCard && dataCard.length > 0) {
      dataCard.forEach((card) => {
        if (moment(card.card_expiration_date).isAfter(toDay)) {
          sst = card.status_of_residence;
        }
      });
    }
    return (
      <div className="flex flex-row align-items-center py-2">
        <div className="flex flex-column">
          <span>{t(sst)}</span>
        </div>
      </div>
    );
  };

  const nameSyndicationTemplete = (rowData) => {
    const branchName = rowData?.syndication_info?.syndication_address_info?.find(
      (key) => key.id === rowData.syndication_branch_id
    )?.branch;
    return (
      <div className="flex flex-row align-items-center py-2">
        <Avatar image={rowData?.syndication_info?.avata} shape="circle" className="mr-3" size="small" />
        <div className="flex flex-column">
          <span className="font-bold mb-1">{rowData?.syndication_info?.name_jp}</span>
          <span>{branchName}</span>
        </div>
      </div>
    );
  };

  const nameFactoryTemplete = (rowData) => {
    const branchName = rowData?.receiving_factory_info?.receiving_factory_address_info?.find(
      (key) => key.id === rowData.receiving_factory_branch_id
    )?.branch;

    return (
      <div className="flex flex-row align-items-center py-2">
        <Avatar image={rowData.receiving_factory_info.avata} shape="circle" className="mr-3" size="small" />
        <div className="flex flex-column">
          <span className="font-bold mb-1">{rowData.receiving_factory_info.name_jp}</span>
          <span>{branchName}</span>
        </div>
      </div>
    );
  };

  const nameDispatchingTemplete = (rowData) => {
    console.log(rowData);
    const branchName = rowData?.dispatching_company_info?.dispatching_company_address_info?.find(
      (key) => key.id === rowData.dispatching_company_branch_id
    )?.branc;
    return (
      <div className="flex flex-row align-items-center py-2">
        <Avatar image={`${rowData?.dispatching_company_info?.avata}`} shape="circle" className="mr-3" size="small" />
        <div className="flex flex-column">
          <span className="font-bold mb-1">{rowData?.dispatching_company_info?.name_jp}</span>
          <span>{branchName}</span>
        </div>
      </div>
    );
  };

  // Lấy danh sách branch
  useEffect(() => {
    if (selectedFactory) {
      const data = {
        object_id: selectedFactory.id,
        object_type: selectedFactory.object_type,
      };
      dispatch(getBranchReceiving(data));
    }
  }, [selectedFactory]);

  useEffect(() => {
    if (selectedDispatching) {
      const data = {
        object_id: selectedDispatching.id,
        object_type: selectedDispatching.object_type,
      };
      dispatch(getBranchDispatching(data));
    }
  }, [selectedDispatching]);

  useEffect(() => {
    if (selectedSyndication) {
      const data = {
        object_id: selectedSyndication.id,
        object_type: selectedSyndication.object_type,
      };
      dispatch(getBranchSyndication(data));
    }
  }, [selectedSyndication]);

  // Lấy giá trị ban đầu
  useEffect(() => {
    if (admin === ObjectType.SYNDICATION) {
      const res = dataSydication?.find((e) => e.id === adminId);
      setSelectedSyndication(res);
    } else if (admin === ObjectType.DISPATCHING_COMPANY) {
      const res = dataDispatching?.find((e) => e.id === adminId);
      setSelectedDispatching(res);
    } else {
      const res = dataReceiving?.find((e) => e.id === adminId);
      setSelectedFactory(res);
    }
  }, [admin, dataSydication, dataDispatching, dataReceiving]);

  // xử lý tìm kiếm khi chọn lọc
  useEffect(() => {
    let dataSearch = {
      page: (first + rows) / rows,
      limit: rows,
      tab: dataSearchIntern?.menu[tabMenuActive]?.id ?? 0,
      s_country: selectedCountry,
      s_receiving_factory_id: selectedFactory?.id,
      s_dispatching_company_id: selectedDispatching?.id,
      s_status_of_residence: selectedResidence,
      s_syndication_id: selectedSyndication?.id,
      s_gender: selectedGender,
      s_receiving_factory_branch_id: selectedFactoryBranch,
      s_dispatching_company_branch_id: selectedDispatchingBranch,
      s_ages: `${ageRange[0]}-${ageRange[1]}`,
      s_global: globalFilterValue,
      // s_entry_date: `${formatDate(entryDateRange[0])} - ${formatDate(
      //   entryDateRange[1]
      // )}`,
    };
    dispatch(getSearchIntern(dataSearch));
  }, [
    globalFilterValue,
    selectedCountry,
    selectedFactory,
    selectedDispatching,
    selectedResidence,
    selectedSyndication,
    selectedGender,
    selectedFactoryBranch,
    selectedDispatchingBranch,
    selectedDispatchingBranch,
    ageRange,
    // entryDateRange,
    loadingDelete,
    loadInsert,
    loadingUpdate,
    tabMenuActive,
    first,
    rows,
  ]);

  // Xóa tìm kiếm
  const onClickDeleteFilter = () => {
    if (admin !== ObjectType.SYNDICATION) {
      setSelectedSyndication(null);
    }
    if (admin !== ObjectType.DISPATCHING_COMPANY) {
      setSelectedDispatching(null);
    }
    if (admin !== ObjectType.RECEIVING_FACTORY) {
      setSelectedFactory(null);
    }
    setSelectedCountry(null);
    setSelectedResidence(undefined);
    setSelectedDispatching(null);
    setSelectedDispatchingBranch(null);
    setSelectedSyndicationBranch(null);
    setSelectedGender(null);
    setAgetmp([16, 50]);
    setEntryDateRange([firstDayOfYear, lastDayOfYear]);
    setTabmenuActive(0);
  };

  const printReport = () => {};

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setSelectRowInternEdit(rowData);
    setEditInternDialogVisible(true);
  };

  //Xóa dữ liệu
  const handleDelete = (rowData) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  const handleTimeLine = (rowData) => {
    setSelectRowInternTimeLine(rowData);
    setTimeLine(true);
  };

  useEffect(() => {
    if (errorDelete) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorDelete.mes,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (dataDelete) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Delete Intern success",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line
  }, [errorDelete, dataDelete]);

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteIntern(id));
      }
      setModalDelete(false);
    }
  };

  //Menu hành động
  const actionBodyTemplate = (rowData) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        onUpdate={() => handleTimeLine(rowData)}
        type="intern"
      />
    );
  };

  // Lấy dữ liệu từ Search vào data
  useEffect(() => {
    const updateDataTable = () => {
      if (dataSearchIntern) {
        const newData = dataSearchIntern?.data || [];
        setDataTable(newData);
      }
    };
    updateDataTable();
  }, [tabMenuActive, dataSearchIntern]);

  // Xử lý và lấy ảnh
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.CountryName_en} src={option.Avatar} className={`mr-2 }`} style={{ width: "18px" }} />
          <div>{t(option.CountryName_en)}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.CountryName_en} src={option.Avatar} className={`mr-2 }`} style={{ width: "18px" }} />
        <div>{t(option.CountryName_en)}</div>
      </div>
    );
  };

  // Hiển thị status ra bảng
  const statusBody = (rowData) => {
    const ids = rowData?.intern_status_detail_info?.map((item) => item.status_id);
    const matchedNames = dataStatus?.filter((item) => ids.includes(item.id));
    return (
      <div className="d-flex gap-1">
        {matchedNames?.map((status, index) => (
          <Tag
            key={index}
            value={status.name}
            severity={`${
              status.priority_level === "need_attention"
                ? "warning"
                : status.priority_level === "hipriority_levelgh"
                ? "danger"
                : "Primary"
            }`}
            className="mr-2 mb-1 border-round-lg"
          />
        ))}
      </div>
    );
  };

  const role = JSON.parse(localStorage.getItem("role"));

  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    // Fetch the image from Google Drive with CORS proxy
    const fetchImage = async () => {
      try {
        const response = await fetch(
          "https://cors-anywhere.herokuapp.com/https://drive.google.com/uc?export=view&id=1BvwZnCGd3NuIULohMB1Xn9CyKUt58_k7"
        );
        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        setImageSrc(imgUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []);

  return (
    <div style={{ width: "calc(100vw - 315px)", minWidth: "1200px" }}>
      <Toast ref={toast} />
      <div className="flex justify-content-between mb-4 border-round-md">
        <div className="flex flex-row gap-3">
          <Button
            label={t("Add New")}
            icon="pi pi-user-plus"
            size="small"
            onClick={() => setAddInternDialogVisible(true)}
            disabled={!role}
          />
          <Button
            label={t("Add violations")}
            icon="pi pi-exclamation-triangle"
            size="small"
            severity="secondary"
            onClick={() => {
              setDialogVisible(true);
            }}
          />
        </div>
        <div className="flex flex-row gap-3">
          <Button
            label={t("Clear Filter")}
            icon="pi pi-filter-slash"
            onClick={onClickDeleteFilter}
            size="small"
            severity="secondary"
          />
          {role && (
            <ButtonImport
              onClick={() => {
                setDialogExcelVisible(true);
              }}
              exportClick={exportExcelHandle}
              n
            />
          )}
          {dialogExcelVisible && (
            <ImportExcelDialog
              visible={dialogExcelVisible}
              onHide={() => {
                setDialogExcelVisible(false);
              }}
              fileUrl={fileUrl}
              type="intern"
              loadingExcel={loadingExcel}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <div className="w-2">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="search"
              v-model="value1"
              value={globalSearch}
              onChange={(e) => setGlobalSearch(e.target.value)}
              placeholder={t("Search")}
              className="w-full"
            />
          </IconField>
        </div>
        <div className="w-2 mb-2">
          <FloatLabel pt={{ root: "w-full" }}>
            <Dropdown
              options={dataCountry.filter((e) => e.CountryName_en !== "Japan")}
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.value)}
              filter
              showClear
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              optionValue="CountryName_en"
              emptyFilterMessage={t("No results found")}
              style={{ height: "35px" }}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
            />
            <label>{t("Country")}</label>
          </FloatLabel>
        </div>
        <div className="w-2">
          <FloatLabel>
            <Calendar
              value={entryDateRange}
              onChange={(e) => setEntryDateRange(e.value)}
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              className="w-full"
              dateFormat="yy/mm/dd"
              showIcon
            />
            <label htmlFor="entry-date">{t("Entry Date")}</label>
          </FloatLabel>
        </div>
        <div className="w-2">
          <FloatLabel>
            <Dropdown
              options={residenceItems}
              value={selectedResidence}
              onChange={(e) => setSelectedResidence(e.value)}
              showClear
              placeholder={t("Residence Status")}
              optionLabel="name"
              optionValue="value"
              className="w-full"
              style={{ height: "35px" }}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
            />
            <label>{t("Residence Status")}</label>
          </FloatLabel>
        </div>
        <div className="w-2">
          <FloatLabel>
            <Dropdown
              options={genderItems}
              value={selectedGender}
              onChange={(e) => setSelectedGender(e.value)}
              optionLabel="name"
              optionValue="value"
              showClear
              className="w-full"
              pt={{ panel: "mt-1" }}
            />
            <label htmlFor="gender">{t("Gender")}</label>
          </FloatLabel>
        </div>
        <div className="w-2">
          <div className="p-inputgroup flex-1">
            <FloatLabel>
              <InputText
                value={agetmp[0]}
                onChange={(e) => {
                  setAgetmp([e.target.value, agetmp[1]]);
                }}
                className="w-full text-center"
              />
              <label htmlFor="filterglobal">{t("Age")}</label>
            </FloatLabel>
            <span className="p-2 surface-100 border-1 border-400 border-x-none">~</span>
            <InputText
              value={agetmp[1]}
              onChange={(e) => {
                setAgetmp([agetmp[0], e.target.value]);
              }}
              className="w-full text-center"
            />
          </div>
          <Slider value={agetmp} onChange={(e) => setAgetmp(e.value)} className="w-full" range />
        </div>
      </div>
      <div className="flex flex-row gap-3 mt-2">
        <div className={`flex w-4 flex-row mb-2`}>
          <FloatLabel pt={{ root: selectedSyndication ? "w-6" : "w-full" }}>
            <Dropdown
              options={dataSydication}
              value={selectedSyndication}
              onChange={(e) => setSelectedSyndication(e.value)}
              filter
              filterBy="name_jp,name_en"
              optionLabel="name_jp"
              disabled={admin === ObjectType.SYNDICATION}
              valueTemplate={dropdownSelected}
              itemTemplate={dropdownOption}
              emptyFilterMessage={t("No results found")}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
              style={{ height: "35px" }}
            />
            <label htmlFor="syndication">{t("Syndication")}</label>
          </FloatLabel>
          <FloatLabel pt={{ root: selectedSyndication ? "w-6" : "" }}>
            <Dropdown
              options={dataBranchSyndication}
              value={selectedSyndicationBranch}
              onChange={(e) => setSelectedSyndicationBranch(e.value)}
              filter
              showClear
              optionValue="id"
              optionLabel="branch"
              emptyFilterMessage={t("No results found")}
              className={`${selectedSyndication ? "w-full" : "hidden"}`}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
            />
            <label htmlFor="syndication-branch" className={`${selectedSyndication ? "" : "hidden"}`}>
              {t("Branch")}
            </label>
          </FloatLabel>
        </div>
        <div className={`flex w-4 flex-row mb-2`}>
          <FloatLabel pt={{ root: selectedFactory ? "w-6" : "w-full" }}>
            <Dropdown
              options={dataReceiving}
              value={selectedFactory}
              onChange={(e) => setSelectedFactory(e.value)}
              filter
              filterBy="name_jp,name_en"
              showClear
              disabled={admin === ObjectType.RECEIVING_FACTORY}
              optionLabel="name_jp"
              valueTemplate={dropdownSelected}
              itemTemplate={dropdownOption}
              emptyFilterMessage={t("No results found")}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
              style={{ height: "35px" }}
            />
            <label htmlFor="factory">{t("Receiving Factory")}</label>
          </FloatLabel>
          <FloatLabel pt={{ root: selectedFactory ? "w-6" : "" }}>
            <Dropdown
              options={dataBranchReceiving}
              value={selectedFactoryBranch}
              onChange={(e) => setSelectedFactoryBranch(e.value)}
              filter
              showClear
              optionValue="id"
              optionLabel="branch"
              emptyFilterMessage={t("No results found")}
              className={`${selectedFactory ? "w-full" : "hidden"}`}
              style={{ height: "35px" }}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
            />
            <label htmlFor="factory-bracnh" className={`${selectedFactory ? "" : "hidden"}`}>
              {t("Branch")}
            </label>
          </FloatLabel>
        </div>
        <div className={`flex w-4 flex-row mb-2`}>
          <FloatLabel pt={{ root: selectedDispatching ? "w-6" : "w-full" }}>
            <Dropdown
              options={dataDispatching}
              value={selectedDispatching}
              onChange={(e) => setSelectedDispatching(e.value)}
              filter
              filterBy="name_jp,name_en"
              optionLabel="name_jp"
              showClear
              disabled={admin === ObjectType.DISPATCHING_COMPANY}
              valueTemplate={dropdownSelected}
              itemTemplate={dropdownOption}
              emptyFilterMessage={t("No results found")}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
              style={{ height: "35px" }}
            />
            <label htmlFor="dispatching">{t("Dispatching company")}</label>
          </FloatLabel>
          <FloatLabel pt={{ root: selectedDispatching ? "w-6" : "" }}>
            <Dropdown
              options={dataBranchDispatching}
              value={selectedDispatchingBranch}
              onChange={(e) => setSelectedDispatchingBranch(e.value)}
              filter
              showClear
              optionValue="id"
              optionLabel="branch"
              emptyFilterMessage={t("No results found")}
              className={`${selectedDispatching ? "w-full" : "hidden"}`}
              pt={{ list: "p-0", root: "w-full", header: "p-3" }}
              style={{ height: "35px" }}
            />
            <label htmlFor="dispatching-bracnh" className={`${selectedDispatching ? "" : "hidden"}`}>
              {t("Branch")}
            </label>
          </FloatLabel>
        </div>
      </div>

      <div className="bg-white px-3 mt-2 shadow-1 border-round-md">
        <div className="flex flex-row justify-content-between">
          <TabMenu
            model={dataSearchIntern?.menu?.map((item, index) => ({
              ...item,
              template: () => tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive, t),
            }))}
            activeIndex={tabMenuActive}
            onTabChange={(e) => setTabmenuActive(e.index)}
            pt={{ menuitem: "p-0" }}
          />
          <SelectButton
            value={switchDetail}
            onChange={(e) => {
              setSwitchDetail(e.value);
            }}
            options={switchDetailItems}
            pt={{ button: "mt-3 p-2" }}
          />
        </div>
        {switchDetail === switchDetailItems[0] && (
          <div>
            <DataTable
              value={dataTable}
              selection={selectedIntern}
              onSelectionChange={(e) => setSelectedIntern(e.value)}
              // filters={filters}
              emptyMessage={t("No data found")}
              size="small"
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

              <Column
                field="name_jp"
                header={t("Name")}
                body={nameTemplete}
                style={{ minWidth: "15rem", paddingLeft: "10px" }}
              ></Column>
              <Column
                field="birthday"
                header={t("Date of Birth")}
                body={onDateTime}
                style={{ minWidth: "8rem" }}
              ></Column>
              <Column
                field="entry_date"
                header={t("Entry Date")}
                filterField="entry_date"
                dataType="date"
                body={onEntryDate}
                style={{ minWidth: "10rem" }}
              ></Column>
              <Column
                field="residence"
                header={t("Residence Status")}
                body={OnResidence}
                style={{ minWidth: "12rem" }}
              ></Column>
              {admin != "syndication" && (
                <Column
                  field="syndication_info.name_jp"
                  header={t("Syndication")}
                  body={nameSyndicationTemplete}
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}
              {admin != "receiving_factory" && (
                <Column
                  field="receiving_factory_info.name_jp"
                  header={t("Receiving Factory")}
                  body={nameFactoryTemplete}
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}
              {admin != "dispatching_company" && (
                <Column
                  field="dispatching_company_info.name_jp"
                  header={t("Dispatching company")}
                  body={nameDispatchingTemplete}
                  style={{ minWidth: "15rem" }}
                ></Column>
              )}
              <Column field="status" header={t("Status")} body={statusBody} style={{ width: "20%" }}></Column>
              <Column field="action" body={actionBodyTemplate}></Column>
            </DataTable>
            <div className="card">
              <Paginator
                first={first}
                rows={rows}
                totalRecords={dataSearchIntern?.total}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        )}
        {switchDetail === switchDetailItems[1] && (
          <div className="flex flex-row flex-wrap py-2">
            <div className="w-4 p-3">
              <DataTable
                scrollable
                scrollHeight="900px"
                value={dataSynthetic?.country}
                size="small"
                emptyMessage={t("No data found")}
              >
                <Column
                  field="name"
                  header={t("Country")}
                  style={{ width: "80%", paddingLeft: "20px" }}
                  body={countryTemplete}
                ></Column>
                <Column
                  field="count"
                  header={t("Quantity")}
                  style={{ width: "20%", fontWeight: "bold" }}
                  align={"center"}
                  alignHeader={"center"}
                ></Column>
              </DataTable>
            </div>
            <div className={`${admin === "syndication" ? "hidden" : ""} w-4 p-3`}>
              <DataTable
                scrollable
                scrollHeight="900px"
                value={dataSynthetic?.syndication}
                emptyMessage={t("No data found")}
                size="small"
              >
                <Column
                  field="name_jp"
                  header={t("Syndication")}
                  style={{ width: "80%", paddingLeft: "20px" }}
                  body={synthenticTempleteSyndication}
                ></Column>
                <Column
                  field="count"
                  header={t("Quantity")}
                  style={{ width: "20%", fontWeight: "bold" }}
                  align={"center"}
                  alignHeader={"center"}
                ></Column>
              </DataTable>
            </div>
            <div className={`${admin === "receiving_factory" ? "hidden" : ""} w-4 p-3`}>
              <DataTable
                // scrollable
                scrollHeight="900px"
                value={dataSynthetic?.receiving_factory}
                emptyMessage={t("No data found")}
                size="small"
              >
                <Column
                  field="name_jp"
                  header={t("Factory")}
                  style={{ width: "80%", paddingLeft: "20px" }}
                  body={synthenticTempleteFactory}
                ></Column>
                <Column
                  field="count"
                  header={t("Quantity")}
                  style={{ width: "20%", fontWeight: "bold" }}
                  align={"center"}
                  alignHeader={"center"}
                ></Column>
              </DataTable>
            </div>
            <div className={`${admin === "dispatching_company" ? "hidden" : ""} w-4 p-3`}>
              <DataTable
                scrollable
                scrollHeight="900px"
                value={dataSynthetic?.dispatching_company}
                emptyMessage={t("No data found")}
                size="small"
              >
                <Column
                  field="name_jp"
                  header={t("Dispatch")}
                  style={{ width: "80%", paddingLeft: "20px" }}
                  body={synthenticTempleteCompany}
                ></Column>
                <Column
                  field="count"
                  header={t("Quantity")}
                  style={{ width: "20%", fontWeight: "bold" }}
                  align={"center"}
                  alignHeader={"center"}
                ></Column>
              </DataTable>
            </div>
            {/* <div className='w-4 p-3'>
              <DataTable
                scrollable
                scrollHeight="300px"
                value={dataSynthetic.violate}
                emptyMessage={t("No data found")}
                size="small"
              >
                <Column
                  field="name_jp"
                  header={t("Violation")}
                  style={{ width: "80%", paddingLeft: "20px" }}
                  body={synthenticTemplete}
                ></Column>
                <Column
                  field="count"
                  header={t("Quantity")}
                  style={{ width: "20%", fontWeight: "bold" }}
                  align={"center"}
                  alignHeader={"center"}
                ></Column>
              </DataTable>
            </div> */}
          </div>
        )}
      </div>
      {addInternDialogVisible && (
        <AddInternDialog
          visible={addInternDialogVisible}
          onHide={() => setAddInternDialogVisible(false)}
          toast={toast}
        />
      )}
      {editInternDialogVisible && (
        <EditInternDialog
          visible={editInternDialogVisible}
          onHide={() => setEditInternDialogVisible(false)}
          internData={selectRowInternEdit}
          toast={toast}
        />
      )}
      {timeLine && (
        <TimeLines
          visible={timeLine}
          onHide={() => setTimeLine(false)}
          toast={toast}
          internData={selectRowInternTimeLine}
        />
      )}
      {dialogVisible && (
        <AddViolateDialog
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          toast={toast}
          internSelected={selectedIntern}
        />
      )}
      <ModelDelete visible={modalDelete} setVisible={setModalDelete} accept={acceptDelete} />
    </div>
  );
}

export default Intern;
