export const GET_STATE_ALL = "GET_STATE_ALL";
export const GET_STATE_ALL_SUCCESS = "GET_STATE_ALL_SUCCESS";
export const GET_STATE_ALL_FAIL = "GET_STATE_ALL_FAIL";

export const GET_STATE_ID = "GET_STATE_ID";
export const GET_STATE_ID_SUCCESS = "GET_STATE_ID_SUCCESS";
export const GET_STATE_ID_FAIL = "GET_STATE_ID_FAIL";

export const GET_STATE_BY_COUNTRY_ID = "GET_STATE_BY_COUNTRY_ID";
export const GET_STATE_BY_COUNTRY_ID_SUCCESS = "GET_STATE_BY_COUNTRY_ID_SUCCESS";
export const GET_STATE_BY_COUNTRY_ID_FAIL = "GET_STATE_BY_COUNTRY_ID_FAIL";

export const SET_STATE = "SET_STATE";
export const SET_STATE_SUCCESS = "SET_STATE_SUCCESS";
export const SET_STATE_FAIL = "SET_STATE_FAIL";

export const UPDATE_STATE = "UPDATE_STATE";
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
export const UPDATE_STATE_FAIL = "UPDATE_STATE_FAIL";

export const DELETE_STATE = "DELETE_STATE";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const DELETE_STATE_FAIL = "DELETE_STATE_FAIL";