import {
  GET_STATUS_BY_ID,
  GET_STATUS_BY_ID_SUCCESS,
  GET_STATUS_BY_ID_FAIL,
  GET_STATUS_BY_USER_ID,
  GET_STATUS_BY_USER_ID_SUCCESS,
  GET_STATUS_BY_USER_ID_FAIL,
  SET_STATUS,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAIL,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  DELETE_STATUS,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAIL,
  SET_DATA_NULL
} from "./actionTypes";

export const getStatusById = id => ({
  type: GET_STATUS_BY_ID,
  payload: id,
});

export const getStatusByIdSuccess = data => ({
  type: GET_STATUS_BY_ID_SUCCESS,
  payload: data,
});

export const getStatusByIdFail = error => ({
  type: GET_STATUS_BY_ID_FAIL,
  payload: error,
});

export const getStatusByUserId = () => ({
  type: GET_STATUS_BY_USER_ID,
});

export const getStatusByUserIdSuccess = data => ({
  type: GET_STATUS_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getStatusByUserIdFail = error => ({
  type: GET_STATUS_BY_USER_ID_FAIL,
  payload: error,
});

export const setStatus = data => ({
  type: SET_STATUS,
  payload: data
});

export const setStatusSuccess = data => ({
  type: SET_STATUS_SUCCESS,
  payload: data,
});

export const setStatusFail = error => ({
  type: SET_STATUS_FAIL,
  payload: error,
});

export const updateStatus = (data) => ({
  type: UPDATE_STATUS,
  payload: data
});

export const updateStatusSuccess = data => ({
  type: UPDATE_STATUS_SUCCESS,
  payload: data,
});

export const updateStatusFail = error => ({
  type: UPDATE_STATUS_FAIL,
  payload: error,
});

export const deleteStatus = (id) => ({
  type: DELETE_STATUS,
  payload: id
});

export const deleteStatusSuccess = data => ({
  type: DELETE_STATUS_SUCCESS,
  payload: data,
});

export const deleteStatusFail = error => ({
  type: DELETE_STATUS_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});