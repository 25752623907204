import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { TabMenu } from "primereact/tabmenu";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import defaultAvatar from "../../assets/images/profile-img.png";
import ModalCreateNotify from "./ModalCreateNotify";
import { getAnnounced } from "../../store/announced/actions";
import { DataContext } from "../../contexts/data/DataProvider";
import { useContext } from "react";
import { site } from "../../api/url_helper";
import axios from "axios";
import { convert } from "html-to-text";

export default function ListNotify({ selectedNotify, setSelectedNotify, tabMenuActive, setTabMenuActive }) {
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const [searchValue, setSearchValue] = useState("");

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const { t } = useTranslation();
  const { selectedLang } = useContext(DataContext);
  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem("auth"));

  const options = {
    wordwrap: 130,
    ignoreImage: true,
    ignoreHref: true,
    preserveNewlines: true,
    baseElement: "body",
  };

  const { announced, totalNotify } = useSelector(
    (state) => ({
      announced: state.Announced.announced?.data,
      totalNotify: state.Announced.announced?.total,
    }),
    shallowEqual
  );
  const tabActive = [{ label: t("Received") }, { label: t("Sent") }];

  useEffect(() => {
    if (auth) {
      dispatch(
        getAnnounced({
          page: (first + rows) / rows,
          limit: rows,
          tab: tabMenuActive === 0 ? "Received" : "Sent",
          s_global: searchValue,
        })
      );
    }
  }, [tabMenuActive, first, searchValue, visible, rows, dispatch]);

  const templateAvatar = (rowData) => {
    return <Avatar image={rowData && rowData.sender_info.avata} size="large" shape="circle" className="my-2 mx-2" />;
  };

  const templateSender = (rowData) => {
    return (
      <div className="flex flex-col " style={{ maxWidth: "70%" }}>
        <span className="font-bold overflow-hidden white-space-nowrap text-overflow-ellipsis">
          {rowData.sender_info.name}
        </span>
      </div>
    );
  };

  const templateContentMessage = (rowData) => {
    return (
      <div className="flex flex-column text-left gap-2  py-2   " style={{ maxWidth: "40vw" }}>
        <h4 className="overflow-hidden white-space-nowrap text-overflow-ellipsis">
          {selectedLang === "vi" ? convert(rowData.title_vi, options) : null}
          {selectedLang === "en" ? convert(rowData.title_en, options) : null}
          {selectedLang === "ja" ? convert(rowData.title_ja, options) : null}
        </h4>
        <p className="overflow-hidden white-space-nowrap text-overflow-ellipsis">
          {selectedLang === "vi" ? convert(rowData.content_vi, options) : null}
          {selectedLang === "en" ? convert(rowData.content_en, options) : null}
          {selectedLang === "ja" ? convert(rowData.content_ja, options) : null}
        </p>
      </div>
    );
  };

  //function format date time when sent/recieved notification
  const formatTime = (time) => {
    const currentDate = new Date();
    const targetDate = new Date(time);

    const timeDiff = Math.abs(currentDate - targetDate);
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    if (minutesDiff < 1) {
      return t("1 minute ago");
    } else if (minutesDiff < 60) {
      return `${minutesDiff} ${t("minutes ago")}`;
    } else if (targetDate.toDateString() === currentDate.toDateString()) {
      const hours = targetDate.getHours();
      const minutes = targetDate.getMinutes();
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    } else if (targetDate.getFullYear() === currentDate.getFullYear()) {
      const month = (targetDate.getMonth() + 1).toString().padStart(2, "0");
      const day = targetDate.getDate().toString().padStart(2, "0");
      return `${month}/${day}`;
    } else {
      const year = targetDate.getFullYear();
      const month = (targetDate.getMonth() + 1).toString().padStart(2, "0");
      const day = targetDate.getDate().toString().padStart(2, "0");

      return `${year}/${month}/${day}`;
    }
  };

  const templateTime = (rowData) => {
    return (
      <div className="flex flex-col justify-content-center">
        <span>{formatTime(rowData.createdAt)}</span>
      </div>
    );
  };

  const rowClass = (data) => {
    return {
      "surface-200": data.seen === false,
    };
  };

  const onSelectionRow = (e) => {
    setSelectedNotify(e.value);
    if (!e.value.seen) {
      axios.put(
        `${site}/api/announced/seen`,
        {
          id: e.value.id,
        },
        { withCredentials: true }
      );
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-row justify-content-between mb-3">
          <Button
            label={t("Create a new notification")}
            size="small"
            style={{ height: "35px" }}
            onClick={() => setVisible(true)}
          />
          <IconField iconPosition="left" className="w-6">
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              v-model="value1"
              placeholder={t("Search")}
              className="w-full "
              style={{ height: "35px" }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </IconField>
        </div>
        <div className="bg-white border-round-md border-noround-bottom shadow-1 px-3">
          <TabMenu
            model={tabActive}
            activeIndex={tabMenuActive}
            onTabChange={(e) => setTabMenuActive(e.index)}
            pt={{ menuitem: "p-0" }}
            className="mb-3 pb-2"
          />
          <DataTable
            value={announced}
            emptyMessage={t("No results found")}
            showHeaders={false}
            rowClassName={rowClass}
            selectionMode="single"
            selection={selectedNotify}
            onSelectionChange={onSelectionRow}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column field="avatar" body={templateAvatar} style={{ width: "5%" }}></Column>
            <Column field="sender" body={templateSender} style={{ width: "10%" }}></Column>
            <Column field="content" body={templateContentMessage} style={{ width: "50%" }} align={"center"}></Column>
            <Column field="time" body={templateTime} style={{ width: "10%" }}></Column>
          </DataTable>
        </div>
        <div className="border-round-md border-noround-top">
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalNotify}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <ModalCreateNotify
        visible={visible}
        setVisible={setVisible}
        tabMenuActive={tabMenuActive}
        tabActive={tabActive}
      />
    </>
  );
}
