export const GET_BRANCH_RECEIVING = "GET_BRANCH_RECEIVING";
export const GET_BRANCH_RECEIVING_SUCCESS = "GET_BRANCH_RECEIVING_SUCCESS";
export const GET_BRANCH_RECEIVING_FAIL = "GET_BRANCH_RECEIVING_FAIL";

export const GET_BRANCH_SYNDICATION = "GET_BRANCH_SYNDICATION";
export const GET_BRANCH_SYNDICATION_SUCCESS = "GET_BRANCH_SYNDICATION_SUCCESS";
export const GET_BRANCH_SYNDICATION_FAIL = "GET_BRANCH_SYNDICATION_FAIL";

export const GET_BRANCH_DISPATCHING = "GET_BRANCH_DISPATCHING";
export const GET_BRANCH_DISPATCHING_SUCCESS = "GET_BRANCH_DISPATCHING_SUCCESS";
export const GET_BRANCH_DISPATCHING_FAIL = "GET_BRANCH_DISPATCHING_FAIL";

export const GET_BRANCH_FULL = "GET_BRANCH_FULL";
export const GET_BRANCH_FULL_SUCCESS = "GET_BRANCH_FULL_SUCCESS";
export const GET_BRANCH_FULL_FAIL = "GET_BRANCH_FULL_FAIL";

export const SET_DATA_NULL = 'SET_DATA_NULL';