import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataView } from "primereact/dataview";
import { getNewIntern } from "../../store/dashboard/action";
import { site } from "../../api/url_helper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircleImage from "./../../components/CircleImage";
import { Tag } from "primereact/tag";

const VNflag = "./images/flag/vn.png";

const itemTemplate = (dataIntern, t) => {
  const fullNameJp = `${dataIntern.first_name_jp} ${dataIntern.middle_name_jp || ""} ${dataIntern.last_name_jp}`.trim();
  const fullNameEn = `${dataIntern.first_name_en || ""} ${dataIntern.middle_name_en || ""} ${
    dataIntern.last_name_en || ""
  }`.trim();

  return (
    <div className="p-dview-item py-4 w-full border-round-xl">
      <div className="flex border-round-xl justify-content-between">
        <div className="flex justify-content-center align-items-center w-2">
          <CircleImage src={dataIntern && dataIntern.avata} size={55} altImage="Avatar" />
        </div>
        <div className="flex flex-column justify-content-center align-items-start  w-8">
          <div className="w-11 font-bold overflow-hidden white-space-nowrap text-overflow-ellipsis">{fullNameJp}</div>
          <div>{fullNameEn}</div>
        </div>
        <div className="flex flex-column justify-content-center align-items-end w-2 ">
          <div className="flex align-items-center">
            <div>{t(dataIntern.country)}</div>
            <div style={{ width: "24px", marginLeft: "4px" }}>
              <img src={dataIntern.flag_country} alt="flag" width="100%" />
            </div>
          </div>
          <div>
            {new Date().getFullYear() - new Date(dataIntern.dob).getFullYear()} {t("Age")}
          </div>
        </div>
      </div>
    </div>
  );
};

const NewIntern = () => {
  const navigate = useNavigate();
  const { dataIntern, loading, error } = useSelector(
    (state) => ({
      dataIntern: state.Dashboard.dataIntern,
      loading: state.Dashboard.loading,
      error: state.Dashboard.error,
    }),
    shallowEqual
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const object_type = JSON.parse(localStorage.getItem("object_type"));
  const auth = JSON.parse(localStorage.getItem("auth"));

  useEffect(() => {
    if (auth) {
      dispatch(
        getNewIntern({
          accType: object_type,
          limit: 3,
        })
      );
    }
  }, [dispatch, object_type]);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>No data</div>;
  // }

  return (
    <div className="p-3">
      <div className="flex justify-content-between">
        <p className="text-xl font-bold">{t("New intern")}</p>
        <a
          onClick={() => {
            navigate("/intern");
          }}
          style={{ color: "#0C4DA2", cursor: "pointer" }}
        >
          {t("View All")}
        </a>
      </div>

      <div>
        <DataView
          value={dataIntern.newInterns || []}
          itemTemplate={(item) => itemTemplate(item, t)}
          emptyMessage={t("No data available")}
          layout="list"
        />
      </div>
    </div>
  );
};

export default NewIntern;
