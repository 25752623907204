export function getCodeRandom(){
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
  
    // Lấy ngẫu nhiên 2 chữ cái từ A-Z
    let randomString = '';
  
    for (let i = 0; i < 2; i++) {
      randomString += letters.charAt(Math.floor(Math.random() * letters.length));
    }
  
    // Lấy ngẫu nhiên 4 số từ 0-9
    for (let i = 0; i < 4; i++) {
      randomString += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
  
    return randomString;
  }

  export function getUsernameRandom(){
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
  
    // Lấy ngẫu nhiên 2 chữ cái từ A-Z
    let randomString = '';
  
    for (let i = 0; i < 2; i++) {
      randomString += letters.charAt(Math.floor(Math.random() * letters.length));
    }
  
    // Lấy ngẫu nhiên 4 số từ 0-9
    for (let i = 0; i < 2; i++) {
      randomString += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
  
    return randomString;
  }

  export function getPasswordRandom(){
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
  
    // Lấy ngẫu nhiên 2 chữ cái từ A-Z
    let randomString = '';
  
    for (let i = 0; i < 2; i++) {
      randomString += letters.charAt(Math.floor(Math.random() * letters.length));
    }
  
    // Lấy ngẫu nhiên 4 số từ 0-9
    for (let i = 0; i < 4; i++) {
      randomString += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
  
    return randomString;
  }