// socket.js
import io from "socket.io-client";
import { site } from "../api/url_helper";

class SocketSingleton {
  constructor() {
    if (!SocketSingleton.instance) {
      this.socket = io(site, {
        secure: true,
        rejectUnauthorized: false, // Chỉ cần đặt rejectUnauthorized là false nếu bạn sử dụng chứng chỉ tự ký
        path: "/socket.io",
      }); // URL của server socket

      const uid = localStorage.getItem("userId");
      console.log(uid);
      this.socket.emit("addUser", { userId: JSON.parse(uid) });

      SocketSingleton.instance = this;
    }

    return SocketSingleton.instance;
  }

  getSocket() {
    return this.socket;
  }
}

const instance = new SocketSingleton();
Object.freeze(instance);

export default instance;
