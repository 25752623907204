import { takeEvery, put, call } from "redux-saga/effects";
import {
  GET_CAREER_BY_ID,GET_CAREER_BY_USER_ID, SET_CAREER, UPDATE_CAREER,DELETE_CAREER
} from "./actionTypes"
import {
    getCareerByIdSuccess,
    getCareerByIdFail,
    getCareerByUserIdSuccess,
    getCareerByUserIdFail,
    setCareerSuccess,
    setCareerFail,
    updateCareerSuccess,
    updateCareerFail,
    deleteCareerSuccess,
    deleteCareerFail
} from "./actions"                                    
import { getCareerDataById , getCareerDataByUserId,addNewDataCareer, updateDataCareer, deleteDataCareer } from "../../api/backend_helper";

function* fetCareerDataId({payload: id}) {
  try {
    const response = yield call(getCareerDataById, id);
    yield put(getCareerByIdSuccess(response));
  } catch (error) {
    yield put(getCareerByIdFail(error))
  }
}

function* fetCareerDataByUserId() {
  try {
    const response = yield call(getCareerDataByUserId);
    yield put(getCareerByUserIdSuccess(response));
  } catch (error) {
    yield put(getCareerByUserIdFail(error))
  }
}

function* onAddNewCareer({ payload: data }) {
  try {
      const response = yield call(addNewDataCareer, data);
      yield put(setCareerSuccess(response));
  } catch (error) {
      yield put(setCareerFail(error));
  }
}

function* onUpdateCareer({ payload: data }) {
  try {
      const response = yield call(updateDataCareer, data)
      yield put(updateCareerSuccess(response));
      console.log(response);
  } catch (error) {
      yield put(updateCareerFail(error))
  }
}

function* onDeleteCareer({ payload: id }) {
  try {
      const response = yield call(deleteDataCareer, id)
      yield put(deleteCareerSuccess(response));
  } catch (error) {
      yield put(deleteCareerFail(error))
  }
}

                                      
function* CareerSaga() {
  yield takeEvery(GET_CAREER_BY_ID, fetCareerDataId)
  yield takeEvery(GET_CAREER_BY_USER_ID, fetCareerDataByUserId)
  yield takeEvery(SET_CAREER, onAddNewCareer)
  yield takeEvery(UPDATE_CAREER, onUpdateCareer)
  yield takeEvery(DELETE_CAREER, onDeleteCareer)
}
                                      
export default CareerSaga;