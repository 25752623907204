import Dashboard from '../pages/Dashboard';
import Intern from '../pages/Intern';
import Syndication from '../pages/Syndication';
import ReceivingFactory from '../pages/ReceivingFactory';
import DispatchingCompany from '../pages/DispatchingCompany';
import Violate from '../pages/Violate';
import Ticket from '../pages/Ticket';
import Support from '../pages/Support';
import Notification from '../pages/Notification';
import Career from '../pages/Settings/Career';
import Status from '../pages/Settings/Status';
import ViolateType from '../pages/Settings/ViolateType';
import Profile from '../pages/Profile';
import Login from '../pages/Authentication/Login';
import License from '../pages/Authentication/License';
import Register from '../pages/Authentication/Register';
import ForgetPassword from '../pages/Authentication/ForgetPassword';
import ViolateDetail from '../pages/ViolateDetail';
import ChangePassword from '../pages/Authentication/ChangePassword';

//Public Routes
const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/register', component: Register },
  { path: '/license', component: License },
];

//Private Routes
const privateRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/intern', component: Intern },
  { path: '/syndication', component: Syndication },
  { path: '/receiving-factory', component: ReceivingFactory },
  { path: '/dispatching-company', component: DispatchingCompany },
  { path: '/violate', component: Violate },
  { path: '/ticket', component: Ticket },
  { path: '/support', component: Support },
  { path: '/notification', component: Notification },
  { path: '/career', component: Career },
  { path: '/status', component: Status },
  { path: '/violate-type', component: ViolateType },
  { path: '/profile', component: Profile },
  { path: '/change-password', component: ChangePassword },
  { path: '/violate-detail', component: ViolateDetail },
  {
    path: '/',
    exact: true,
    component: Dashboard,
  },
];

export { publicRoutes, privateRoutes };
