import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';

const ModalActiveAccount = ({
  visibleActive,
  setVisibleActive,
  email,
  setEmail,
  sendCodeToVerifyEmail,
  forgetPassword,
  sendCodeToResetPassword,
}) => {
  const { t } = useTranslation();
  const footerDialog = () => {
    return (
      <div>
        <Button
          label={t('Cancel')}
          className='p-button-text text-black-alpha-70'
          onClick={() => {
            setVisibleActive(false);
            setEmail('');
          }}
          style={{ height: '30px' }}
        />
        <Button
          label={forgetPassword ? t('Confirm') : t('Active')}
          className='p-button-primary text-'
          onClick={
            forgetPassword ? sendCodeToResetPassword : sendCodeToVerifyEmail
          }
          style={{ height: '30px' }}
          icon='pi pi-arrow-circle-right'
          iconPos='right'
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        header={forgetPassword ? t('Forgot password') : t('Active account')}
        closable={false}
        visible={visibleActive}
        style={{ width: '30vw' }}
        className='border-round-xl'
        footer={footerDialog}>
        <div className='flex flex-column'>
          {forgetPassword ? (
            <span className='mb-2 font-bold text-primary '>
              {t('Enter your email to verify')}:
            </span>
          ) : (
            <>
              <span className=''>{t('Account has not been activated')}.</span>
              <span className='mb-3'>
                {t('Please enter your email address to activate your account')}.
              </span>
            </>
          )}
          <InputText
            placeholder={t('Email')}
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ModalActiveAccount;
