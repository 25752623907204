import {
  GET_CAREER_BY_ID,
  GET_CAREER_BY_ID_SUCCESS,
  GET_CAREER_BY_ID_FAIL,
  GET_CAREER_BY_USER_ID,
  GET_CAREER_BY_USER_ID_SUCCESS,
  GET_CAREER_BY_USER_ID_FAIL,
  SET_CAREER,
  SET_CAREER_SUCCESS,
  SET_CAREER_FAIL,
  UPDATE_CAREER,
  UPDATE_CAREER_SUCCESS,
  UPDATE_CAREER_FAIL,
  DELETE_CAREER,
  DELETE_CAREER_SUCCESS,
  DELETE_CAREER_FAIL
} from "./actionTypes";

export const getCareerById = id => ({
  type: GET_CAREER_BY_ID,
  payload: id,
});

export const getCareerByIdSuccess = data => ({
  type: GET_CAREER_BY_ID_SUCCESS,
  payload: data,
});

export const getCareerByIdFail = error => ({
  type: GET_CAREER_BY_ID_FAIL,
  payload: error,
});

export const getCareerByUserId = id => ({
  type: GET_CAREER_BY_USER_ID,
  payload: id,
});

export const getCareerByUserIdSuccess = data => ({
  type: GET_CAREER_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getCareerByUserIdFail = error => ({
  type: GET_CAREER_BY_USER_ID_FAIL,
  payload: error,
});

export const setCareer = data => ({
  type: SET_CAREER,
  payload: data
});

export const setCareerSuccess = data => ({
  type: SET_CAREER_SUCCESS,
  payload: data,
});

export const setCareerFail = error => ({
  type: SET_CAREER_FAIL,
  payload: error,
});

export const updateCareer = (data) => ({
  type: UPDATE_CAREER,
  payload: data
});

export const updateCareerSuccess = data => ({
  type: UPDATE_CAREER_SUCCESS,
  payload: data,
});

export const updateCareerFail = error => ({
  type: UPDATE_CAREER_FAIL,
  payload: error,
});

export const deleteCareer = (id) => ({
  type: DELETE_CAREER,
  payload: id
});

export const deleteCareerSuccess = data => ({
  type: DELETE_CAREER_SUCCESS,
  payload: data,
});

export const deleteCareerFail = error => ({
  type: DELETE_CAREER_FAIL,
  payload: error,
});