import { GET_NOTIFY, GET_NOTIFY_SUCCESS, GET_NOTIFY_FAIL } from './actionTypes';

export const getNotify = (query) => ({
  type: GET_NOTIFY,
  payload: query,
});

export const getNotifySuccess = (data) => ({
  type: GET_NOTIFY_SUCCESS,
  payload: data,
});

export const getNotifyFail = (error) => ({
  type: GET_NOTIFY_FAIL,
  payload: error,
});
