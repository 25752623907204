import React from "react";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";

function PrivateLayout({ children }) {
  return (
    <div className="h-screen w-screen flex flex-row surface-100">
      <div className="hidden md:flex bg-white">
        <SidebarMenu />
      </div>
      <div className="flex flex-column flex-auto">
        <div>
          <Header />
        </div>
        <div className="p-2 md:p-4" style={{ maxHeight: "calc(100vh - 55px)", overflow: "auto" }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PrivateLayout;
