import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import axios from 'axios';

const loadTranslations = async () => {
  const langVi = {};
  const langEn = {};
  const langJa = {};

  try {
    const response = await axios.get('https://api.languages.itomo.vn/get-all');
    response.data.forEach((element) => {
      langEn[element.language_key] = element.en;
      langJa[element.language_key] = element.ja;
      langVi[element.language_key] = element.vi;
    });

    return {
      ja: { translation: langJa },
      vi: { translation: langVi },
      en: { translation: langEn },
    };
  } catch (error) {
    console.error('Error when calling API:', error);
    return {
      ja: { translation: {} },
      vi: { translation: {} },
      en: { translation: {} },
    };
  }
};

const initializeI18n = async () => {
  const resources = await loadTranslations();

  const language = localStorage.getItem("I18N_LANGUAGE") || "en";
  if (!localStorage.getItem("I18N_LANGUAGE")) {
    localStorage.setItem("I18N_LANGUAGE", "en");
  }

  i18n
    .use(detector)
    .use(initReactI18next)
    .init({
      resources,
      lng: language,
      fallbackLng: "en",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });
};

initializeI18n();

export default i18n;
