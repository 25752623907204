const env = process.env.REACT_APP_ENVIRONMANT;

// let site_be = '';
// let site_fe = '';
// if(env === 'DEV') {
//     site_be = 'http://localhost:3012';
//     site_fe = 'http://localhost:3000';
// } else {
//     site_be = 'https://api.lotusocean-jp.com';
//     site_fe = 'https://app.lotusocean-jp.com';
// }

export const api_address = "https://address.itomo.vn";
export const site = "https://api.lotusocean-jp.com";
export const site_front_end = "https://app.lotusocean-jp.com";

// export const api_address = "https://address.itomo.vn";
// export const site = "http://localhost:3012";
// export const site_front_end = "http://localhost:3000";

// country
export const GET_COUNTRY_ALL = `${api_address}/v1/country/all`;
export const GET_COUNTRY_ID = `${api_address}/v1/country`;
export const SET_COUNTRY = `${api_address}/v1/country/insert`;
export const UPDATE_COUNTRY = `${api_address}/v1/country/update`;
export const DELETE_COUNTRY = `${api_address}/v1/country/delete`;

// state
export const GET_STATE_ALL = `${api_address}/v1/state/all`;
export const GET_STATE_ID = `${api_address}/v1/state/id`;
export const GET_STATE_BY_COUNTRY_ID = `${api_address}/v1/state/countryid`;
export const SET_STATE = `${api_address}/v1/state/insert`;
export const UPDATE_STATE = `${api_address}/v1/state/update`;
export const DELETE_STATE = `${api_address}/v1/state/delete`;
// district
export const GET_DISTRICT_ALL = `${api_address}/v1/district/all`;
export const GET_DISTRICT_ID = `${api_address}/v1/district/id`;
export const GET_DISTRICT_BY_STATE_ID = `${api_address}/v1/district/stateid`;
export const SET_DISTRICT = `${api_address}/v1/district/insert`;
export const UPDATE_DISTRICT = `${api_address}/v1/district/update`;
export const DELETE_DISTRICT = `${api_address}/v1/district/delete`;
// ward
export const GET_WARD_ALL = `${api_address}/v1/ward/all`;
export const GET_WARD_ID = `${api_address}/v1/ward/id`;
export const GET_WARD_BY_DISTRICT_ID = `${api_address}/v1/ward/districtid`;
export const SET_WARD = `${api_address}/v1/ward/insert`;
export const UPDATE_WARD = `${api_address}/v1/ward/update`;
export const DELETE_WARD = `${api_address}/v1/ward/delete`;

// career
export const GET_CAREER_BY_ID = `${site}/api/career/get-by-id`;
export const GET_CAREER_BY_USER_ID = `${site}/api/career/get-by-user-id`;
export const SET_CAREER = `${site}/api/career/insert`;
export const UPDATE_CAREER = `${site}/api/career/update`;
export const DELETE_CAREER = `${site}/api/career/delete`;

// users
export const LOGIN = `${site}/api/users/login`;
export const LOGOUT = `${site}/api/users/logout`;
export const GET_USER_BY_ID = `${site}/api/users/get-by-id`;
export const GET_USER_BY_USER_ID = `${site}/api/users/get-by-user-id`;
export const REGISTER_ADMIN = `${site}/api/users/register-admin`;
export const REGISTER_USER = `${site}/api/users/register-user`;
export const CHANGE_PASSWORD = `${site}/api/users/change-password`;
export const DELETE_USER = `${site}/api/users/delete`;
export const RESET_PASSWORD = `${site}/api/users/reset-password`;
export const VERIFY_KEY = `${site}/api/users/verify-code`;
export const VERIFY_KEY_LICENSE = `${site}/api/users/verify-key`;
export const VERIFY_EMAIL = `${site}/api/users/verify-email`;
export const GET_INFOR = `${site}/api/users/get-info`;

// status
export const GET_STATUS_BY_ID = `${site}/api/status/get-by-id`;
export const GET_STATUS_BY_USER_ID = `${site}/api/status/get-by-user-id`;
export const SET_STATUS = `${site}/api/status/insert`;
export const UPDATE_STATUS = `${site}/api/status/update`;
export const DELETE_STATUS = `${site}/api/status/delete`;

// violate_type
export const GET_VIOLATE_TYPE_BY_ID = `${site}/api/violate-type/get-by-id`;
export const GET_VIOLATE_TYPE_BY_USER_ID = `${site}/api/violate-type/get-by-user-id`;
export const SET_VIOLATE_TYPE = `${site}/api/violate-type/insert`;
export const UPDATE_VIOLATE_TYPE = `${site}/api/violate-type/update`;
export const DELETE_VIOLATE_TYPE = `${site}/api/violate-type/delete`;

// syndication
export const GET_SYNDICATION_BY_ID = `${site}/api/syndication/get-by-id`;
export const GET_SYNDICATION_BY_USER_ID = `${site}/api/syndication/get-by-user-id`;
export const GET_SYNDICATION_BY_KEY_ID = `${site}/api/syndication/get-by-key-id`;
export const SET_SYNDICATION = `${site}/api/syndication/insert`;
export const UPDATE_SYNDICATION = `${site}/api/syndication/update`;
export const DELETE_SYNDICATION = `${site}/api/syndication/delete`;
export const GET_LIST_SYNDICATION = `${site}/api/syndication/get-list-syndication`;

// receiving-factory
export const GET_RECEIVING_FACTORY_BY_ID = `${site}/api/receiving-factory/get-by-id`;
export const GET_RECEIVING_FACTORY_BY_USER_ID = `${site}/api/receiving-factory/get-by-user-id`;
export const GET_RECEIVING_FACTORY_BY_KEY_ID = `${site}/api/receiving-factory/get-by-key-id`;
export const SET_RECEIVING_FACTORY = `${site}/api/receiving-factory/insert`;
export const UPDATE_RECEIVING_FACTORY = `${site}/api/receiving-factory/update`;
export const DELETE_RECEIVING_FACTORY = `${site}/api/receiving-factory/delete`;
export const GET_LIST_RECEIVING_FACTORY = `${site}/api/receiving-factory/get-list-factory`;

// dispatching-company
export const GET_DISPATCHING_COMPANY_BY_ID = `${site}/api/dispatching-company/get-by-id`;
export const GET_DISPATCHING_COMPANY_BY_USER_ID = `${site}/api/dispatching-company/get-by-user-id`;
export const GET_DISPATCHING_COMPANY_BY_KEY_ID = `${site}/api/dispatching-company/get-by-key-id`;
export const SET_DISPATCHING_COMPANY = `${site}/api/dispatching-company/insert`;
export const UPDATE_DISPATCHING_COMPANY = `${site}/api/dispatching-company/update`;
export const DELETE_DISPATCHING_COMPANY = `${site}/api/dispatching-company/delete`;
export const GET_LIST_DISPATCHING_COMPANY = `${site}/api/dispatching-company/get-list-company`;

// intern
export const GET_INTERN_BY_ID = `${site}/api/intern/get-by-id`;
export const GET_INTERN_BY_USER_ID = `${site}/api/intern/get-by-user-id`;
export const GET_INTERN_BY_KEY_ID = `${site}/api/intern/get-by-key-id`;
export const SET_INTERN = `${site}/api/intern/insert`;
export const UPDATE_INTERN = `${site}/api/intern/update`;
export const DELETE_INTERN = `${site}/api/intern/delete`;
export const SEARCH_INTERN = `${site}/api/intern/search`;
export const GET_TIME_LINE = `${site}/api/intern/get-timeline`;
export const GET_LIST_CARD = `${site}/api/intern/get-list-card`;

//address
export const GET_BRANCH = `${site}/api/address/get-branch`;
export const GET_BRANCH_FULL = `${site}/api/address/get-branch-full`;

// violate
export const GET_VIOLATE_BY_ID = `${site}/api/violate/get-by-id`;
export const GET_VIOLATE_BY_USER_ID = `${site}/api/violate/get-by-user-id`;
export const SET_VIOLATE = `${site}/api/violate/insert`;
export const UPDATE_VIOLATE = `${site}/api/violate/update`;
export const DELETE_VIOLATE = `${site}/api/violate/delete`;
export const HANDLE_VIOLATE = `${site}/api/violate/handle`;
// ticket
export const GET_TICKET_LIST = `${site}/api/ticket/get-ticket-list`;
export const GET_TICKET_DETAIL_LIST = `${site}/api/ticket/get-ticket-detail-list`;
export const GET_TICKET_USERID = `${site}/api/ticket/userId`;
export const GET_TICKET_ID = `${site}/api/ticket`;
export const SET_TICKET = `${site}/api/ticket/insert`;
export const UPDATE_TICKET = `${site}/api/ticket/update`;
export const DELETE_TICKET = `${site}/api/ticket/delete`;
export const SET_REPLY_TICKET = `${site}/api/ticket/reply`;
export const TRANSLATE_TICKET = `${site}/api/ticket/translate`;
export const GET_LIST_USER = `${site}/api/ticket/get-list-user`;
export const CLOSE_TICKET = `${site}/api/ticket/close`;
//announce
export const GET_ANNOUNCED = `${site}/api/announced/get-by-user-id`;
export const SET_ANNOUNCED = `${site}/api/announced/insert`;
export const GET_LIST_TARGET = `${site}/api/announced/get-list-user`;
export const GET_LIST_INTERN_TARGET = `${site}/api/announced/get-list-user-intern`;

//dashboard
export const GET_DASHBOARD_DATA = `${site}/api/dashboard/get-data`;
export const GET_NEW_INTERN = `${site}/api/users/dashboard/get-new-interns`;
export const GET_NOTIFICATION = `${site}/api/notification/get-by-user-id`;
