export const GET_RECEIVING_FACTORY_BY_ID = "GET_RECEIVING_FACTORY_BY_ID";
export const GET_RECEIVING_FACTORY_BY_ID_SUCCESS = "GET_RECEIVING_FACTORY_BY_ID_SUCCESS";
export const GET_RECEIVING_FACTORY_BY_ID_FAIL = "GET_RECEIVING_FACTORY_BY_ID_FAIL";

export const GET_RECEIVING_FACTORY_BY_USER_ID = "GET_RECEIVING_FACTORY_BY_USER_ID";
export const GET_RECEIVING_FACTORY_BY_USER_ID_SUCCESS = "GET_RECEIVING_FACTORY_BY_USER_ID_SUCCESS";
export const GET_RECEIVING_FACTORY_BY_USER_ID_FAIL = "GET_RECEIVING_FACTORY_BY_USER_ID_FAIL";

export const GET_RECEIVING_FACTORY_LIST = "GET_RECEIVING_FACTORY_LIST";
export const GET_RECEIVING_FACTORY_LIST_SUCCESS = "GET_RECEIVING_FACTORY_LIST_SUCCESS";
export const GET_RECEIVING_FACTORY_LIST_FAIL = "GET_RECEIVING_FACTORY_LIST_FAIL";

export const GET_RECEIVING_FACTORY_BY_KEY_ID = "GET_RECEIVING_FACTORY_BY_KEY_ID";
export const GET_RECEIVING_FACTORY_BY_KEY_ID_SUCCESS = "GET_RECEIVING_FACTORY_BY_KEY_ID_SUCCESS";
export const GET_RECEIVING_FACTORY_BY_KEY_ID_FAIL = "GET_RECEIVING_FACTORY_BY_KEY_ID_FAIL";

export const SET_RECEIVING_FACTORY = "SET_RECEIVING_FACTORY";
export const SET_RECEIVING_FACTORY_SUCCESS = "SET_RECEIVING_FACTORY_SUCCESS";
export const SET_RECEIVING_FACTORY_FAIL = "SET_RECEIVING_FACTORY_FAIL";

export const UPDATE_RECEIVING_FACTORY = "UPDATE_RECEIVING_FACTORY";
export const UPDATE_RECEIVING_FACTORY_SUCCESS = "UPDATE_RECEIVING_FACTORY_SUCCESS";
export const UPDATE_RECEIVING_FACTORY_FAIL = "UPDATE_RECEIVING_FACTORY_FAIL";

export const DELETE_RECEIVING_FACTORY = "DELETE_RECEIVING_FACTORY";
export const DELETE_RECEIVING_FACTORY_SUCCESS = "DELETE_RECEIVING_FACTORY_SUCCESS";
export const DELETE_RECEIVING_FACTORY_FAIL = "DELETE_RECEIVING_FACTORY_FAIL";

export const SET_DATA_NULL = 'SET_DATA_NULL';