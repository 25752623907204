import React, { useState } from "react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { TicketCategoryObj } from "../../components/Interface";

const MailboxSidebar = ({
  ticketBox,
  totalInbox,
  totalOutbox,
  onMailboxClick,
  onAddNew,
  setCategory,
}) => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryChange = (category) => {
    const newCategory = selectedCategory === category ? null : category;
    setSelectedCategory(newCategory);
    setCategory(newCategory);
  };

  return (
    <div className="bg-white border-round-md p-3 custom-container">
      <Button
        label={t("Add New")}
        className="custom-button"
        onClick={onAddNew}
      />
      <span className="flex font-bold mt-5 mb-3">{t("Mailbox")}</span>
      <div>
        <div
          className={`relative custom-mailbox hover-effect ${ticketBox === "Inbox" ? "active" : ""
            }`}
          style={{ cursor: "pointer" }}
          onClick={() => onMailboxClick("Inbox")}
        >
          <i className="pi pi-envelope ml-3 custom-mailbox-icon"></i>
          <span className="ml-3" style={{ fontSize: "14px" }}>
            {t("Inbox")}
          </span>
          <span className="absolute right-0 mr-2">{totalInbox}</span>
        </div>
        <div
          className={`relative custom-mailbox hover-effect ${ticketBox === "Outbox" ? "active" : ""
            }`}
          style={{ cursor: "pointer" }}
          onClick={() => onMailboxClick("Outbox")}
        >
          <i className="pi pi-send ml-3 custom-mailbox-icon"></i>
          <span className="ml-3" style={{ fontSize: "14px" }}>
            {t("Sent Items")}
          </span>
          <span className="absolute right-0 mr-2">{totalOutbox}</span>
        </div>
      </div>
      <span className="flex font-bold mt-5 mb-3">{t("Select topic")}</span>
      <div>
        {Object.keys(TicketCategoryObj).map((key) => (
          <div
            key={key}
            className="relative custom-mailbox hover-effect"
            style={{ cursor: "pointer" }}
            onClick={() => handleCategoryChange(TicketCategoryObj[key])}
          >
            <Checkbox
              className="ml-3"
              checked={selectedCategory === TicketCategoryObj[key]}
            />
            <span className="ml-3" style={{ fontSize: "14px" }}>
              {t(TicketCategoryObj[key])}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(MailboxSidebar);
