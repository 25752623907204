import {
  GET_TICKET_LIST,
  GET_TICKET_LIST_SUCCESS,
  GET_TICKET_LIST_FAIL,
  GET_TICKET_DETAIL_LIST,
  GET_TICKET_DETAIL_LIST_SUCCESS,
  GET_TICKET_DETAIL_LIST_FAIL,
  SET_TICKET,
  SET_TICKET_SUCCESS,
  SET_TICKET_FAIL,
  UPDATE_TICKET,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAIL,
  SET_DATA_NULL,
  SET_REPLY_TICKET,
  SET_REPLY_TICKET_SUCCESS,
  SET_REPLY_TICKET_FAIL,
  TRANSLATE_TICKET,
  TRANSLATE_TICKET_SUCCESS,
  TRANSLATE_TICKET_FAIL,
  GET_LIST_USER_REQUEST,
  GET_LIST_USER_SUCCESS,
  GET_LIST_USER_FAILURE,
  CLOSE_TICKET,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAIL,
  RECEIVER_TICKET,
} from "./actionTypes";

export const getTicketList = (params) => ({
  type: GET_TICKET_LIST,
  payload: params,
});
export const getTicketListSuccess = (data) => {
  // console.log("getTicketListSuccess - action: ", data);
  return {
    type: GET_TICKET_LIST_SUCCESS,
    payload: data,
  };
};
export const getTicketListFail = (error) => {
  // console.log("getTicketListFail - action: ", error);
  return {
    type: GET_TICKET_LIST_FAIL,
    payload: error,
  };
};

export const getTicketDetailList = (params) => ({
  type: GET_TICKET_DETAIL_LIST,
  payload: params,
});
export const getTicketDetailListSuccess = (data) => ({
  type: GET_TICKET_DETAIL_LIST_SUCCESS,
  payload: data,
});
export const getTicketDetailListFail = (error) => ({
  type: GET_TICKET_DETAIL_LIST_FAIL,
  payload: error,
});

export const setTicket = (values, file) => ({
  type: SET_TICKET,
  payload: { values, file },
});

export const setTicketSuccess = (data) => ({
  type: SET_TICKET_SUCCESS,
  payload: data,
});

export const setTicketFail = (error) => ({
  type: SET_TICKET_FAIL,
  payload: error,
});

export const setReplyTicket = (values) => ({
  type: SET_REPLY_TICKET,
  payload: { values },
});

export const setReplyTicketSuccess = (data) => ({
  type: SET_REPLY_TICKET_SUCCESS,
  payload: data,
});

export const setReplyTicketFail = (error) => ({
  type: SET_REPLY_TICKET_FAIL,
  payload: error,
});

export const translateTicket = (id) => ({
  type: TRANSLATE_TICKET,
  payload: id,
});

export const translateTicketSuccess = (data) => ({
  type: TRANSLATE_TICKET_SUCCESS,
  payload: data,
});

export const translateTicketFail = (error) => ({
  type: TRANSLATE_TICKET_FAIL,
  payload: error,
});

export const updateTicket = (data) => ({
  type: UPDATE_TICKET,
  payload: data,
});

export const updateTicketSuccess = (data) => ({
  type: UPDATE_TICKET_SUCCESS,
  payload: data,
});

export const updateTicketFail = (error) => ({
  type: UPDATE_TICKET_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});

export const getListUserRequest = (key) => ({
  type: GET_LIST_USER_REQUEST,
  payload: key,
});

export const getListUserSuccess = (data) => ({
  type: GET_LIST_USER_SUCCESS,
  payload: data,
});

export const getListUserFailure = (error) => ({
  type: GET_LIST_USER_FAILURE,
  payload: error,
});

export const closeTicket = (id) => ({
  type: CLOSE_TICKET,
  payload: id,
});

export const closeTicketSuccess = (id) => ({
  type: CLOSE_TICKET_SUCCESS,
  payload: { id },
});

export const closeTicketFail = (error) => ({
  type: CLOSE_TICKET_FAIL,
  payload: error,
});

export const receiveTicket = (data) => {
  console.log("receiveTicket - action: ", data);
  return {
    type: RECEIVER_TICKET,
    payload: data,
  };
};
