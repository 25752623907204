import { GET_NOTIFY, GET_NOTIFY_SUCCESS, GET_NOTIFY_FAIL } from './actionTypes';

const INIT_STATE = {
  notify: [],
  loading: false,
  error: null,
};

const Notify = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFY:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFY_SUCCESS:
      return {
        ...state,
        notify: action.payload,
        loading: false,
      };
    case GET_NOTIFY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Notify;
