import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import UploadFile from "../../components/UploadFile";
import { useState, useRef } from "react";
import { site } from "../../api/url_helper";
import NotiDialog from "./NotiDialog";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { ProgressSpinner } from "primereact/progressspinner";

const ImportExcelDialog = ({
  visible,
  onHide,
  fileUrl,
  type,
  loadingExcel,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [clearFiles, setClearFiles] = useState(false);
  const [postData, setPostData] = useState({ success: 0, failed: 0 });
  const [failedFile, setFailedFile] = useState(null);
  const [notiDialogVisible, setNotiDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const { t } = useTranslation();
  const showWarn = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: "Please select the file before importing",
      life: 3000,
    });
  };

  const failedFileUrl = `${site}/${failedFile}`;

  const onUpload = () => {};

  const getFiles = (files) => {
    let file = files?.[0];
    setSelectedFiles(file);
  };

  // post file excel
  const SubmitHandle = async () => {
    if (selectedFiles) {
      setLoading(true);
      // setTimeout(() => {
      //   setLoading(false);
      //   setNotiDialogVisible(true);
      // }, 2000);

      const url = `${site}/api/users/dashboard/excel/${type}`;

      let formData = new FormData();
      formData.append("file", selectedFiles, selectedFiles.name);

      try {
        const response = await axios.post(
          url,
          formData,
          { withCredentials: true },
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        if(response.status == 200){
          setPostData(response.data);
          setFailedFile(response.data?.file);
          setNotiDialogVisible(true);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Upload error:", error);
        setLoading(false);
      }
    } else {
      showWarn();
    }
  };

  const header = <div>{t("Import from Excel")}</div>;
  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
      }}
      header={header}
      style={{ width: "40%" }}
    >
      <Toast ref={toast} />
      <p className="font-bold">{t("Steps")}</p>
      <div className="bg-yellow-100 px-5 py-3 line-height-4 border-round-md my-4">
        <span>
          {t("1. Download the sample file and enter data")}{" "}
          {!loadingExcel && (
            <a
              className="text-blue-500 font-bold underline cursor-pointer"
              href={fileUrl}
              download={fileUrl}
            >
              {t("Download sample file")}
            </a>
          )}
        </span>
        <span className="text-red-500 font-bold">{"  "}</span>{" "}
        {loadingExcel && (
          <span>
            <ProgressSpinner
              style={{ width: "15px", height: "15px" }}
              strokeWidth="8"
            />
          </span>
        )}
        <p>{t("2. Enter the data correctly according to the fields.")}</p>
        <p className="text-red-500">
          {t("Note: Do not change the names and order of the columns.")}
        </p>
      </div>
      <UploadFile
        onUpload={onUpload}
        getFiles={getFiles}
        clearFiles={clearFiles}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "40px",
          gap: "1rem",
        }}
      >
        <div className="card">
          <Button
            label="Hủy"
            className="px-5 py-2 bg-white border-none text-400"
            severity="secondary"
            onClick={() => {
              onHide();
            }}
          />
        </div>
        <div className="card">
          <Button
            label="Nhập"
            className="px-5 py-2 bg-green-700"
            severity="info"
            loading={loading}
            onClick={() => {
              SubmitHandle();
            }}
          />
        </div>
      </div>
      {notiDialogVisible && (
        <NotiDialog
          visible={notiDialogVisible}
          onHide={() => {
            setNotiDialogVisible(false);
          }}
          success={postData.success}
          failed={postData.failed}
          failedUrl={failedFileUrl}
        />
      )}
    </Dialog>
  );
};

export default ImportExcelDialog;
