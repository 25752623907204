import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { setCareer } from "../../../store/career/actions";
import { useDispatch } from "react-redux";

const AddCareerDialog = ({ visible, onHide, toast }) => {
  const [career, setCareerData] = useState({ name: "", description: "" });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAddCareer = () => {
    if (career.name === "") {
      toast.current?.show({
        severity: "error",
        summary: "error",
        detail: "name is requied",
        life: 3000,
      });
    } else {
      const res = dispatch(setCareer(career));
      if(res) {
          setCareerData({ name: '', description: '' });
          toast.current?.show({ severity: 'success', summary: 'success', detail: 'Add career Success', life: 3000 });
      }
      onHide();
    }
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t("Add Career")}
      modal
      style={{ width: "50rem", top: "-15%" }}
      footer={
        <div>
          <Button
            label="Cancel"
            onClick={onHide}
            severity="secondary"
            className="p-button-text"
          />
          <Button label="Save" onClick={handleAddCareer} />
        </div>
      }
    >
      <div className="flex flex-column mb-4">
        <label className="mb-2">Career name</label>
        <InputText
          id="name"
          value={career.name}
          onChange={(e) => setCareerData({ ...career, name: e.target.value })}
        />
      </div>
      <div className="flex flex-column mb-3">
        <label className="mb-2">Description</label>
        <InputTextarea
          id="description"
          rows={5}
          value={career.description}
          onChange={(e) =>
            setCareerData({ ...career, description: e.target.value })
          }
        />
      </div>
    </Dialog>
  );
};

export default AddCareerDialog;
