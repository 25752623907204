import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_COUNTRY_ALL,
  GET_COUNTRY_ID,
  SET_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
} from "./actionTypes";
import {
  getCountryAllFail,
  getCountryAllSuccess,
  getCountryIdSuccess,
  getCountryIdFail,
  setCountrySuccess,
  setCountryFail,
  updateCountrySuccess,
  updateCountryFail,
  deleteCountrySuccess,
  deleteCountryFail,
} from "./actions";

import {
  getCountryDataAll,
  getCountryDataId,
  addNewDataCountry,
  updateDataCountry,
  deleteDataCountry,
} from "../../api/backend_helper";
import axios from "axios";

function* fetCountryData() {
  try {
    const response = yield call(
      axios.get,
      "https://address.itomo.vn/v1/country/all"
    );
    yield put(getCountryAllSuccess(response.data));
  } catch (error) {
    yield put(getCountryAllFail(error));
  }
}

function* fetCountryDataId({ payload: id }) {
  try {
    const response = yield call(getCountryDataId, id);
    yield put(getCountryIdSuccess(response));
  } catch (error) {
    yield put(getCountryIdFail(error));
  }
}

function* onAddNewCountry({ payload: data }) {
  try {
    const response = yield call(addNewDataCountry, data);
    yield put(setCountrySuccess(response));
    yield call(refreshCountryData);
  } catch (error) {
    yield put(setCountryFail(error));
  }
}

function* onUpdateCountry({ payload: data }) {
  try {
    const response = yield call(updateDataCountry, data);
    yield put(updateCountrySuccess(response));
    yield call(refreshCountryData);
  } catch (error) {
    yield put(updateCountryFail(error));
  }
}

function* onDeleteCountry({ payload: id }) {
  try {
    const response = yield call(deleteDataCountry, id);
    yield put(deleteCountrySuccess(response));
  } catch (error) {
    yield put(deleteCountryFail(error));
  }
}

function* refreshCountryData() {
  const response = yield call(getCountryDataAll);
  yield put(getCountryAllSuccess(response));
}

function* CountrySaga() {
  yield takeEvery(GET_COUNTRY_ALL, fetCountryData);
  yield takeEvery(GET_COUNTRY_ID, fetCountryDataId);
  yield takeEvery(SET_COUNTRY, onAddNewCountry);
  yield takeEvery(UPDATE_COUNTRY, onUpdateCountry);
  yield takeEvery(DELETE_COUNTRY, onDeleteCountry);
}

export default CountrySaga;
